import React, { useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import styled from 'styled-components';
import { EntityTypeEnum, LabelPreviewWithoutTypeOutDTO } from '@api/logsteo-api.v2.tsx';
import { ApiContext } from '@api/api.tsx';
import { dumpVars, isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils.tsx';
import COBadge from '@components/ringil3/COBadge/COBadge.tsx';
import RingilSpinner from '@components/ringil3/RingilSpinner/RingilSpinner.tsx';
import Heading from '@components/ringil3/Heading/Heading.tsx';
import EditButton from '@components/ringil3/Buttons/EditButton.tsx';
import InputTextWithSuffix from '@components/ringil3/Input/InputTextWithSuffix/InputTextWithSuffix.tsx';
import { RowWithGap } from '@components/styles.tsx';
import SvgLabel from '@components/ringil3/icons/Label.tsx';
import SvgTagEdit from '@components/ringil3/icons/TagEdit.tsx';
import SvgTagPlus from '@components/ringil3/icons/TagPlus.tsx';
import InputNoteWithLink from '@components/ringil3/Input/InputNoteWithLink/InputNoteWithLink.tsx';
import LinkWithIcon from '@components/ringil3/Links/LinkWithIcon.tsx';
import { ClickableCursor, HorizontalLine } from '@components/ringil3/styles.tsx';
import useForm from '@hooks/useForm/useForm.tsx';
import * as yup from 'yup';
import { VerticalFieldAndValue } from '@components/ringil3/Containers/VerticalFieldAndValue.tsx';
import { InputText } from 'primereact/inputtext';
import SvgNewTagCheck from '@components/ringil3/icons/tags/NewTagCheck.tsx';
import RingilButton from '@components/ringil3/Buttons/RingilButton.tsx';
import SvgDelete from '@components/ringil3/icons/buttons/Delete.tsx';
import { useDebounce } from 'use-debounce';
import ButtonTag from '@components/ringil3/Tags/ButtonTag.tsx';

interface ComponentProps {
  objectType: EntityTypeEnum;
  objectId: string;
  resolveLabelsForType?: EntityTypeEnum;
  disabled?: boolean;
  onChange?: (labels: LabelPreviewWithoutTypeOutDTO[]) => void;
  value?: LabelPreviewWithoutTypeOutDTO[];
}

interface LabelsProps {}

interface AddLabelForm {
  color?: string;
  name?: string;
}

interface LabelColor {
  colorName: string;
  hexValue: string;
}

const LabelsTag: React.FC<ComponentProps> = ({ objectType, objectId, resolveLabelsForType, disabled, onChange, value }) => {
  const { tr } = useTranslationLgs();
  const { getAllLabelsForCompanyEntityType, createNewLabelForCompany, cuGetLabelsForEntity, cuSetLabelsForEntity, archiveLabel } = useContext(ApiContext);
  const [labels, setLabels] = useState<LabelPreviewWithoutTypeOutDTO[]>(value || []);
  const [labelsForEntity, setLabelsForEntity] = useState<LabelPreviewWithoutTypeOutDTO[]>(value || []);
  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText] = useDebounce(searchText, 700);
  const [apiReqCount, setApiReqCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [showDeleteLabel, setShowDeleteLabel] = useState(false);

  const labelColors: LabelColor[] = [
    {
      colorName: tr('LabelsTag.green', 'green'),
      hexValue: '#60BD4E',
    },
    {
      colorName: tr('LabelsTag.yellow', 'yellow'),
      hexValue: '#F1D600',
    },
    {
      colorName: tr('LabelsTag.orange', 'orange'),
      hexValue: '#FF9E19',
    },
    {
      colorName: tr('LabelsTag.red', 'red'),
      hexValue: '#EB5A46',
    },
    {
      colorName: tr('LabelsTag.magenta', 'magenta'),
      hexValue: '#C277E0',
    },
    {
      colorName: tr('LabelsTag.blue', 'blue'),
      hexValue: '#0179BF',
    },
    {
      colorName: tr('LabelsTag.lightblue', 'lightblue'),
      hexValue: '#00C2DF',
    },
    {
      colorName: tr('LabelsTag.green', 'green'),
      hexValue: '#51E898',
    },
    {
      colorName: tr('LabelsTag.darkblue', 'darkblue'),
      hexValue: '#334563',
    },
    {
      colorName: tr('LabelsTag.pink', 'pink'),
      hexValue: '#F62DAE',
    },
    {
      colorName: tr('LabelsTag.black', 'black'),
      hexValue: '#000000',
    },
    {
      colorName: tr('LabelsTag.cherry', 'cherry'),
      hexValue: '#A63A50',
    },
    {
      colorName: tr('LabelsTag.indigo', 'indigo'),
      hexValue: '#470063',
    },
    {
      colorName: tr('LabelsTag.sage', 'sage'),
      hexValue: '#91C499',
    },
    {
      colorName: tr('LabelsTag.silver', 'silver'),
      hexValue: '#AFBFC0',
    },
    {
      colorName: tr('LabelsTag.lemon', 'lemon'),
      hexValue: '#CFD11A',
    },
    {
      colorName: tr('LabelsTag.sandy', 'sandy'),
      hexValue: '#E0B773',
    },
  ];

  useEffect(() => {
    if (isNotNullOrUndefined(value)) {
      setLabelsForEntity(value);
    }
  }, [value]);

  const addLabelForm = useForm<AddLabelForm>(
    yup.object().shape({
      color: yup.string().required(),
      name: yup.string().required(),
    }),
    null,
    d => saveNewLabel(d),
    false,
    false,
    null,
    'scroll',
  );

  useEffect(() => {
    load();
  }, [debouncedSearchText]);

  const loadLabelsHandler = () => {
    load();
  };

  const saveNewLabel = (d: AddLabelForm) => {
    createNewLabelForCompany(
      {
        labelValue: d.name,
        labelColor: d.color,
        entityType: resolveLabelsForType,
      },
      () => {
        addLabelForm.overwriteValues(null);
        load();
      },
    );
  };

  const deleteLabel = (labelId: string) => {
    if (isNotNullOrUndefined(objectId)) {
      setApiReqCount(c => c + 1);
      cuSetLabelsForEntity(
        objectType,
        objectId,
        {
          labelIds: labelsForEntity.filter(t => t.labelId != labelId).map(t => t.labelId),
        },
        () => {
          setApiReqCount(c => c - 1);
          load();
        },
      );
    } else {
      onChange(labelsForEntity.filter(t => t.labelId != labelId));
    }
  };

  const addLabel = (labelId: string) => {
    if (objectId == null) {
      onChange([...labelsForEntity, labels.find(t => t.labelId == labelId)]);
    } else {
      setApiReqCount(c => c + 1);
      cuSetLabelsForEntity(objectType, objectId, { labelIds: [...labelsForEntity.map(t => t.labelId), labelId] }, () => {
        setApiReqCount(c => c - 1);
        load();
      });
    }
  };

  const load = () => {
    setApiReqCount(c => c + 1);
    getAllLabelsForCompanyEntityType(resolveLabelsForType, searchText, d => {
      setApiReqCount(c => c - 1);
      setLabels(d);
    });
    if (objectId != null) {
      setApiReqCount(c => c + 1);
      cuGetLabelsForEntity(objectType, objectId, d => {
        setApiReqCount(c => c - 1);
        setLabelsForEntity(d);
      });
    }
  };

  const existingLabels = () => {
    return (
      <RowWithGap>
        <LabelsRow>
          {labelsForEntity?.map((label, index) => {
            return (
              <COBadge
                key={index}
                variant={'filled'}
                type={'custom'}
                backgroundColor={label.labelColor}
                iconPath={'/images/icons/ringil3/buttons/deleteWhite.svg'}
                onIconClick={e => {
                  e.preventDefault();
                  deleteLabel(label.labelId);
                }}
              >
                <div className={'text-white'}>{label.labelValue}</div>
              </COBadge>
            );
          })}
        </LabelsRow>
        <RingilSpinner isSpinning={apiReqCount > 0} />
      </RowWithGap>
    );
  };

  const archiveLabelHandler = (labelId: string) => {
    archiveLabel(
      labelId,
      () => {
        loadLabelsHandler();
      },
      null,
    );
  };

  const pane = (
    <LabelsWrapper>
      {!showDeleteLabel ? (
        <>
          <Heading
            headingVariant={'h4'}
            title={tr(`LabelsTag.labels`, `Labels`)}
            buttons={[
              <EditButton
                onClick={() => {
                  setShowDeleteLabel(true);
                }}
              />,
            ]}
          ></Heading>
          <InputTextWithSuffix
            suffix={
              <>
                <img src={'/images/icons/ringil3/inputsearch.svg'} />
              </>
            }
            value={searchText}
            onChange={v => setSearchText(v)}
          />
          <ExistingLabelsWrapper>
            {labels?.map((label, index) => {
              return (
                <COBadge
                  key={index}
                  variant={'filled'}
                  type={'custom'}
                  color={'white'}
                  backgroundColor={label.labelColor}
                  onClick={e => {
                    e.preventDefault();
                    addLabel(label.labelId);
                  }}
                >
                  {label.labelValue}
                </COBadge>
              );
            })}
          </ExistingLabelsWrapper>
          <>
            {isNullOrUndefined(addLabelForm.values) && (
              <ClickableCursor
                onClick={e => {
                  addLabelForm.setValues({ color: '' });
                }}
              >
                <LinkWithIcon icon={<SvgTagPlus />} label={tr(`LabelsTag.addLabel`, `Add label`)} />
              </ClickableCursor>
            )}
            {isNotNullOrUndefined(addLabelForm.values) && (
              <>
                <HorizontalLine variant={'tertiary'} />
                <Heading headingVariant={'h4'} title={tr(`LabelsTag.addLabelTitle`, `Add label`)} buttons={[]}></Heading>
                <VerticalFieldAndValue label={tr(`LabelsTag.newLabel`, `New label`)} labelMode={'normal'} errorMessage={addLabelForm.errors.name}>
                  <InputText value={addLabelForm.values.name} onChange={e => addLabelForm.setFieldValue(addLabelForm.names.name, e.target.value)} />
                </VerticalFieldAndValue>

                <VerticalFieldAndValue label={tr(`LabelsTag.tagColor`, `Tag color`)} labelMode={'normal'}>
                  <ColorRow>
                    {labelColors.map((color, index) => {
                      return (
                        <React.Fragment key={index}>
                          <ColorBox color={color.hexValue} onClick={e => addLabelForm.setFieldValue(addLabelForm.names.color, color.hexValue)}>
                            <div>{color.hexValue === addLabelForm.values.color && <SvgNewTagCheck />}</div>
                            <div>{color.colorName}</div>
                          </ColorBox>
                        </React.Fragment>
                      );
                    })}
                  </ColorRow>
                </VerticalFieldAndValue>
                <div>
                  <RingilButton label={tr(`LabelsTag.addLabelButton`, `Add label`)} onClick={() => addLabelForm.validateAndSend()} />
                </div>
              </>
            )}
          </>
        </>
      ) : (
        <>
          <Heading headingVariant={'h4'} title={tr(`LabelsTag.removeLabel`, `Remove label`)} buttons={[]}></Heading>
          <ExistingLabelsWrapper>
            {labels?.map((label, index) => {
              return (
                <COBadge
                  key={index}
                  variant={'outlined'}
                  type={'custom'}
                  backgroundColor={label.labelColor}
                  onClick={e => {
                    e.preventDefault();
                    archiveLabelHandler(label.labelId);
                  }}
                >
                  {label.labelValue}
                  <SvgDelete />
                </COBadge>
              );
            })}
          </ExistingLabelsWrapper>
          <div>
            <RingilButton
              label={tr(`LabelsTag.done`, `Done`)}
              onClick={() => {
                setShowDeleteLabel(false);
                setOpen(false);
              }}
              mode={'filled'}
            />
          </div>
        </>
      )}
    </LabelsWrapper>
  );

  if (isNullOrUndefined(labelsForEntity)) return <></>;

  return (
    <>
      <ButtonTag
        icon={<SvgLabel />}
        label={<div>{existingLabels()}</div>}
        actionIcon={labelsForEntity?.length > 0 ? <SvgTagEdit /> : <SvgTagPlus />}
        onClose={() => {
          setShowDeleteLabel(false);
          addLabelForm.overwriteValues(null);
        }}
        actionPane={pane}
        variant={labelsForEntity?.length > 0 ? 'defined' : 'empty'}
        hideButtons={true}
        autoClose={true}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
};

const LabelsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.2rem;
`;

const ExistingLabelsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.3rem;
`;

const LabelsWrapper = styled.div`
  display: flex;
  width: 600px;
  gap: 1rem;
  flex-direction: column;
`;

const ColorRow = styled.div`
  display: flex;
  gap: 0.4rem;
  flex-wrap: wrap;
`;
const ColorBox = styled.a`
  display: flex;
  background-color: ${props => props.color};
  height: 2rem;
  margin: 0.2rem 0;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 4px;
  white-space: nowrap;
  padding: 0.5rem;
`;
export default LabelsTag;
