import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { ApiContext } from '@api/api.tsx';
import * as yup from 'yup';

interface ComponentProps {
  title: string;
  onClick?: () => void;
  icon?: ReactElement;
  menu?: { label: string; code: string }[];
  onMenuSelect?: (code: string) => void;
  menuWidth?: string;
}

const InputActionButton: React.FC<ComponentProps> = ({ title, onClick, icon, menu, onMenuSelect, menuWidth }) => {
  const { tr } = useTranslationLgs();
  const [showMenu, setShowMenu] = useState(false);
  const [menuPosition, setMenuPosition] = useState<{ top: number; right: number } | null>(null);

  const handleButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowMenu(!showMenu);
    const rect = (e.currentTarget as HTMLElement).getBoundingClientRect();
    setMenuPosition({
      top: rect.bottom,
      right: window.innerWidth - rect.right,
    });
    if (onClick) onClick();
  };

  const handleMenuItemClick = (code: string) => {
    console.log('Menu item clicked:', code);
    if (onMenuSelect) onMenuSelect(code);
    setShowMenu(false);
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (showMenu && !document.getElementById('menu-container')?.contains(e.target as Node)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    if (showMenu) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showMenu]);

  return (
    <>
      <div
        className={'flex flex-row gap-2 h-[40px] items-center py-[8px] px-[24px] text-normal leading-4 text-content-brand border-2 border-solid cursor-pointer'}
        onClick={handleButtonClick}
      >
        {icon && <div>{icon}</div>}
        {title}
      </div>
      {showMenu &&
        menu &&
        menuPosition &&
        createPortal(
          <div
            id="menu-container"
            style={{
              position: 'absolute',
              top: menuPosition.top,
              right: menuPosition.right,
              width: '300px',
            }}
            className="bg-white shadow-lg border mt-2 z-50"
          >
            {menu.map(item => (
              <div key={item.code} className="py-2 px-4 cursor-pointer hover:bg-gray-100" onClick={() => handleMenuItemClick(item.code)}>
                {item.label}
              </div>
            ))}
          </div>,
          document.body,
        )}
    </>
  );
};

export default InputActionButton;
