import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Button } from 'primereact/button';
import { ApiContext } from '@api/api';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ButtonRow, RingilHeading } from '@components/styles.tsx';

interface ExpeditionInfo {
  applicationId: string;
  expeditionId: string;
  fromLocation: string;
  toLocation: string;
}

interface ComponentProps {
  onComplete: () => void;
  selectedExpeditions: ExpeditionInfo[];
}

export const useDachserExport = () => {
  const [visible, setVisible] = useState(false);

  const MassActionDachser: React.FC<ComponentProps> = ({ selectedExpeditions, onComplete }) => {
    const { tr } = useTranslationLgs();
    const { generateDachserExportAndDownload, downloadAttachment } = useContext(ApiContext);

    const exportExpeditions = () => {
      generateDachserExportAndDownload({ expeditionIds: selectedExpeditions.map(t => t.expeditionId) }, d => {
        downloadAttachment(d.attachmentId, data => {
          // @ts-ignore
          const linkSource = `data:${data.mimeType};base64,${data.data}`;
          const downloadLink = document.createElement('a');

          downloadLink.href = linkSource;
          downloadLink.download = data.fileName;
          downloadLink.click();
          setVisible(false);
          onComplete();
        });
      });
    };

    return (
      <>
        {/*// @ts-ignore*/}
        <Dialog onHide={() => setVisible(false)} visible={visible} closeOnEscape={false} blockScroll={true} style={{ width: '600px' }}>
          <RingilHeading>{tr(`MassActionDachser.dachserExport`, `Dachser export`)}</RingilHeading>
          <SidebarContent>
            {/*// @ts-ignore*/}
            <DataTable value={selectedExpeditions} emptyMessage={tr(`global.noRecordFound`, `Nebyly nalezeny žádné záznamy`)}>
              <Column header={tr(`MassActionDachser.applicationId`, `applicationId`)} field={'applicationId'}></Column>
              <Column header={tr(`MassActionDachser.fromLocation`, `fromLocation`)} field={'fromLocation'}></Column>
              <Column header={tr(`MassActionDachser.toLocation`, `toLocation`)} field={'toLocation'}></Column>
            </DataTable>
            <ButtonRow>
              <Button label={tr(`RingilSidebar.export`, `Export`)} className="p-mr-2" onClick={e => exportExpeditions()} />
              <Button label={tr(`RingilSidebar.cancel`, `Cancel`)} className="p-button-text" onClick={() => onComplete()} />
            </ButtonRow>
          </SidebarContent>
        </Dialog>
      </>
    );
  };

  return { MassActionDachser, setVisible };
};

const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
`;
