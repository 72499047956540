import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCheckCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={17} fill="none" {...props}>
    <path
      fill={props.fill ?? '#00C65E'}
      fillRule="evenodd"
      d="M8 16.5a8 8 0 1 0 0-16 8 8 0 0 0 0 16m4.707-10.293-1.414-1.414L6.5 9.586 4.707 7.793 3.293 9.207 6.5 12.414z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCheckCircle;
