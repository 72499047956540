import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import { ApiContext } from '@api/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { AdminModuleAllInheritedPreviewOutDTO, AdminModuleAllNotInheritedPreviewOutDTO, CompanyModuleEnum } from '@api/logsteo-api.v2';
import { InputSwitch } from 'primereact/inputswitch';
import { Sidebar } from 'primereact/sidebar';
import useForm from '@hooks/useForm/useForm.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import InputAdminAllCompanies from '@components/obsolete/Form/InputAdminAllCompanies/InputAdminAllCompanies.tsx';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';

interface ComponentProps {
  ownerCompanyId: string;
  inheriterCompanyId?: string;
  visible: boolean;
  onHide: () => void;
}

interface FormData {
  companyId: string;
}

/**
 * Use this when you want to add more inheritores for the owner...
 * @param ownerCompanyId
 * @param inheriterCompanyId
 * @param visible
 * @param onHide
 * @constructor
 */
const ManageInheritedModulesSidebar: React.FC<ComponentProps> = ({ ownerCompanyId, inheriterCompanyId, visible, onHide }) => {
  const form = useForm<FormData>(yup.object().shape({}), { companyId: inheriterCompanyId }, d => {}, true, false);
  const { tr } = useTranslationLgs();

  const { adminAddInheritingModuleByCompany, adminGetModulesForInheritedCompanyDependsOnParentCompany, adminDeleteInheritingModuleByCompany } = useContext(ApiContext);

  const [data, setData] = useState<AdminModuleAllInheritedPreviewOutDTO[]>([]);

  useEffect(() => {
    load();
  }, [form.values.companyId]);

  useEffect(() => {
    form.setFieldValue('companyId', inheriterCompanyId);
  }, [inheriterCompanyId]);

  const load = () => {
    adminGetModulesForInheritedCompanyDependsOnParentCompany(ownerCompanyId, form.values.companyId, d => {
      setData(d);
    });
  };

  const toggleModule = (moduleType: CompanyModuleEnum, checked: boolean) => {
    if (checked) {
      adminAddInheritingModuleByCompany(ownerCompanyId, form.values.companyId, moduleType, () => {
        load();
      });
    } else {
      adminDeleteInheritingModuleByCompany(ownerCompanyId, form.values.companyId, moduleType, () => {
        load();
      });
    }
  };

  return (
    <>
      {/*// @ts-ignore*/}
      <Sidebar visible={visible} onHide={() => onHide()} position={'right'} style={{ width: 'var(--default-sidebar-width)' }}>
        <HorizontalFieldValue label={'Company'} value={<InputAdminAllCompanies companyId={form.values.companyId} onChange={d => form.setFieldValue('companyId', d)} />} />
        {/*// @ts-ignore*/}
        <DataTable value={data} emptyMessage={tr(`global.noRecordFound`, `Nebyly nalezeny žádné záznamy`)}>
          <Column header={'Module type'} body={(row: AdminModuleAllNotInheritedPreviewOutDTO) => <div>{row.companyModule}</div>} />
          <Column
            style={{ width: '5rem' }}
            header={'Active'}
            body={(row: AdminModuleAllNotInheritedPreviewOutDTO) => <InputSwitch checked={row.isActive} onChange={e => toggleModule(row.companyModule, e.value)} />}
          ></Column>
        </DataTable>
      </Sidebar>
    </>
  );
};

export default ManageInheritedModulesSidebar;
