import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { isNotNullOrUndefined } from '@utils/utils.tsx';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import { ExpeditionTypeEnum } from '@api/logsteo-api.v2.tsx';
import SvgFtlLtl from '@icons/FtlLtl.tsx';
import { Dropdown } from 'primereact/dropdown';

interface ComponentProps {
  value?: ExpeditionTypeEnum;
  readOnly?: boolean;
  required?: boolean;
  onChange: (expeditionType: ExpeditionTypeEnum) => void;
}

const ExpeditionTypeTag: React.FC<ComponentProps> = ({ value, readOnly, required, onChange }) => {
  const { tr } = useTranslationLgs();
  const [internalValue, setInternalValue] = useState<ExpeditionTypeEnum>(value);

  const expeditionTypes = [ExpeditionTypeEnum.FULL_TRUCK_LOAD, ExpeditionTypeEnum.LESS_TRUCK_LOAD];

  const renderText = (code: ExpeditionTypeEnum) => {
    if (code == null) return null;
    return tr(`ExpeditionTypeTag.${code}`, `ExpeditionTypeTag.${code}`);
  };

  return (
    <>
      <ButtonTag
        label={tr('ExpeditionTypeTag.title', 'Typ přepravy')}
        required={required}
        readonly={readOnly}
        value={renderText(value)}
        onSave={onSuccess => {
          onChange(internalValue);
          onSuccess();
        }}
        icon={<SvgFtlLtl />}
        autoClose
      >
        <Dropdown
          options={expeditionTypes}
          value={internalValue}
          onChange={e => setInternalValue(e.value)}
          itemTemplate={i => <div>{renderText(i)}</div>}
          valueTemplate={i => <div>{i != null ? renderText(i) : 'Neuvedeno'}</div>}
        />
      </ButtonTag>
    </>
  );
};

export default ExpeditionTypeTag;
