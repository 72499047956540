import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Dialog } from 'primereact/dialog';
import InputYesNoPlain from '../Form/InputYesNoPlain/InputYesNoPlain';
import useForm, { proxiedPropertiesOf } from '@hooks/useForm/useForm';
import { Button } from 'primereact/button';
import { ButtonRow } from '@components/styles';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import useNavigation from '@hooks/useNavigation';
import * as yup from 'yup';

interface ComponentProps {
  visible: boolean;
  onCancel: () => void;
  isLinkedToSN: boolean;
  applicationId: string;
}

interface Form {
  copyAttachments: boolean;
  linkToSN: boolean;
  copySNAttachments: boolean;
}

const DuplicateExpeditionDialog: React.FC<ComponentProps> = ({ visible, onCancel, isLinkedToSN, applicationId }) => {
  const { tr } = useTranslationLgs();
  const navigation = useNavigation();
  const form = useForm<Form>(
    yup.object().shape({}),
    {
      copyAttachments: false,
      linkToSN: false,
      copySNAttachments: false,
    },
    data => save(data),
    false,
    false,
  );
  const names = proxiedPropertiesOf<Form>();

  const save = (data: Form) => {
    navigation.navigate(
      navigation.urlFunctions.createDuplicateExpedition(applicationId, {
        link: true,
        copyAttachments: form.values.copyAttachments,
        copySNAttachments: form.values.copySNAttachments,
        linkToSN: form.values.linkToSN,
      }),
    );
  };
  return (
    <>
      {/*
        // @ts-ignore*/}
      <Dialog
        visible={visible}
        baseZIndex={1000000}
        onHide={() => onCancel()}
        header={<h4>{tr(`DuplicateExpeditionDialog.duplicateExpedition`, `Duplicate expedition`)}</h4>}
        className="dialog-confirm dialog-md"
      >
        <p>{tr(`DuplicateExpeditionDialog.title`, `Co chcete pri duplikaci zachovat? Vazba na Expedici bude zachovana.`)}</p>
        <HorizontalFieldValue
          label={tr(`DuplicateExpeditionDialog.kopirovatPrilohyZExp`, `Kopirovat prilohy z EXP`)}
          value={<InputYesNoPlain value={form.values.copyAttachments} onChange={v => form.setFieldValue(names.copyAttachments, v)} />}
        />
        {isLinkedToSN && (
          <>
            <HorizontalFieldValue
              label={tr(`DuplicateExpeditionDialog.linkovatNaSn`, `Linkovat na SN`)}
              value={<InputYesNoPlain value={form.values.linkToSN} onChange={v => form.setFieldValue(names.linkToSN, v)} />}
            />
            <HorizontalFieldValue
              label={tr(`DuplicateExpeditionDialog.kopirovatPrilohyZSn`, `Kopirovat prilohy z SN`)}
              value={<InputYesNoPlain value={form.values.copySNAttachments} onChange={v => form.setFieldValue(names.copySNAttachments, v)} />}
            />
          </>
        )}

        <ButtonRow>
          <Button label={tr(`DuplicateExpeditionDialog.duplicate`, `Duplicate`)} onClick={e => form.validateAndSend()} />
          <Button label={tr(`DuplicateExpeditionDialog.cancel`, `Cancel`)} className="p-button-text" onClick={() => onCancel()} />
        </ButtonRow>
      </Dialog>
    </>
  );
};

export default DuplicateExpeditionDialog;
