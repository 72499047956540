import React, { useContext, useState } from 'react';
import useForm from '@hooks/useForm/useForm';
import { ApiContext } from '@api/api';
import { ShipmentPartiesData, ShipmentPartiesForm } from '../forms/ShipmentPartiesForm';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { RingilSidebar } from '@components/obsolete/RingilSidebar/RingilSidebar.tsx';
import * as yup from 'yup';

interface ComponentProps {
  initialData?: ShipmentPartiesData;
  shipmentId: string;
  companyId: string;
}

export const useCreateEditShipmentPartiesSidebar = (onComplete: () => void) => {
  const [visible, setVisible] = useState(false);
  const { tr } = useTranslationLgs();

  const EditShipmentPartiesSidebar: React.FC<ComponentProps> = ({ initialData, shipmentId, companyId }) => {
    const { updateShipmentUpdateDataForLocationStatus } = useContext(ApiContext);

    const form = useForm<ShipmentPartiesData>(
      yup.object().shape({}),
      initialData,
      v => {
        updateShipmentUpdateDataForLocationStatus(
          shipmentId,
          {
            transportationType: form.values.transportationTypeCode,

            loadingLocationId: form.values.loadingAddress.locationId,
            unloadingLocationId: form.values.unloadingAddress.locationId,
          },
          () => {
            setVisible(false);
            onComplete();
          },
        );
      },
      true,
      true,
    );

    return (
      <RingilSidebar
        visible={visible}
        headerText={tr(`EditShipmentLocationSidebar.updateShipment`, `Update shipment`)}
        onHide={() => setVisible(false)}
        onSave={() => form.validateAndSend()}
      >
        {form.values && <ShipmentPartiesForm showTransportationType={true} form={form} inSidebar={true} showParties={false} showDates={false} showLocations={true} />}
      </RingilSidebar>
    );
  };

  return { EditShipmentPartiesSidebar, setVisible };
};
