import React, { useContext, useState } from 'react';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import SupplierNotificationInternalNoteForm, { SupplierNotificationInternalNoteData } from '../forms/SupplierNotificationInternalDataForm';
import useForm from '@hooks/useForm/useForm.tsx';
import { RingilSidebar } from '@components/obsolete/RingilSidebar/RingilSidebar.tsx';
import * as yup from 'yup';

interface ComponentProps {
  initialData?: SupplierNotificationInternalNoteData;
  supplierNotificationId: string;
  companyId: string;
}

export const useCreateEditSupplierNotificationInternalNoteSidebar = (onComplete: () => void) => {
  const [visible, setVisible] = useState(false);

  const EditSupplierNotificationInternalNoteSidebar: React.FC<ComponentProps> = ({ initialData, supplierNotificationId, companyId }) => {
    const { updateNotificationSupplierUpdateInternalNote } = useContext(ApiContext);

    const form = useForm<SupplierNotificationInternalNoteData>(
      yup.object().shape({}),
      initialData,
      v => {
        updateNotificationSupplierUpdateInternalNote(
          supplierNotificationId,
          {
            internalNote: form.values.internalNote,
          },
          () => {
            setVisible(false);
            onComplete();
          },
        );
      },
      true,
      true,
    );

    const { tr } = useTranslationLgs();

    return (
      <RingilSidebar
        visible={visible}
        headerText={tr(`EditSupplierNotificationInternalNoteSidebar.updateSupplierNotification`, `Update supplier notification`)}
        onHide={() => setVisible(false)}
        onSave={() => form.validateAndSend()}
      >
        {form.values && <SupplierNotificationInternalNoteForm form={form} inSidebar={true} />}
      </RingilSidebar>
    );
  };

  return { EditSupplierNotificationInternalNoteSidebar, setVisible };
};
