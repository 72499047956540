import React, { useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import * as yup from 'yup';
import useCountries from '@hooks/use-countries/useCountries.tsx';
import { Dropdown } from 'primereact/dropdown';

interface ComponentProps {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

const InputCountry: React.FC<ComponentProps> = ({ value, onChange, disabled }) => {
  const countries = useCountries();

  const { tr } = useTranslationLgs();
  return (
    <>
      <Dropdown
        options={countries}
        value={value}
        onChange={e => onChange(e.value)}
        optionValue={'code'}
        optionLabel={'name'}
        style={{ width: '100%', height: '42px' }}
        disabled={disabled}
      />
    </>
  );
};

export default InputCountry;
