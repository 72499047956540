import React, { useContext, useState } from 'react';
import { CarrierQuoteStateEnum, CustomerQuoteStateEnum, EmailStatusEnum, EntityTypeEnum, QuoteGroupEnum, QuotesPreviewOutDTO } from '@api/logsteo-api.v2';
import RingilDataTable from '@components/ringil3/RingilDataTable/RingilDataTable';
import { Col, RowWithGap } from '@components/styles.tsx';
import LinkWithIcon from '@components/ringil3/Links/LinkWithIcon';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { dateFormatDateTime, mapFromAPIDateTime, mapFromAPIToDateLong } from '@utils/date';
import { Dayjs } from 'dayjs';
import styled, { css } from 'styled-components';
import COBadge from '@components/ringil3/COBadge/COBadge.tsx';
import { formatPrice, isNotNullOrUndefined } from '@utils/utils';
import { Ringil3Note } from '@components/ringil3/styles';
import RectangleButton from '@components/ringil3/Buttons/RectangleButton';
import SvgMessage from '@components/ringil3/icons/Message';
import SvgWarning from '@components/ringil3/icons/Warning';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { useCreateAcceptQuote } from '@components/ringil3/Features/Expedition/QuoteTable/AcceptQuote/AcceptQuote.tsx';
import CustomerChatComponent from '@components/obsolete/Chat/CustomerChatComponent.tsx';
import { useCreateRejectQuote } from '@components/ringil3/Features/Expedition/QuoteTable/RejectQuote/RejectQuote.tsx';

interface ComponentProps {
  quotes?: QuotesPreviewOutDTO[];
  onChanged: () => void;
  companyId: string;
  expeditionId: string;
}

const SpotQuoteTable: React.FC<ComponentProps> = ({ quotes, onChanged, companyId, expeditionId }) => {
  const { tr } = useTranslationLgs();
  const { loggedUser } = useContext(AuthenticatedUserContext);
  const [selectedCarrierId, setSelectedCarrierId] = useState(undefined);

  const renderName = (row: QuotesPreviewOutDTO) => {
    return <div>{row.name}</div>;
  };

  const disabled = false;

  const { AcceptQuote, setSidebarData } = useCreateAcceptQuote();
  const { RejectQuote, setSidebarData: setRejectSidebarData } = useCreateRejectQuote();

  const renderActions = (row: QuotesPreviewOutDTO) => {
    const hasDifferentSlot = row.locations.filter(location => location.isDifferent).length > 0;
    return (
      <>
        {row.quoteGroup === QuoteGroupEnum.RINGIL && (
          <>
            {row.carrierQuoteState === CarrierQuoteStateEnum.OFFERED_PRICE && !row.isExpired && (
              <ColSameSize>
                <RectangleButton
                  label={tr('customerExpeditionDetail.tabCarrierPending.pickButton', 'Vybrat')}
                  onClick={() =>
                    setSidebarData({
                      selectedQuoteId: row.id,
                      selectedCarrierName: row.name,
                      applicationId: row.expeditionApplicationId,
                      companyId: loggedUser.companyId,
                    })
                  }
                />
                <RectangleButton
                  label={tr('customerExpeditionDetail.tabCarrierPending.rejectButton', 'Odmítnout')}
                  onClick={() => {
                    setRejectSidebarData({
                      selectedQuoteId: row.id,
                      selectedCarrierName: row.name,
                      applicationId: row.expeditionApplicationId,
                      companyId: loggedUser.companyId,
                    });
                  }}
                />
              </ColSameSize>
            )}
          </>
        )}
      </>
    );
  };

  const renderMessages = (row: QuotesPreviewOutDTO) => {
    const hasDifferentSlot = row.locations.filter(location => location.isDifferent).length > 0;
    return (
      <MessagesAndWarning>
        {row.messagesCount == 0 && (
          <LinkWithIcon onClick={() => setSelectedCarrierId(row.carrierId)} icon={<SvgMessage />} label={tr(`QuoteTable.writeMessage`, `Write message`)}></LinkWithIcon>
        )}
        {row.messagesCount > 0 && (
          <LinkWithIcon
            onClick={() => setSelectedCarrierId(row.carrierId)}
            label={tr(`QuoteTable.messagesReply`, `Message: {{messagesCount}}`, {
              messagesCount: row.messagesCount,
            })}
          ></LinkWithIcon>
        )}
        {hasDifferentSlot && <LinkWithIcon label={tr(`SpotQuoteTable.diferrentSlots`, `Diferrent slots`)} icon={<SvgWarning />}></LinkWithIcon>}
      </MessagesAndWarning>
    );
  };

  const mapToColor = (emailStatus?: EmailStatusEnum) => {
    switch (emailStatus) {
      case EmailStatusEnum.DELIVERED:
      case EmailStatusEnum.READ_EMAIL:
      case EmailStatusEnum.READ_IN_APP:
      case EmailStatusEnum.SEND:
        return 'var(--ringil3-complementary-draft)';
      case EmailStatusEnum.FAILED:
        return 'var(--ringil3-complementary-error-color)';
      default:
        return '';
    }
  };

  const mapCarrierQuoteStateToColor = (emailStatus?: CarrierQuoteStateEnum) => {
    switch (emailStatus) {
      case CarrierQuoteStateEnum.ACCEPTED_BY_CUSTOMER:
      case CarrierQuoteStateEnum.ACCEPTED_NEED_RECONFIRM:
        return 'var(--ringil3-complementary-success)';
      case CarrierQuoteStateEnum.OFFERED_PRICE:
        return 'var(--ringil3-complementary-draft)';
      case CarrierQuoteStateEnum.REJECTED_BY_CARRIER:
        return 'var(--ringil3-complementary-error-color)';
      case CarrierQuoteStateEnum.NEW:
      case CarrierQuoteStateEnum.REJECTED_BY_CUSTOMER:
      default:
        return '';
    }
  };

  const mapCustomerQuoteStateToColor = (emailStatus?: CustomerQuoteStateEnum) => {
    switch (emailStatus) {
      case CustomerQuoteStateEnum.ASSIGNED_MANUALLY_BY_CUSTOMER:
      case CustomerQuoteStateEnum.ACCEPTED_BY_CARRIER:
        return 'var(--ringil3-complementary-success)';
      case CustomerQuoteStateEnum.REJECTED_BY_CARRIER:
        return 'var(--ringil3-complementary-error-color)';
      default:
        return '';
    }
  };

  const renderState = (row: QuotesPreviewOutDTO) => {
    return (
      <div>
        {row.emailStatus && row.carrierActionAt && (
          <>
            {row.distributionType === 'SPOT' && (
              <DescriptionDistributionStatus color={mapCarrierQuoteStateToColor(row.carrierQuoteState)}>
                {tr(`SpotQuoteTable.carrierQuoteState.${row.carrierQuoteState}`, `${row.carrierQuoteState}`)}
              </DescriptionDistributionStatus>
            )}{' '}
            {row.distributionType === 'FIXED_PRICE' && (
              <DescriptionDistributionStatus color={mapCustomerQuoteStateToColor(row.customerQuoteState)}>
                {tr(`SpotQuoteTable.customerQuoteState.${row.customerQuoteState}`, `${row.customerQuoteState}`)}
              </DescriptionDistributionStatus>
            )}
            <div className="p-d-flex p-flex-wrap">
              <RowWithGap>
                <Date>{mapFromAPIToDateLong(row.emailStatusCreatedAt)}</Date>
                <div className="tooltip">
                  <img src={'/images/icons/ringil3/envelope.svg'} alt="info" className="p-button-text tooltip-button p-p-0" />
                  <div className="tooltip-container">
                    <div className="descrip-wrapper">
                      {row.emailStatusDetail?.map((item, index) => DescriptionTooltip(index, item.userName, item.emailStatus, mapFromAPIDateTime(item.time), item.email))}
                    </div>
                  </div>
                </div>
              </RowWithGap>
            </div>
          </>
        )}
        {row.emailStatus && !row.carrierActionAt && (
          <>
            <DescriptionDistributionStatus color={mapToColor(row.emailStatus)}>
              {tr(`SpotQuoteDataTable.emailStatus.${row.emailStatus}`, 'SpotQuoteDataTable.emailStatus.${emailStatus}')}
            </DescriptionDistributionStatus>
            <div className="p-d-flex p-flex-wrap">
              <RowWithGap>
                <Date>{mapFromAPIToDateLong(row.emailStatusCreatedAt)}</Date>
                <div className="tooltip">
                  <img src={'/images/icons/ringil3/envelope.svg'} alt="info" className="p-button-text tooltip-button p-p-0" />
                  <div className="tooltip-container">
                    <div className="descrip-wrapper">
                      {row.emailStatusDetail?.map((item, index) => DescriptionTooltip(index, item.userName, item.emailStatus, mapFromAPIDateTime(item.time), item.email))}
                    </div>
                  </div>
                </div>
              </RowWithGap>
            </div>
          </>
        )}
      </div>
    );
  };

  const DescriptionTooltip = (index: number, name: string, status: string, dateTime: Dayjs, email: string) => {
    return (
      <DescriptionInner key={index}>
        <div className="descrip-detail__name">
          <strong>{name}</strong>
        </div>
        <div className="descrip-detail__name">
          <strong>{email}</strong>
        </div>
        <div className="p-d-flex p-jc-between">
          <small className="descrip-detail__status">{tr(`SpotQuoteDataTable.emailStatus.${status}`, 'SpotQuoteDataTable.emailStatus.${status}')}</small>
          <small className="descrip-detail__data">{dateFormatDateTime(dateTime)}</small>
        </div>
      </DescriptionInner>
    );
  };

  const renderPrice = (row: QuotesPreviewOutDTO) => {
    if (row.distributionType === 'SPOT')
      return (
        <div>
          {row.offeredPrice && row.carrierQuoteState !== CarrierQuoteStateEnum.REJECTED_BY_CARRIER && (
            <Col>
              <COBadge variant={'filled'} type={row.carrierQuoteState === CarrierQuoteStateEnum.REJECTED_BY_CUSTOMER ? 'neutral' : 'positive'}>
                {[formatPrice(row.offeredPrice, row.currency), row.distance ? `${(row.offeredPrice / row.distance).toFixed(2)} ${row.currency}/km` : null]
                  .filter(t => t != null)
                  .join(' | ')}
              </COBadge>
              <Ringil3Note>
                {tr(`QuoteTable.offerValidTillDateWithTime`, `Offer valid till {{dateWithTime}}`, {
                  dateWithTime: mapFromAPIToDateLong(row.validTill),
                })}
              </Ringil3Note>
            </Col>
          )}
        </div>
      );

    return (
      <div>
        {row.offeredPrice &&
          (row.customerQuoteState === CustomerQuoteStateEnum.ACCEPTED_BY_CARRIER || row.customerQuoteState === CustomerQuoteStateEnum.ASSIGNED_MANUALLY_BY_CUSTOMER) && (
            <Col>
              <COBadge variant={'filled'} type={'positive'}>
                {[formatPrice(row.offeredPrice, row.currency), row.distance ? `${(row.offeredPrice / row.distance).toFixed(2)} ${row.currency}/km` : null]
                  .filter(t => t != null)
                  .join(' | ')}
              </COBadge>
            </Col>
          )}
      </div>
    );
  };

  return (
    <>
      <RingilDataTable
        data={quotes}
        columns={[{ name: 'Dopravce' }, { name: 'Stav' }, { name: 'Nabidnuta cena' }, { name: 'Komunikace' }, { name: '' }]}
        renderers={[renderName, renderState, renderPrice, renderMessages, renderActions]}
      ></RingilDataTable>
      <AcceptQuote onCompleted={() => onChanged()} />
      <RejectQuote onCompleted={() => onChanged()} />
      <CustomerChatComponent
        companyId={companyId}
        entityId={expeditionId}
        entityType={EntityTypeEnum.EXPEDITION}
        visible={isNotNullOrUndefined(selectedCarrierId)}
        selectedCarrierId={selectedCarrierId}
        onHide={() => setSelectedCarrierId(undefined)}
        readOnly={false}
      />
    </>
  );
};

const DescriptionInner = styled.div`
  display: flex;
  flex-direction: column;
`;

interface DescriptionDistributionStatusProps {
  color: string;
}

const DescriptionDistributionStatus = styled.div<DescriptionDistributionStatusProps>`
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `}
`;

const Date = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: var(--ringil3-gr4);
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
`;

const ColSameSize = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  justify-content: center;
  flex: 1;
`;

const MessagesAndWarning = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`;

export default SpotQuoteTable;
