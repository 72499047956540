import React from 'react';
import RingilButton from '@components/framework/buttons/RingilButton.tsx';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import SvgCoButtonPlus from '@icons/CoButtonPlus.tsx';

interface ComponentProps {
  title: string;
  onClick: () => void;
  fullWidth?: boolean;
  type?: 'ROUNDED' | 'SQUARE';
  hideButton?: boolean;
}

const AddButton: React.FC<ComponentProps> = ({ onClick, title, fullWidth = false, type = 'SQUARE', hideButton = false }) => {
  const classes = type == 'SQUARE' ? 'rounded border border-solid border-content-gr2' : 'rounded-2xl';
  return (
    <div
      className={twMerge('inline-flex', clsx({ 'flex-grow': fullWidth == true }, { 'cursor-pointer': hideButton === false }))}
      onClick={hideButton ? () => {} : onClick}
    >
      <div className={twMerge(`flex flex-row  gap-4 justify-between items-center p-4  bg-white  ${classes}`, clsx({ 'flex-grow': fullWidth == true }))}>
        <div className={'text-content-brand text-base font-medium  flex-grow'}>{title}</div>
        {hideButton === false && <RingilButton mode={'rect-small'} leftIcon={<SvgCoButtonPlus />} />}
      </div>
    </div>
  );
};

export default AddButton;
