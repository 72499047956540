import React, { useContext, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import RingilSidebar from '@components/ringil3/Sidebar/RingilSidebar.tsx';
import { Field } from '@components/framework/formfield/Field.tsx';
import RingilInputText from '@components/framework/input/RingilInput/RingilInputText.tsx';
import useForm from '@hooks/useForm/useForm.tsx';
import RingilTextArea from '@components/framework/input/RingilTextArea.tsx';
import * as yup from 'yup';

interface ComponentProps {}

interface Form {
  personName: string;
  personTypeAndId?: string;
  carPlate?: string;
  note?: string;
}

export const useCreateNewEntrySidebar = () => {
  const [visible, setVisible] = useState(false);

  const NewEntrySidebar: React.FC<ComponentProps> = () => {
    //const { addNewPersionEntry } = useContext(ApiContext);
    const { tr } = useTranslationLgs();

    const form = useForm<Form>(
      yup.object().shape({}),
      {
        note: null,
        personTypeAndId: null,
        personName: null,
        carPlate: null,
      },
      d => save(d),
      false,
      false,
    );

    const save = (data: Form) => {};

    return (
      <RingilSidebar
        visible={visible}
        heading={'Nová návštěva'}
        onBack={() => setVisible(false)}
        onContinue={() => {
          form.validateAndSend();
        }}
      >
        <div className={'flex flex-col gap-4'}>
          <Field label={'Jméno a příjmení'} required>
            <RingilInputText value={form.values.personName} onChange={v => form.setFieldValue(form.names.personName, v)} />
          </Field>
          <Field label={'Typ a číslo dokladu'}>
            <RingilInputText value={form.values.personTypeAndId} onChange={v => form.setFieldValue(form.names.personTypeAndId, v)} />
          </Field>
          <Field label={'SPZ'}>
            <RingilInputText value={form.values.carPlate} onChange={v => form.setFieldValue(form.names.carPlate, v)} />
          </Field>
          <Field label={'Poznámka'}>
            <RingilTextArea value={form.values.note} onChange={v => form.setFieldValue(form.names.note, v)} />
          </Field>
        </div>
      </RingilSidebar>
    );
  };

  return { NewEntrySidebar, visible, setVisible };
};
