import React, { useContext, useState } from 'react';
import useForm from '@hooks/useForm/useForm';
import { ApiContext } from '@api/api';
import ShipmentCargoInfoForm, { ShipmentCargoInfoData } from '../forms/ShipmentCargoInfoForm';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { TransportationTypeEnum } from '@api/logsteo-api.v2';
import { RingilSidebar } from '@components/obsolete/RingilSidebar/RingilSidebar.tsx';
import * as yup from 'yup';

interface ComponentProps {
  initialData?: ShipmentCargoInfoData;
  shipmentId: string;
  companyId: string;
  transportationTypeCode: TransportationTypeEnum;
}

export const useCreateEditShipmentCargoInfoSidebar = (onComplete: () => void) => {
  const [visible, setVisible] = useState(false);

  const EditShipmentCargoInfoSidebar: React.FC<ComponentProps> = ({ initialData, shipmentId, companyId, transportationTypeCode }) => {
    const { updateShipmentUpdateDataFoCargoInfo } = useContext(ApiContext);
    const { tr } = useTranslationLgs();

    const form = useForm<ShipmentCargoInfoData>(
      yup.object().shape({}),
      initialData,
      v => {
        updateShipmentUpdateDataFoCargoInfo(
          shipmentId,
          {
            cargoType: form.values.cargoType,
            specialRequirementIds: form.values.specialRequirementIds,
            containerInfo: form.values.containerInfo,
            customsTypeCode: form.values.customsTypeCode,
            referralCode: form.values.referralCode,
            shipmentId: form.values.shipmentId,
          },
          () => {
            setVisible(false);
            onComplete();
          },
        );
      },
      true,
      true,
    );

    return (
      <RingilSidebar
        visible={visible}
        headerText={tr(`EditShipmentCargoInfoSidebar.updateShipment`, `Update shipment`)}
        onHide={() => setVisible(false)}
        onSave={() => form.validateAndSend()}
      >
        {form.values && <ShipmentCargoInfoForm form={form} companyId={companyId} inSidebar={true} transportationTypeCode={transportationTypeCode} />}
      </RingilSidebar>
    );
  };

  return { EditShipmentCargoInfoSidebar, setVisible };
};
