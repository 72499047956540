import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';

import { isNullOrUndefined } from '@utils/utils.tsx';
import { DropdownItem, LocationDetailWithTimeslots, LocationOverview } from '@type/types.ts';
import { DayEnum, TimeResponse } from '@api/logsteo-api.v2.tsx';

dayjs.extend(utc);
dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Prague');

/**
 * This function generate days inside interval.
 * @param start
 * @param end
 */
export const daysInterval = (start: Dayjs, end: Dayjs): Dayjs[] => {
  if (end == null || !end.isValid()) return [start];

  let date = start;
  const a = [];

  while (date.isSameOrBefore(end, 'day')) {
    a.push(date);
    console.log(`Adding date: ${date}`);
    date = date.add(1, 'day');
  }

  console.log(`daysInterval: ${JSON.stringify(a)}`);
  return a;
};

export const dateFormatOnlyDateLong = (date: Dayjs) => {
  return date.format('D. MMMM YYYY');
};

export const formatAPIDateOnly = (date: Dayjs) => {
  return date.utc().format('YYYY-MM-DD');
};

export const formatAPITimeOnly = (date: Dayjs) => {
  return date.utc().format('HH:mm');
};

export const dateFormatOnlyDateShort = (date: Dayjs, shortYear = false): string => {
  if (isNullOrUndefined(date)) return null;
  if (shortYear) {
    return date.format('D.M.YY');
  } else {
    return date.format('D.M.YYYY');
  }
};

export const dateFormatToDropDownItemHour = (date: Dayjs): DropdownItem => {
  return {
    label: date.format('HH:mm'),
    value: date.format('HH:mm'),
  };
};

export const monthIndex = (date: Dayjs) => {
  return dayjs(date).month();
};

export const dayOfMonthIndex = (date: Dayjs) => {
  return dayjs(date).date();
};

export const shortDateFormat = 'd.m.y';

export const dateFormatDateTime = (date: Dayjs): string => {
  if (isNullOrUndefined(date)) return null;
  return date.format('DD.MM.YYYY, HH:mm');
};

const isContinuousInterval = (timeslots: LocationDetailWithTimeslots['timeslots']): boolean => {
  let isContinuousInterval = true;
  timeslots.map((slot, index) => {
    if (index < timeslots.length - 1) {
      if (
        dayjs(slot.day)
          .add(1, 'day')
          .isSame(dayjs(timeslots[index + 1].day), 'day')
      ) {
        return null;
      } else {
        isContinuousInterval = false;
      }
    }
  });
  return isContinuousInterval;
};

export const renderTimeslots = (timeslots: LocationDetailWithTimeslots['timeslots'] | LocationOverview['dayTimeslots']): string => {
  if (timeslots.length === 0) {
    return null;
  } else if (isContinuousInterval(timeslots) && timeslots.length > 1) {
    return `${formatDate(timeslots[0].day)} - ${formatDate(timeslots[timeslots.length - 1].day)}`;
  } else {
    const days = timeslots.map((slot, index) => `${formatDate(slot.day)}`);
    return days.join(', ');
  }
};

export const dayJsToDate = (date: Dayjs) => dayjs(date).toDate();

export const areDatesSameMinute = (date1: Dayjs, date2: Dayjs) => {
  return dayjs(date1).isSame(dayjs(date2), 'minute');
};

export const formatDate = (date: string | Dayjs | Date, format: string = 'D.M.YYYY') => dayjs(date).format(format);

export const setTime = (previous: Dayjs, time: string) => {
  if (isNullOrUndefined(time)) return previous;
  const splited = time.split(':');
  const newTime = previous.set('hour', parseInt(splited[0])).set('minute', parseInt(splited[1])).set('second', 0).set('millisecond', 0);
  return newTime;
};

export const mapToAPIDateTime = (date: Dayjs): string => {
  if (isNullOrUndefined(date)) return null;
  return date.format();
};

export const localDateTimeToApi = (localDateTime: string, timezone: string): string => {
  if (isNullOrUndefined(localDateTime)) return null;
  return dayjs.tz(localDateTime, timezone).format();
};

/**
 * Map the Date[] or Date to the array of Dayjs object.
 * If the input variable is Date, then the result will be
 * [date.startDay(), date.endOfDay()]
 *
 * If the input variable is Date[].lenght == 2, then the result will be
 * [date[0].startDay(), date[1].endOfDay()]
 *
 * @param date
 */
export const mapToInterval = (date: Date[] | Date): Dayjs[] => {
  if (Array.isArray(date)) {
    const start = fromDate(date[0]);
    const end = fromDate(date.length == 2 && date[1] !== null ? date[1] : date[0]);
    return [start.startOf('day'), end.add(1, 'day').startOf('day')];
  }
  alert('over to, ze to funguje date[0] => date');
  const start = fromDate(date);
  const end = fromDate(date);
  return [start.startOf('day'), end.add(1, 'day').startOf('day')];
};

export const mapFromAPIDateTime = (stringDate: string): Dayjs => {
  if (isNullOrUndefined(stringDate)) return null;
  const date = dayjs(stringDate);
  //console.log(`stringDate: ${stringDate}, date: ${date} isValid: ${date.isValid()}, ${date.tz('Europe/Sofia')}`);
  return date.tz('Europe/Prague');
};

export const toTime = (date: Dayjs): string => {
  if (isNullOrUndefined(date)) return null;
  return date.format('HH:mm');
};

export const mapFromAPIToTime = (date: string): string => {
  if (isNullOrUndefined(date)) return date;
  return mapFromAPIDateTime(date).tz('Europe/Prague').format('HH:mm');
};

export const mapFromAPIToDateShort = (date: string): string => {
  if (isNullOrUndefined(date)) return date;
  return mapFromAPIDateTime(date).tz('Europe/Prague').format('DD.MM.YYYY');
};

export const mapFromAPIToDateLong = (date: string): string => {
  if (isNullOrUndefined(date)) return date;
  return mapFromAPIDateTime(date).tz('Europe/Prague').format('DD.MM.YYYY HH:mm');
};

export const fromDate = (date: Date): Dayjs => {
  return date === null ? null : dayjs(date).tz('Europe/Prague');
};

export const mapFromApiToDate = (stringDate: string): Date => {
  if (isNullOrUndefined(stringDate)) return null;
  return dayjs(stringDate).toDate();
};

export const renderFromToOnlyDate = (from: string, to: string) => {
  if (isNullOrUndefined(from)) return <></>;
  if (isNullOrUndefined(to)) return <></>;

  return `${mapFromAPIToDateShort(from)} - ${mapFromAPIToDateShort(to)}`;
};

export const getMyTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const formatTimeInMinutesToHourString = (timeInMin: number) => {
  const hours = Math.floor(timeInMin / 60);
  const minutes = timeInMin - 60 * Math.floor(timeInMin / 60);
  return `${hours.toString().length == 2 ? hours : '0' + hours}:${minutes.toString().length == 2 ? minutes : '0' + minutes}`;
};

export const findNextDay = (date: Dayjs, dayInWeek: DayEnum): Dayjs => {
  let nextDay = date.add(1, 'day');
  const day = dayEnumToDayJS(dayInWeek);

  while (nextDay.day() !== day) {
    nextDay = nextDay.add(1, 'day');
  }
  return nextDay;
};

export const dayEnumToDayJS = (day: DayEnum): number => {
  switch (day) {
    case DayEnum.MONDAY:
      return 1;
    case DayEnum.TUESDAY:
      return 2;
    case DayEnum.WEDNESDAY:
      return 3;
    case DayEnum.THURSDAY:
      return 4;
    case DayEnum.FRIDAY:
      return 5;
    case DayEnum.SATURDAY:
      return 6;
    case DayEnum.SUNDAY:
      return 0;
  }
};

export const createZonedDateTime = (localDate: string, hourString: string, timezone: string): Dayjs => {
  if (localDate == null || hourString == null || timezone == null) return null;
  return dayjs.tz(`${localDate}T${hourString}`, timezone);
};

export const printDateInterval = (start: Dayjs, end: Dayjs) => {
  return `${start.format('DD.MM. HH:mm')} - ${end.format('HH:mm')}`;
};

/**
 * Creates local date time from iso8601 string and timezone.
 * @param iso8601
 * @param timeZone
 */
export const createLocalDateTimeTZ = (iso8601: string, timeZone: string) => {
  const localDateTime = mapFromAPIDateTime(iso8601).tz(timeZone).format(`YYYY-MM-DDTHH:mm`);
  const splitted = localDateTime.split(`T`);
  const localDate = splitted[0];
  const time = splitted[1];
  return { localDate, time, timeZone, localDateTime };
};

export const createLocalDateTime = (dateTimeResponse: TimeResponse): string => {
  if (dateTimeResponse == null) return null;
  return `${dateTimeResponse.localDate}T${dateTimeResponse.hourString}`;
};
