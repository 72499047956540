import React, { useContext, useEffect } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { Sidebar } from 'primereact/sidebar';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { isNullOrUndefined } from '@utils/utils';
import useForm, { proxiedPropertiesOf } from '@hooks/useForm/useForm';
import { OpeningEntityTypeEnum, OpeningHourDefinitionsDTO, OpeningHourDefinitionsInDTO } from '@api/logsteo-api.v2';
import { findValidationMessage } from '@utils/validation';
import OpeningHourDefinitionForm, { emptyOpeningHours, openingHoursValidationSchema } from '@components/obsolete/OpeningHoursDefinition/OpeningHourDefinitionForm.tsx';
import { Row } from '@components/styles.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import InputYesNoPlain from '@components/obsolete/Form/InputYesNoPlain/InputYesNoPlain.tsx';

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  onCompleted: () => void;
  locationId?: string;
  editRampId?: string;
}

interface FormData {
  rampName: string;
  isPrivate: boolean;
  openingHourDefinitions: OpeningHourDefinitionsInDTO;
  isShowOnRampDashboard: boolean;
  maxSkuCount?: number;
  maxReservationCount?: number;
  isTemporaryClosed: boolean;
}

const initialData: FormData = {
  rampName: '',
  isPrivate: false,
  openingHourDefinitions: emptyOpeningHours(OpeningEntityTypeEnum.RAMP),
  isShowOnRampDashboard: null,
  isTemporaryClosed: false,
};

const AddEditRamp: React.FC<ComponentProps> = ({ visible, onHide, onCompleted, locationId, editRampId }) => {
  const { tr } = useTranslationLgs();
  const { createRampForLocation, getRampDetailForLocation, updateRampForLocation } = useContext(ApiContext);

  const names = proxiedPropertiesOf<FormData>();

  useEffect(() => {
    if (visible) formik.clear();
    if (!isNullOrUndefined(editRampId)) {
      getRampDetailForLocation(locationId, editRampId, d => {
        formik.setFieldValue(names.rampName, d.name);
        formik.setFieldValue(names.isPrivate, d.isPrivate);
        formik.setFieldValue(names.isShowOnRampDashboard, d.isShowOnRampDashboard);
        formik.setFieldValue(names.openingHourDefinitions, d.openingHourDefinitions);
        formik.setFieldValue(names.maxReservationCount, d.maxReservationCount);
        formik.setFieldValue(names.maxSkuCount, d.maxSkuAmount);
        formik.setFieldValue(names.isTemporaryClosed, d.isTemporaryClosed);
      });
    }
  }, [visible]);

  const validationSchema = yup.object().shape({
    rampName: yup.string().required(tr('validation.required', 'Value is required')),
    openingHourDefinitions: openingHoursValidationSchema,
  });

  const formik = useForm<FormData>(validationSchema, initialData, values => save(values), false, false);

  const save = (values: FormData) => {
    if (isNullOrUndefined(editRampId)) {
      createRampForLocation(
        locationId,
        {
          name: values.rampName,
          timeZone: 'Europe/Prague',
          isPrivate: values.isPrivate,
          isShowOnRampDashboard: values.isShowOnRampDashboard,
          openingHourDefinitions: values.openingHourDefinitions,
          maxSkuAmount: values.maxSkuCount,
          maxReservationCount: values.maxReservationCount,
          isTemporaryClosed: values.isTemporaryClosed,
        },
        () => {
          onCompleted();
        },
      );
    } else {
      updateRampForLocation(
        locationId,
        editRampId,
        {
          name: values.rampName,
          openingHourDefinitions: values.openingHourDefinitions,
          timeZone: 'Europe/Prague',
          isPrivate: values.isPrivate,
          isShowOnRampDashboard: values.isShowOnRampDashboard,
          maxSkuAmount: values.maxSkuCount,
          maxReservationCount: values.maxReservationCount,
          isTemporaryClosed: values.isTemporaryClosed,
        },
        () => {
          onCompleted();
        },
      );
    }
  };

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Sidebar visible={visible} position="right" baseZIndex={1000000} onHide={onHide} blockScroll={true} dismissable={false} style={{ minWidth: '40rem' }}>
        <ColumnRow>
          <InnerRow>
            <SidebarHeading>{tr(`AddEditRamp.addRamp`, `Add ramp`)}</SidebarHeading>
          </InnerRow>
          <Row>
            <HorizontalFieldValue
              label={tr(`AddEditRamp.rampName`, `Ramp name`)}
              validationError={findValidationMessage(formik.validationErrors, names.rampName, tr)}
              value={
                <>
                  <InputText style={{ width: '100%' }} value={formik.values.rampName} onChange={e => formik.setFieldValue(names.rampName, e.target.value)} />
                </>
              }
            />
          </Row>
          <OpeningHourDefinitionForm
            openingHours={formik.values.openingHourDefinitions as OpeningHourDefinitionsDTO}
            onChange={v => formik.setFieldValue(names.openingHourDefinitions, v)}
            validationErrors={formik.validationErrors}
            validationPrefix={'openingHourDefinitions.'}
          />

          <Row>
            <HorizontalFieldValue
              label={tr(`AddEditRamp.privateRamp`, `Private ramp`)}
              value={<InputYesNoPlain value={formik.values.isPrivate} onChange={v => formik.setFieldValue(names.isPrivate, v)} />}
            />
          </Row>
          <Row>
            <HorizontalFieldValue
              label={tr(`AddEditRamp.showOnPanel`, `Show on panel`)}
              value={<InputYesNoPlain value={formik.values.isShowOnRampDashboard} onChange={v => formik.setFieldValue(names.isShowOnRampDashboard, v)} />}
            />
          </Row>
          <Row>
            <HorizontalFieldValue
              label={tr(`AddEditRamp.isTemporaryClosed`, `Dočasně uzavřeno`)}
              value={<InputYesNoPlain value={formik.values.isTemporaryClosed} onChange={v => formik.setFieldValue(names.isTemporaryClosed, v)} />}
            />
          </Row>
          <Row>
            <HorizontalFieldValue
              label={tr(`AddEditRamp.maxSkuCount`, `Max SKU count`)}
              value={
                <>
                  <InputText
                    style={{ width: '100%' }}
                    name={'maxSkuCount'}
                    value={formik.values.maxSkuCount}
                    onChange={e => formik.setFieldValue(names.maxSkuCount, e.target.value)}
                  />
                  <Error>{formik.errors.maxSkuCount}</Error>
                </>
              }
            />
            <HorizontalFieldValue
              label={tr(`AddEditRamp.maxReservationCount`, `Max Reservation count`)}
              value={
                <>
                  <InputText
                    style={{ width: '100%' }}
                    name={'maxReservationCount'}
                    value={formik.values.maxReservationCount}
                    onChange={e => formik.setFieldValue(names.maxReservationCount, e.target.value)}
                  />
                  <Error>{formik.errors.maxReservationCount}</Error>
                </>
              }
            />
          </Row>
          <ButtonRow>
            <Button label={tr(`AddEditLocationSidebar.save`, `Save`)} onClick={() => formik.validateAndSend()}></Button>
            <Button
              label={tr(`AddEditLocationSidebar.back`, `Back`)}
              className={'p-button-text'}
              onClick={e => {
                e.preventDefault();
                onHide();
              }}
            ></Button>
          </ButtonRow>
        </ColumnRow>
      </Sidebar>
    </>
  );
};

const Link = styled.a`
  cursor: pointer;
  color: var(--anchor-color);
`;

const Delete = styled.div`
  display: flex;
`;
const To = styled.div`
  display: flex;
  flex-grow: 1;
`;
const From = styled.div`
  display: flex;
  flex-grow: 1;
`;

const FromToRow = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 0.5rem;
`;
const FromTo = styled.div`
  display: flex;
  flex-grow: 1;
  row-gap: 0.5rem;
  flex-direction: column;
  align-items: start;
  align-content: start;
`;
const Day = styled.div`
  display: flex;
  flex-grow: 0;
  padding: 0 1rem 0 0;
`;

const Label = styled.label`
  font-weight: bold;
`;

const Grid = styled.div`
  margin-top: 1rem;
  gap: 1rem;
  display: grid;
  grid-template-columns: auto auto;
`;
const ButtonRow = styled.div`
  margin-top: 2rem;
  display: flex;
  gap: 2rem;
`;

const SidebarHeading = styled.div`
  display: flex;
  font-size: 1.5rem;
  color: black;
  margin-bottom: 1rem;
`;

const InnerRow = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
`;

const ColumnRow = styled.div`
  display: flex;
  flex-direction: column;
`;

const Error = styled.div`
  display: flex;
  color: red;
`;

export default AddEditRamp;
