import { useCallback, useEffect, useRef, useState } from 'react';

export const useStateCallback2 = <T,>(initialState: T): [T, (stateCb: (prev: T) => T, cb?: (state: T) => void) => void] => {
  const [state, setState] = useState(initialState);
  const cbQueueRef = useRef<Array<(state: T) => void>>([]); // Queue for callbacks

  const setStateCallback = useCallback((stateCb: (prev: T) => T, cb?: (state: T) => void) => {
    if (cb) {
      cbQueueRef.current.push(cb); // Add callback to queue
    }
    setState(stateCb);
  }, []);

  useEffect(() => {
    if (cbQueueRef.current.length > 0) {
      // Execute all callbacks in the queue
      cbQueueRef.current.forEach(callback => callback(state));
      cbQueueRef.current = []; // Clear the queue
    }
  }, [state]);

  return [state, setStateCallback];
};
