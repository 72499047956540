import React, { useContext, useState } from 'react';
import * as yup from 'yup';
import { ApiContext } from '@api/api.tsx';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { formatPrice, isNotNullOrUndefined } from '@utils/utils.tsx';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import SvgMoney from '@components/ringil3/icons/Money.tsx';
import SvgTagEdit from '@components/ringil3/icons/TagEdit.tsx';
import InputPriceWithCurrency from '@components/ringil3/Input/InputPriceWithCurrency/InputPriceWithCurrency.tsx';
import useForm from '@hooks/useForm/useForm.tsx';
import { VerticalFieldAndValue } from '@components/ringil3/Containers/VerticalFieldAndValue.tsx';

interface ComponentProps {
  price?: number;
  currency?: string;
  expeditionId: string;
  onReload: () => void;
  readOnly?: boolean;
}

interface Form {
  price: number;
  currency: string;
}

const ExpectedPriceTag: React.FC<ComponentProps> = ({ price, currency, onReload, expeditionId, readOnly = false }) => {
  const { tr } = useTranslationLgs();
  const { changeExpeditionDistributionPrice } = useContext(ApiContext);
  const [open, setOpen] = useState(false);

  const form = useForm<Form>(
    yup.object().shape({
      price: yup.number().required('asdfasdf'),
      currency: yup.string().required(),
    }),
    { price: price, currency: currency },
    d => save(d),
    false,
    false,
    null,
    'none',
  );

  const pane = (
    <>
      <VerticalFieldAndValue label={tr(`ExpectedPriceTag.price`, `Price`)} required errorMessage={form.errors.price}>
        <InputPriceWithCurrency
          price={form.values.price}
          currency={form.values.currency}
          changePrice={price => {
            form.setFieldValue(form.names.price, price);
          }}
          changeCurrency={currency => {
            form.setFieldValue(form.names.currency, currency);
          }}
        />
      </VerticalFieldAndValue>
    </>
  );

  const save = (data: Form) => {
    changeExpeditionDistributionPrice(
      expeditionId,
      {
        price: data.price,
        currency: data.currency,
      },
      () => {
        setOpen(false);
        onReload();
      },
    );
  };

  return (
    <>
      <ButtonTag
        label={tr(`ExpectedPriceTag.title`, `Expected price`)}
        value={formatPrice(price, currency)}
        icon={<SvgMoney />}
        actionIcon={<SvgTagEdit />}
        readonly={readOnly}
        onSave={() => {
          form.validateAndSend();
        }}
        autoClose
      >
        {pane}
      </ButtonTag>
    </>
  );
};

export default ExpectedPriceTag;
