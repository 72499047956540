import React, { useContext, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import useForm, { proxiedPropertiesOf } from '@hooks/useForm/useForm';
import { isNullOrUndefined } from '@utils/utils';
import { ApiContext } from '@api/api';
import { CarrierExpeditionId, EditDataDTO } from '@api/logsteo-api.v2';
import { RingilSidebar } from '@components/obsolete/RingilSidebar/RingilSidebar.tsx';
import Section from '@components/obsolete/Section/Section.tsx';
import InputPlate from '@components/obsolete/Form/InputPlate/InputPlate.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import { InputDriver } from '@components/obsolete/Form/InputDriver/InputDriver.tsx';
import { InputDispatcher } from '@components/obsolete/Form/InputDispatcher/InputDispatcher.tsx';
import * as yup from 'yup';

interface ComponentProps {
  carrierExpeditionIds: CarrierExpeditionId[];
  expeditionId: string;
}

type FormData = Omit<EditDataDTO, ''>;

export const useCreateMultiEditCarInfoSidebar = (onComplete: () => void) => {
  const [visible, setVisible] = useState(false);
  const { tr } = useTranslationLgs();

  // FIXME: brat prvni expeditici asi neni chytre, navrhnout jinak
  const MultiEditCarInfoSidebar: React.FC<ComponentProps> = ({ carrierExpeditionIds, expeditionId }) => {
    const { caMassEditCarInfo } = useContext(ApiContext);

    const form = useForm<FormData>(
      yup.object().shape({}),
      {
        changeCarPlate: false,
        carPlate: null,
        changeDriverId: false,
        driverId: null,
        changeDispatcherId: false,
        dispatcherId: null,
      },
      v => {
        caMassEditCarInfo(
          {
            expeditionCoordinates: carrierExpeditionIds,
            editData: form.values,
          },
          () => {
            onComplete();
          },
        );
      },
      true,
      true,
    );

    if (isNullOrUndefined(form)) return <></>;

    const names = proxiedPropertiesOf<FormData>();

    return (
      <RingilSidebar
        visible={visible}
        headerText={tr(`MultiEditCarInfoSidebar.massUpdateAction`, `Mass update action`)}
        onHide={() => setVisible(false)}
        onSave={() => form.validateAndSend()}
      >
        <Section
          label={tr(`MultiEditCarInfoSidebar.changeCarPlate`, `Change car plate`)}
          showEnable={true}
          onVisibilityChanged={v => form.setFieldValue(names.changeCarPlate, v)}
        >
          <InputPlate
            label={tr(`MultiEditCarInfoSidebar.carPlate`, `Car plate`)}
            value={form.values.carPlate}
            onChange={v => form.setFieldValue(names.carPlate, v)}
            disabled={false}
          />
        </Section>
        <Section
          label={tr(`MultiEditCarInfoSidebar.changeDriver`, `Change driver`)}
          showEnable={true}
          onVisibilityChanged={v => form.setFieldValue(names.changeDriverId, v)}
        >
          <HorizontalFieldValue
            label={tr(`MultiEditCarInfoSidebar.driver`, `Driver`)}
            value={
              <>
                <InputDriver value={form.values.driverId} onChange={v => form.setFieldValue(names.driverId, v)} />
              </>
            }
          />
        </Section>
        <Section
          label={tr(`MultiEditCarInfoSidebar.changeDispatcher`, `Change dispatcher`)}
          showEnable={true}
          onVisibilityChanged={v => form.setFieldValue(names.changeDispatcherId, v)}
        >
          <HorizontalFieldValue
            label={''}
            value={
              <>
                <InputDispatcher value={form.values.dispatcherId} onChange={v => form.setFieldValue(names.dispatcherId, v)} expeditionId={expeditionId} />
              </>
            }
          />
        </Section>
      </RingilSidebar>
    );
  };

  return { MultiEditCarInfoSidebar, setVisible };
};
