import React, { useContext, useEffect, useState } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ShipmentDataForSNOrderInDTO, ShipmentStateCodeEnum, SupplierNotificationItemInDTO, TransportationTypeEnum, WeightModeEnum } from '@api/logsteo-api.v2';
import { ApiContext } from '@api/api';
import { isNullOrUndefined } from '@utils/utils';
import { mapFromApiToDate } from '@utils/date';
import Layout from '@app/layout/Layout.tsx';
import { useParams, useSearchParams } from 'react-router-dom';
import CreateSupplierNotification, { CreateSupplierNotificationFormData } from '@app/pages/customer/supplier-notification/CreateSupplierNotification.tsx';
import { AttachmentDocument } from '@components/ringil3/Features/Attachments/UploadAttachmentDialog.tsx';
import { Order, OrderItem } from '@components/obsolete/Orders/types.ts';

interface ComponentProps {}

const CreateSupplierNotificationPage: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();
  const [initialData, setInitialData] = useState<CreateSupplierNotificationFormData>();
  const { cuGetSupplierNotificationForDuplication } = useContext(ApiContext);

  const [searchParams] = useSearchParams();

  const fromSupplierNotificationId = searchParams.get('fromSupplierNotificationId');

  useEffect(() => {
    cuGetSupplierNotificationForDuplication(fromSupplierNotificationId, d => {
      setInitialData({
        cargoType: {
          cargoTypeCode: d.cargoType.cargoType.cargoTypeCode,
          adrWeight: d.cargoType.adrWeight,
        },
        companyId: d.customerId,
        supplierId: d.supplierId,
        attachments: d.attachmentsData.map(t => {
          return {
            ...t,
            documentType: {
              documentType: t.documentType,
              name: t.documentTypeName,
            },
          } as AttachmentDocument;
        }),
        transportationType: d.transportationType,
        supplierLocationId: d.supplierLocationId,
        customerLocationId: d.customerLocationId,
        shipmentStateCode: d.shipmentStateCode,
        specialRequirementIds: d.specialRequirementCodes,
        containerInfo: d.containerInfo,
        customsTypeCode: d.customsTypeCode,
        referralCode: d.referralCode,
        internalNote: d.internalNote,
        notifyData: {
          notifyUsersDependsOnRole: d.notifyData.notifyUsersDependsOnRole,
          responsiblePersonId: d.notifyData.responsiblePerson?.id,
          userIdsToNotify: d.notifyData.usersToNotify.map(t => t.id),
        },
        orders: d.orders.map(t => convertToOrder(t)),
        internalInvoiceNumber: d.internalInfoData?.internalInvoiceNumber,
        deliveryConditionsIncotermCode: d.internalInfoData?.deliveryConditionsIncotermCode,
        invoiceDueDate: mapFromApiToDate(d.internalInfoData?.invoiceDueDate),
        shipmentId: d.shipmentId,
        fromShipmentId: null,
        customerLocation: d.customerLocation,
        supplierLocation: d.supplierLocation,
        supplierDeliverySlots: { dayWithInterval: [] },
        customerDeliverySlots: { dayWithInterval: [] },
      } as CreateSupplierNotificationFormData);
    });
  }, []);

  const convertToOrder = (t: ShipmentDataForSNOrderInDTO): Order => {
    return {
      name: t.name,
      cargoAmount: t.cargoAmount,
      weight: t.weight,
      currencyOrderValue: t.currencyOrderValue,
      cargoDescription: t.cargoDescription,
      items: t.items.map(t => convertToItem(t)),
    } as Order;
  };

  const convertToItem = (t: SupplierNotificationItemInDTO): OrderItem => {
    return {
      quantityUnit: t.quantityUnitCode,
      weight: t.weight,
      width: t.width,
      stackable: isNullOrUndefined(t.stackable) ? false : t.stackable,
      quantity: t.quantity,
      height: t.height,
      length: t.length,
      cargoItemType: { code: t.cargoItemTypeCode },
      cargoMessage: '',
      weightMode: WeightModeEnum.TOTAL_WEIGHT,
    } as OrderItem;
  };

  return (
    <>
      <Layout title={tr(`create-shipment.createSupplierNotification`, `Duplicate supplier notification`)}>
        {initialData && <CreateSupplierNotification initialData={initialData} />}
      </Layout>
    </>
  );
};

export default withAuthenticationRequired(CreateSupplierNotificationPage);
