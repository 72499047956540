import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMapPin = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={16} fill="none" {...props}>
    <path
      fill="#B2B2B2"
      fillRule="evenodd"
      d="M0 6v.293c0 1.429.486 2.815 1.379 3.93L6 16l4.621-5.776A6.3 6.3 0 0 0 12 6.293V6A6 6 0 1 0 0 6m6 2a2 2 0 1 0 0-4 2 2 0 0 0 0 4"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMapPin;
