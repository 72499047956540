import React from 'react';

interface ComponentProps {
  value: string;
}

const ReadonlyInput: React.FC<ComponentProps> = ({ value }) => {
  return <div className={'flex px-2.5 grow h-[40px] bg-base-tertiary border border-content-quaternary text-content-secondary items-center'}>{value}</div>;
};

export default ReadonlyInput;
