import { withAuthenticationRequired } from '@auth0/auth0-react';
import RingilButton from '@components/framework/buttons/RingilButton.tsx';
import React, { PropsWithChildren, useState } from 'react';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import { useCreateTable } from '@components/ringil3/RingilDataTable/DataTable.tsx';
import SvgCoButtonDots from '@icons/CoButtonDots.tsx';
import SvgCoButtonEdit from '@icons/CoButtonEdit.tsx';
import SvgCoButtonDelete from '@icons/CoButtonDelete.tsx';
import SvgCoButtonPlus from '@icons/CoButtonPlus.tsx';
import { InputSwitch } from 'primereact/inputswitch';
import { HorizontalLine, Panel } from '@components/ringil3/styles.tsx';
import StateTag from '@components/framework/tags/StateTag.tsx';
import { ExpeditionStatusEnum, ExpeditionTypeEnum, LovDTO, TransportationTypeEnum } from '@api/logsteo-api.v2.tsx';
import CargoTypeTag from '@components/framework/tags/CargoTypeTag.tsx';
import TruckTag from '@components/framework/tags/TruckTag.tsx';
import ManipulationTag from '@components/framework/tags/ManipulationTag.tsx';
import TransportationTypeTag from '@components/framework/tags/TransportationTypeTag.tsx';
import ExpeditionTypeTag from '@components/framework/tags/ExpeditionTypeTag.tsx';
import ResponsiblePersonTag from '@components/framework/tags/ResponsiblePersonTag.tsx';
import InternalInfoNotifyTag from '@components/framework/tags/InternalInfoNotifyTag.tsx';
import InternalInfoNotifyByRoleTag from '@components/framework/tags/InternalInfoNotifyByRoleTag.tsx';
import { RoleEnum } from '@type/enums.ts';
import InternalInfoDisponentTag from '@components/framework/tags/InternalInfoDisponentTag.tsx';
import InternalInfoPairingSymbol from '@components/framework/tags/InternalInfoPairingSymbol.tsx';
import InternalNoteTag from '@components/framework/tags/InternalNoteTag.tsx';
import InternalInfoInvoiceState from '@components/framework/tags/InternalInfoInvoiceStateTag.tsx';
import InternalInfoRebillingTag from '@components/framework/tags/InternalInfoRebillingTag.tsx';
import InputPlus from '@components/framework/input/InputPlus/InputPlus.tsx';
import SvgIconPlus from '@components/framework/orders/IconPlus.tsx';
import LinkWithIcon from '@components/ringil3/Links/LinkWithIcon.tsx';
import InputSelect from '@components/ringil3/Input/InputSelect/InputSelect.tsx';
import DuplicateDatesButtons from '@components/features/DuplicateDatesButtons/DuplicateDatesButtons.tsx';
import { dumpVars } from '@utils/utils.tsx';
import dayjs from 'dayjs';
import SvgCalendar from '@components/framework/icons/Calendar.tsx';
import SvgLkw from '@components/ringil3/icons/heading/Lkw.tsx';

type Person = {
  id: number;
  name: string;
  age: number;
};

const Components2: React.FC = () => {
  const { Table, Column } = useCreateTable<Person>();

  const data: Person[] = [
    { id: 1, name: 'asdf asd fasd fa sdf asdf ', age: 25 },
    { id: 2, name: 'Petr', age: 30 },
    // ... další data
  ];

  const [computedLocalDates, setComputedLocalDates] = useState<string[]>([]);

  return (
    <div className={'flex flex-col gap-3 px-10 bg-content-quaternary'}>
      <Comp
        heading={'Icon Buttons'}
        code={`        
<RingilButton mode={'rect-small'} leftIcon={<SvgCoButtonPlus />} />
<RingilButton mode={'rect-small'} leftIcon={<SvgCoButtonDots />} />
<RingilButton mode={'rect-small'} leftIcon={<SvgCoButtonEdit />} />
<RingilButton mode={'rect-small'} leftIcon={<SvgCoButtonDelete />} />
<RingilButton mode={'rect-small'} leftIcon={<SvgCalendar />} />
        `}
      >
        <div className={'flex flex-row gap-4'}>
          <RingilButton mode={'rect-small'} leftIcon={<SvgCoButtonPlus />} />
          <RingilButton mode={'rect-small'} leftIcon={<SvgCoButtonDots />} />
          <RingilButton mode={'rect-small'} leftIcon={<SvgCoButtonEdit />} />
          <RingilButton mode={'rect-small'} leftIcon={<SvgCoButtonDelete />} />
          <RingilButton mode={'rect-small'} leftIcon={<SvgCalendar />} />
        </div>
      </Comp>

      <COHeadline title={'Input enabler'} variant={'h1'} />
      <Comp
        heading={'Input enabler'}
        code={`        
<InputPlus title={'Title'}>
  <div>Content</div>
</InputPlus>
<LinkWithIcon label={'Text'} icon={<SvgIconPlus />} onClick={() => {}}></LinkWithIcon>
        `}
      >
        <div className={'flex flex-col gap-4'}>
          <InputPlus title={'Title'}>
            <div>Content</div>
          </InputPlus>
          <LinkWithIcon label={'Text'} icon={<SvgIconPlus />} onClick={() => {}}></LinkWithIcon>
        </div>
      </Comp>

      <COHeadline title={'Expedition tags'} variant={'h1'} />
      <Comp
        heading={'Icon Buttons'}
        code={`        
<StateTag expeditionState={ExpeditionStatusEnum.NEW} />
<TransportationTypeTag transportationType={TransportationTypeEnum.TRAIN} onChange={v => {}} />
<ExpeditionTypeTag value={ExpeditionTypeEnum.FULL_TRUCK_LOAD} onChange={v => {}} />
<ManipulationTag loadingTypes={['FROM_SIDE']} />
<CargoTypeTag cargoTypeCode="COMMON" />
<TruckTag truckType={{ code: 'TAULINER', loadingCapacity: 100 }} />
        `}
      >
        <div className={'flex flex-row gap-4'}>
          <StateTag expeditionState={ExpeditionStatusEnum.NEW} />
          <TransportationTypeTag transportationType={TransportationTypeEnum.TRAIN} onChange={v => {}} />
          <ExpeditionTypeTag value={ExpeditionTypeEnum.FULL_TRUCK_LOAD} onChange={v => {}} />
          <ManipulationTag value={['FROM_SIDE']} onChange={v => {}} />
          <CargoTypeTag cargoType={null} onChange={v => {}} />
          <TruckTag truckTypeCode={'TAULINER'} onChange={v => {}} />
        </div>
      </Comp>

      <COHeadline title={'Headings'} variant={'h1'} />
      <Comp
        heading={'Icon Buttons'}
        code={`        
<COHeadline variant={'h2'} title={'H3 headline'} icon={<SvgLkw />} />

        `}
      >
        <div className={'flex flex-row gap-4'}>
          <COHeadline variant={'h2'} title={'H3 headline'} icon={<SvgLkw />} />
        </div>
      </Comp>

      <COHeadline title={'Internal information'} variant={'h1'} />
      <Comp
        heading={'Icon Buttons'}
        code={`        
<div className={'flex flex-col gap-4'}>
  <div className={'flex flex-row gap-4'}>
    <ResponsiblePersonTag person={{ name: 'Miloslav Vlach', active: true } as LovDTO} onChange={v => {}} />
    <InternalInfoNotifyTag persons={[{ name: 'Miloslav Vlach', active: true } as LovDTO]} onChange={v => {}} />
    <InternalInfoNotifyByRoleTag notifyRoleName={RoleEnum.ROLE_SN_NOTIFY} isActive={true} onChange={v => {}} />
  </div>
  <div className={'flex flex-row gap-4'}>
    <InternalInfoInvoiceState value={null} onChange={v => {}} />
    <InternalInfoDisponentTag disponentPerson={{ name: 'Miloslav Vlach', active: true } as LovDTO} onChange={v => {}} />
    <InternalInfoPairingSymbol value={'asdf'} onChange={v => {}} />
    <InternalNoteTag value={null} onChange={v => {}} />
    <InternalInfoRebilingTag value={null} onChange={v => {}} />
  </div>
</div>
        `}
      >
        <div className={'flex flex-col gap-4'}>
          <div className={'flex flex-row gap-4'}>
            <ResponsiblePersonTag person={{ name: 'Miloslav Vlach', active: true } as LovDTO} onChange={v => {}} />
            <InternalInfoNotifyTag persons={[{ name: 'Miloslav Vlach', active: true } as LovDTO]} onChange={v => {}} />
            <InternalInfoNotifyByRoleTag notifyRoleName={RoleEnum.ROLE_SN_NOTIFY} isActive={true} onChange={v => {}} />
          </div>
          <div className={'flex flex-row gap-4'}>
            <InternalInfoInvoiceState value={null} onChange={v => {}} />
            <InternalInfoDisponentTag disponentPerson={{ name: 'Miloslav Vlach', active: true } as LovDTO} onChange={v => {}} />
            <InternalInfoPairingSymbol value={'asdf'} onChange={v => {}} />
            <InternalNoteTag value={null} onChange={v => {}} />
            <InternalInfoRebillingTag value={null} onChange={v => {}} />
          </div>
        </div>
      </Comp>

      <COHeadline title={'InputSelect'} variant={'h1'} />
      <Comp
        heading={'Icon Buttons'}
        code={`  
<div className={'flex flex-col gap-4'}>
          <InputSelect
            model={[
              { label: 'Nepřiřazovat', code: 'A' },
              { label: 'Přiřadit vybraného', code: 'B' },
              { label: 'Poptat', code: 'C' },
            ]}
            onSelect={v => {}}
            selectedItemCode={'A'}
          />
</div>              
        `}
      >
        <div className={'flex flex-col gap-4'}>
          <InputSelect
            model={[
              { label: 'Nepřiřazovat', code: 'A' },
              { label: 'Přiřadit vybraného', code: 'B' },
              { label: 'Poptat', code: 'C' },
            ]}
            onSelect={v => {}}
            selectedItemCode={'A'}
          />
        </div>
      </Comp>

      <COHeadline title={'DuplicateDatesButtons'} variant={'h1'} />
      <Comp
        heading={'DuplicateDatesButtons'}
        code={`  
<div className={'flex flex-col gap-4 w-[350px]'}>
  {computedLocalDates.map(t => dayjs(t).format('YYYY-MM-DD')).join(', ')}
  <DuplicateDatesButtons startLocalDate={'2025-01-06'} onChanged={v => setComputedLocalDates(v)} computedLocalDates={computedLocalDates} />
</div>           
        `}
      >
        <div className={'flex flex-col gap-4 w-[350px]'}>
          {computedLocalDates.map(t => dayjs(t).format('YYYY-MM-DD')).join(', ')}
          <DuplicateDatesButtons startLocalDate={'2025-01-06'} onChanged={v => setComputedLocalDates(v)} computedLocalDates={computedLocalDates} />
        </div>
      </Comp>
    </div>
  );
};

interface Props {
  code: string;
  heading: string;
}

const Comp: React.FC<PropsWithChildren<Props>> = ({ code, children, heading }) => {
  const [showCode, setShowCode] = useState(false);
  return (
    <>
      <div className={'flex flex-row gap-4 py-2'}>
        <COHeadline title={heading} variant={'h3'} />
        <div className={'flex flex-row gap-2 items-center'}>
          <InputSwitch checked={showCode} onChange={e => setShowCode(e.value)} />
          <div>Show code</div>
        </div>
      </div>
      <div>{children}</div>
      {showCode && <pre>{code}</pre>}
      <div className={'m-1'}></div>
      <HorizontalLine variant={'tertiary'} />
    </>
  );
};

export default withAuthenticationRequired(Components2);
