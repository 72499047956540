import { NewExpeditionForm } from '@app/pages/expedition/create/types.ts';
import { generateUUID } from '@utils/utils.tsx';
import { CargoTypeCodeEnum } from '@utils/constants.ts';

export const newExpeditionInitialData = (): NewExpeditionForm => {
  const ret = {
    locations: [
      {
        location: null,
        loadingReference: null,
        localityMode: 'FROM_LOCATIONS',
        id: generateUUID(),
        orders: [],
        unloadingOrderIds: null,
        timeslots: { dayWithInterval: [] },
      },
      {
        location: null,
        loadingReference: null,
        localityMode: 'FROM_LOCATIONS',
        id: generateUUID(),
        orders: null,
        unloadingOrderIds: [],
        timeslots: { dayWithInterval: [] },
      },
    ],
    distribution: null,
    cargoType: { cargoTypeCode: CargoTypeCodeEnum.COMMON_GOODS, adrWeight: null },
  } as NewExpeditionForm;

  return ret;
};
