import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import Badge from '@components/ringil3/Badges/Badge';
import { ExpeditionCoordinate } from '@api/logsteo-api.v2';
import { isNotNullOrUndefined } from '@utils/utils';
import { ClickableLink } from '@components/ringil3/styles';
import useNavigation from '@hooks/useNavigation.tsx';

interface ComponentProps {
  expeditionCoords?: ExpeditionCoordinate;
}

const LinkedExpBadge: React.FC<ComponentProps> = ({ expeditionCoords }) => {
  const { tr } = useTranslationLgs();
  const navigation = useNavigation();

  return (
    <>
      {isNotNullOrUndefined(expeditionCoords) && (
        <>
          {expeditionCoords.isCarrier === false && (
            <ClickableLink href={navigation.createNavigationLink(navigation.urlFunctions.createExpeditionDetailV2(expeditionCoords.expeditionId))}>
              <Badge filled={true} value={expeditionCoords.applicationId} label={tr(`LinkedExpBadge.title`, `Expedition`)} highlightedValue={true}></Badge>
            </ClickableLink>
          )}
          {expeditionCoords.isCarrier === true && (
            <ClickableLink
              href={navigation.createNavigationLink(navigation.urlFunctions.createCarrierShipmentDetail(expeditionCoords.customerId, expeditionCoords.applicationId))}
            >
              <Badge filled={true} value={expeditionCoords.applicationId} label={tr(`LinkedExpBadge.title`, `Expedition`)} highlightedValue={true}></Badge>
            </ClickableLink>
          )}
        </>
      )}
    </>
  );
};

export default LinkedExpBadge;
