import { withAuthenticationRequired } from '@auth0/auth0-react';
import RingilButton from '@components/framework/buttons/RingilButton.tsx';
import SvgPen from '@components/icons/Pen.tsx';
import React from 'react';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import COSelect from '@components/framework/selects/COSelect.tsx';
import COInlineMessage from '@components/ringil3/COInlineMessage/COInlineMessage.tsx';
import SvgCoInlineMessageInfo from '@components/ringil3/COInlineMessage/icons/CoInlineMessageInfo.tsx';
import SvgCoInlineMessageWarning from '@components/ringil3/COInlineMessage/icons/CoInlineMessageWarning.tsx';
import SvgCoInlineMessageError from '@components/ringil3/COInlineMessage/icons/CoInlineMessageError.tsx';
import SvgCoInlineMessageSuccess from '@components/ringil3/COInlineMessage/icons/CoInlineMessageSuccess.tsx';
import SvgLkw from '@components/ringil3/icons/heading/Lkw.tsx';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import { InputText } from 'primereact/inputtext';
import SvgETraReference from '@components/features/eTransport/tags/ETraReference.tsx';
import SvgETransportSend from '@components/features/eTransport/icons/ETransportSend.tsx';
import SectionPanel from '@components/framework/panels/SectionPanel.tsx';
import Card from '@components/framework/card/Card.tsx';
import SvgMapPin from '@components/framework/icons/MapPin.tsx';
import { Field } from '@components/framework/formfield/Field.tsx';
import AddButton from '@components/framework/buttons/AddButton.tsx';
import { HorizontalLine } from '@components/styles.tsx';
import InputTextWithSuffix from '@components/ringil3/Input/InputTextWithSuffix/InputTextWithSuffix.tsx';
import InputPriceWithCurrency from '@components/ringil3/Input/InputPriceWithCurrency/InputPriceWithCurrency.tsx';
import SvgDelete from '@components/ringil3/icons/buttons/Delete.tsx';
import InputActionButton from '@components/framework/buttons/InputActionButton.tsx';
import CardSection from '@components/framework/card/CardSection.tsx';
import MoreLink from '@components/framework/buttons/MoreLink.tsx';
import BreadCrumbComponent from '@components/obsolete/BreadCrumbComponent/BreadCrumbComponent.tsx';
import { ReservationActionTypesEnum } from '@api/logsteo-api.v2.tsx';
import { formatDateInterval } from '@utils/utils.tsx';
import RingilDataTable from '@components/ringil3/RingilDataTable/RingilDataTable.tsx';
import { useCreateTable } from '@components/ringil3/RingilDataTable/DataTable.tsx';
import InputSelectMultiple from '@components/ringil3/Input/InputSelect/InputSelectMultiple.tsx';
import InputSelect from '@components/ringil3/Input/InputSelect/InputSelect.tsx';
import LocationSelect from '@components/framework/locationselect/LocationSelect.tsx';
import COBadge from '@components/ringil3/COBadge/COBadge.tsx';
import SvgCoButtonPlus from '@icons/CoButtonPlus.tsx';
import SvgCoButtonDots from '@icons/CoButtonDots.tsx';
import SvgCoButtonEdit from '@icons/CoButtonEdit.tsx';
import SvgCoButtonDelete from '@icons/CoButtonDelete.tsx';

type Person = {
  id: number;
  name: string;
  age: number;
};

const AdminAllCompanies: React.FC = () => {
  const { Table, Column } = useCreateTable<Person>();

  const data: Person[] = [
    { id: 1, name: 'asdf asd fasd fa sdf asdf ', age: 25 },
    { id: 2, name: 'Petr', age: 30 },
    // ... další data
  ];

  return (
    <div className={'flex flex-col gap-3 px-10'}>
      <COHeadline title={'Buttons'} variant={'h3'} />
      <div className={'flex flex-row gap-4'}>
        <RingilButton mode={'rect-small'} leftIcon={<SvgCoButtonPlus />} />
        <RingilButton mode={'rect-small'} leftIcon={<SvgCoButtonDots />} />
        <RingilButton mode={'rect-small'} leftIcon={<SvgCoButtonEdit />} />
        <RingilButton mode={'rect-small'} leftIcon={<SvgCoButtonDelete />} />
      </div>
      <COHeadline title={'Add button'} variant={'h3'} />
      <AddButton title={'Add'} onClick={() => {}}></AddButton>
      <AddButton title={'Add'} onClick={() => {}} fullWidth></AddButton>

      <COHeadline title={'InputSelect'} variant={'h3'} />
      <InputSelect
        model={[
          {
            label: 'Loading',
            code: ReservationActionTypesEnum.LOADING,
          },
          {
            label: 'Unloading',
            code: ReservationActionTypesEnum.UNLOADING,
          },
        ]}
        selectedItemCode={'LOADING'}
        onSelect={v => {}}
      />

      <COHeadline title={'InputSelectMultiple'} variant={'h3'} />
      <InputSelectMultiple
        model={[
          {
            label: 'Loading',
            code: ReservationActionTypesEnum.LOADING,
          },
          {
            label: 'Unloading',
            code: ReservationActionTypesEnum.UNLOADING,
          },
        ]}
        selectedItemCodes={['LOADING']}
        onSelect={v => {}}
      />

      <COHeadline title={'Table'} variant={'h3'} />
      <Table value={data} showMassActions={true}>
        <Column header="ID" fixed width={50} body={row => <span>{row.id}</span>} />
        <Column header="Jméno" fixed width={220} body={row => <span>{row.name}</span>} />
        <Column header="Věk" fixed width={50} body={row => <span>{row.age}</span>} />
        <Column header="Email" width={200} body={row => <span>{row.name}</span>} />
        <Column header="Email" width={200} body={row => <span>{row.name}</span>} />
        <Column header="Email" width={200} body={row => <span>{row.name}</span>} />
        <Column header="Email" width={200} body={row => <span>{row.name}</span>} />
        <Column header="Email" width={200} body={row => <span>{row.name}</span>} />
        <Column header="Email" width={200} body={row => <span>{row.name}</span>} />
        <Column header="Email" width={200} body={row => <span>{row.name}</span>} />
        <Column header="Email" width={200} body={row => <span>{row.name}</span>} />
        <Column header="Email" width={200} body={row => <span>{row.name}</span>} />
      </Table>

      <COHeadline title={'RingilDataTable'} variant={'h3'} />
      <RingilDataTable
        data={[
          { name: 'Miloslav', subject: 'Test', companyModule: 'EXPEDITION', since: '2022-01-01', till: '2022-01-01' },
          { name: 'Miloslav 2', subject: 'Test', companyModule: 'EXPEDITION', since: '2022-01-01', till: '2022-01-01' },
          { name: 'Miloslav', subject: 'Test', companyModule: 'TIMESLOT', since: '2022-01-01', till: '2022-01-01' },
          { name: 'Miloslav', subject: 'Test', companyModule: 'EXPEDITION', since: '2022-01-01', till: '2022-01-01' },
          { name: 'Miloslav', subject: 'Test', companyModule: 'EXPEDITION', since: '2022-01-01', till: '2022-01-01' },
          { name: 'Miloslav', subject: 'Test', companyModule: 'EXPEDITION', since: '2022-01-01', till: '2022-01-01' },
        ]}
        columns={[{ name: 'Name' }, { name: 'Action' }, { name: 'Entity' }, { name: 'Since & till' }]}
        renderers={[row => <>{row.name}</>, row => <>{row.subject}</>, row => <>{row.companyModule}</>, row => <>{formatDateInterval(row.since, row.till)}</>]}
      ></RingilDataTable>

      <COHeadline title={'Location select'} variant={'h3'} />
      <LocationSelect value={null} onChange={v => {}} />

      <COHeadline title={'BreadCrumb'} variant={'h3'} />
      <BreadCrumbComponent
        items={[
          {
            label: 'Label',
            url: '/',
          },
          {
            label: 'Label',
            url: '/',
          },
          {
            label: 'Label',
            url: '/',
          },
          {
            label: 'Label N',
            disabled: false,
          },
        ]}
      />

      <COHeadline title={'Badges'} variant={'h3'} />
      <div className={'flex flex-row gap-4'}>
        <COBadge variant={'filled'} type={'neutral'}>
          neutral
        </COBadge>
        <COBadge variant={'filled'} type={'draft'}>
          draft
        </COBadge>
        <COBadge variant={'filled'} type={'processing'}>
          processing
        </COBadge>
        <COBadge variant={'filled'} type={'positive'}>
          positive
        </COBadge>
        <COBadge variant={'filled'} type={'warning'}>
          warning
        </COBadge>
        <COBadge variant={'filled'} type={'loading'}>
          loading
        </COBadge>
        <COBadge variant={'filled'} type={'unloading'}>
          unloading
        </COBadge>
      </div>
      <div className={'flex flex-row gap-4'}>
        <COBadge variant={'outlined'} type={'neutral'}>
          neutral
        </COBadge>
        <COBadge variant={'outlined'} type={'draft'}>
          draft
        </COBadge>
        <COBadge variant={'outlined'} type={'processing'}>
          processing
        </COBadge>
        <COBadge variant={'outlined'} type={'positive'}>
          positive
        </COBadge>
        <COBadge variant={'outlined'} type={'warning'}>
          warning
        </COBadge>
        <COBadge variant={'outlined'} type={'loading'}>
          loading
        </COBadge>
        <COBadge variant={'outlined'} type={'unloading'}>
          unloading
        </COBadge>
      </div>

      <COHeadline title={'MoreLink'} variant={'h3'} />
      <MoreLink title={'More (12)'} />
      <COHeadline title={'CardSection'} variant={'h3'} />
      <SectionPanel icon={<SvgETransportSend />} heading={'O transportu'}>
        <Card>
          <CardSection icon={<SvgMapPin />} title={'Adress destination'}>
            <div>Hraniční přechod: BORS 2 - A3</div>
            <div>Bucuresti</div>
            <div>Strada Virginia</div>
            <div>Bucuresti</div>
          </CardSection>
        </Card>
      </SectionPanel>
      <COHeadline title={'Inputs'} variant={'h3'} />
      <Field label={'Phone weight'} required={true}>
        <InputTextWithSuffix suffix={'kg'} value={'3 3453'} onChange={v => {}} />
      </Field>
      <Field label={'Quantity'} required={true}>
        <InputTextWithSuffix suffix={'psc'} value={'3 3453'} onChange={v => {}} />
      </Field>

      <Field label={'Price'} required={true}>
        <InputPriceWithCurrency price={23234} currency={'CZK'} changeCurrency={v => {}} changePrice={v => {}} />
      </Field>

      <Field label={'Price'} required={false} readOnly={true} buttonsMode={'iconButton'} actionButtons={<RingilButton leftIcon={<SvgDelete />} mode={'rect-small'} />}>
        <InputPriceWithCurrency price={23234} currency={'CZK'} changeCurrency={v => {}} changePrice={v => {}} />
      </Field>

      <Field label={'Price'} readOnly={true} actionButtons={<InputActionButton title={'Akce'} onClick={() => {}} />} buttonsMode={'actionButton'}>
        <InputPriceWithCurrency price={23234} currency={'CZK'} changeCurrency={v => {}} changePrice={v => {}} />
      </Field>

      <COHeadline title={'HorizontalLine'} variant={'h3'} />
      <HorizontalLine variant={'brand-light'} />
      <HorizontalLine variant={'normal'} />
      <HorizontalLine variant={'tertiary'} />
      <HorizontalLine variant={'quaternary'} />
      <hr className={'bg-content-tertiary h-[1px] border-0'} />

      <COHeadline title={'AddButton'} variant={'h3'} />
      <AddButton title={'Product'} onClick={() => {}}></AddButton>

      <COHeadline title={'InputText'} variant={'h3'} />

      <div className={'flex flex-row gap-4'}>
        <Field label={'Phone'}>
          <InputText value={'asdfasdfasd'} onChange={e => {}} />
        </Field>
        <Field label={'Phone required'} required={true}>
          <InputText value={'asdfasdfasd'} onChange={e => {}} />
        </Field>
        <Field
          label={'Phone readonly'}
          readOnly={true}
          actionButtons={
            <div className={'flex flex-row gap-2 h-[36px] items-center py-[8px] px-[24px] text-normal leading-4 text-content-brand border-2 border-solid'}>Action</div>
          }
        >
          <InputText value={'asdfasdfasd'} onChange={e => {}} />
        </Field>
        <Field label={'Phone readonly'} errorMessage={'Povinna hodnota'}>
          <InputText value={'asdfasdfasd'} onChange={e => {}} />
        </Field>
      </div>

      <h2>Headlines</h2>
      <COHeadline title={'H1 headline'} variant={'h1'} />
      <COHeadline title={'H2 headline'} variant={'h2'} />
      <COHeadline title={'H3 headline'} variant={'h3'} />
      <COHeadline title={'H4 headline'} variant={'h4'} />
      <COHeadline title={'H5 headline'} variant={'h5'} />
      <COHeadline title={'H3 headline'} variant={'h3'} icon={<SvgPen />} />
      <COHeadline title={'H4 headline'} variant={'h4'} icon={<SvgPen />} />
      <h2>COSelect</h2>
      <div className={'flex flex-col gap-4'}>
        <COSelect label="Name" selected={true} />
        <COSelect label="Name" selected={true} badgeTexts={['Value']} />
        <COSelect label="Name" selected={true} badgeTexts={['Value', 'Value']} />
        <COSelect label="Name" selected={false} />
        <COSelect label="Name" selected={false} badgeTexts={['Value']} />
        <COSelect label="Name" selected={false} badgeTexts={['Value', 'Value']} />
      </div>
      <h2>Buttons</h2>
      <COHeadline title={'Filled small'} variant={'h3'} />
      <div className={'flex flex-row gap-2'}>
        <RingilButton label={'Pokračovat'} mode={'filled-small'} />
        <RingilButton label={'Pokračovat'} leftIcon={<SvgCoButtonEdit />} mode={'filled-small'} />
        <RingilButton leftIcon={<SvgCoButtonDots />} mode={'filled-small'} />
      </div>
      <div className={'flex flex-row gap-2'}>
        <RingilButton label={'Pokračovat'} mode={'filled-small'} disabled={true} />
        <RingilButton label={'Pokračovat'} leftIcon={<SvgCoButtonEdit />} mode={'filled-small'} disabled={true} />
        <RingilButton leftIcon={<SvgCoButtonDots />} mode={'filled-small'} disabled={true} />
      </div>
      <COHeadline title={'Outlined small'} variant={'h3'} />
      <div className={'flex flex-row gap-2'}>
        <RingilButton label={'Pokračovat'} mode={'outlined-small'} />
        <RingilButton label={'Pokračovat'} leftIcon={<SvgCoButtonEdit />} mode={'outlined-small'} />
        <RingilButton leftIcon={<SvgCoButtonDots />} mode={'outlined-small'} />
      </div>
      <div className={'flex flex-row gap-2'}>
        <RingilButton label={'Pokračovat'} mode={'outlined-small'} disabled={true} />
        <RingilButton label={'Pokračovat'} leftIcon={<SvgCoButtonEdit />} mode={'outlined-small'} disabled={true} />
        <RingilButton leftIcon={<SvgCoButtonDots />} mode={'outlined-small'} disabled={true} />
      </div>
      <COHeadline title={'Filled big'} variant={'h3'} />
      <div className={'flex flex-row gap-2'}>
        <RingilButton label={'Pokračovat'} mode={'filled-big'} />
        <RingilButton label={'Pokračovat'} leftIcon={<SvgCoButtonEdit />} mode={'filled-big'} />
        <RingilButton leftIcon={<SvgCoButtonDots />} mode={'filled-big'} />
      </div>
      <div className={'flex flex-row gap-2'}>
        <RingilButton label={'Pokračovat'} mode={'filled-big'} disabled={true} />
        <RingilButton label={'Pokračovat'} leftIcon={<SvgCoButtonEdit />} mode={'filled-big'} disabled={true} />
        <RingilButton leftIcon={<SvgCoButtonDots />} mode={'filled-big'} disabled={true} />
      </div>
      <COHeadline title={'Outlined big'} variant={'h3'} />
      <div className={'flex flex-row gap-2'}>
        <RingilButton label={'Pokračovat'} mode={'outlined-big'} />
        <RingilButton label={'Pokračovat'} leftIcon={<SvgCoButtonEdit />} mode={'outlined-big'} />
        <RingilButton leftIcon={<SvgCoButtonDots />} mode={'outlined-big'} />
      </div>
      <div className={'flex flex-row gap-2'}>
        <RingilButton label={'Pokračovat'} mode={'outlined-big'} disabled={true} />
        <RingilButton label={'Pokračovat'} leftIcon={<SvgCoButtonEdit />} mode={'outlined-big'} disabled={true} />
        <RingilButton leftIcon={<SvgCoButtonDots />} mode={'outlined-big'} disabled={true} />
      </div>
      <COHeadline title={'Table'} variant={'h3'} />
      <div className={'flex flex-row gap-2'}>
        <RingilButton label={'Pokračovat'} mode={'rect-small'} />
        <RingilButton label={'Pokračovat'} leftIcon={<SvgCoButtonEdit />} mode={'rect-small'} />
        <RingilButton leftIcon={<SvgCoButtonDots />} mode={'rect-small'} />
      </div>
      <div className={'flex flex-row gap-2'}>
        <RingilButton label={'Pokračovat'} mode={'rect-small'} disabled={true} />
        <RingilButton label={'Pokračovat'} leftIcon={<SvgCoButtonEdit />} mode={'rect-small'} disabled={true} />
        <RingilButton leftIcon={<SvgCoButtonDots />} mode={'rect-small'} disabled={true} />
      </div>
      <h2>COInlineMessage</h2>
      <div className={'flex flex-col gap-4'}>
        <COInlineMessage variant={'info'} icon={<SvgCoInlineMessageInfo />}>
          U této šablony obvykle poptáváte <b>SpeedTrans s.r.o.</b>, výběrová cena 12.000 Kč
        </COInlineMessage>
        <COInlineMessage variant={'info'}>
          U této šablony obvykle poptáváte <b>SpeedTrans s.r.o.</b>, výběrová cena 12.000 Kč
        </COInlineMessage>
        <COInlineMessage variant={'info'} buttons={<RingilButton label={'asdas'} mode={'rect-small'} />}>
          U této šablony obvykle poptáváte <b>SpeedTrans s.r.o.</b>, výběrová cena 12.000 Kč
        </COInlineMessage>
        <COInlineMessage variant={'warning'} icon={<SvgCoInlineMessageWarning />}>
          U této šablony obvykle poptáváte <b>SpeedTrans s.r.o.</b>, výběrová cena 12.000 Kč
        </COInlineMessage>
        <COInlineMessage variant={'warning'}>
          U této šablony obvykle poptáváte <b>SpeedTrans s.r.o.</b>, výběrová cena 12.000 Kč
        </COInlineMessage>
        <COInlineMessage variant={'success'} icon={<SvgCoInlineMessageSuccess />}>
          U této šablony obvykle poptáváte <b>SpeedTrans s.r.o.</b>, výběrová cena 12.000 Kč
        </COInlineMessage>
        <COInlineMessage variant={'success'}>
          U této šablony obvykle poptáváte <b>SpeedTrans s.r.o.</b>, výběrová cena 12.000 Kč
        </COInlineMessage>
        <COInlineMessage variant={'error'} icon={<SvgCoInlineMessageError />}>
          U této šablony obvykle poptáváte <b>SpeedTrans s.r.o.</b>, výběrová cena 12.000 Kč
        </COInlineMessage>
        <COInlineMessage variant={'error'}>
          U této šablony obvykle poptáváte <b>SpeedTrans s.r.o.</b>, výběrová cena 12.000 Kč
        </COInlineMessage>
      </div>
      <COHeadline variant={'h2'} title={'H3 headline'} icon={<SvgLkw />} />

      <div className={'flex flex-row gap-4'}>
        <ButtonTag icon={<SvgETraReference />} label={'Transport reference no.'} readonly={false}></ButtonTag>
        <ButtonTag icon={<SvgETraReference />} label={'Transport reference no.'} required={true} readonly={false}></ButtonTag>
        <ButtonTag icon={<SvgETraReference />} label={'Transport reference no.'} required={true} readonly={false} errorMessage={'Povinne'}></ButtonTag>
        <ButtonTag value={'AKLUOHL948654'} icon={<SvgETraReference />} label={'Transport reference no.'} readonly={false}></ButtonTag>
      </div>
      <div className={'flex flex-row gap-4'}>
        <ButtonTag icon={<SvgETraReference />} label={'Transport reference no.'} readonly={true}></ButtonTag>
        <ButtonTag icon={<SvgETraReference />} label={'Transport reference no.'} required={true} readonly={true}></ButtonTag>
        <ButtonTag icon={<SvgETraReference />} label={'Transport reference no.'} required={true} readonly={true} errorMessage={'Povinne'}></ButtonTag>
        <ButtonTag value={'AKLUOHL948654'} icon={<SvgETraReference />} label={'Transport reference no.'} readonly={true}></ButtonTag>
      </div>

      <COHeadline title={'SectionPanel'} variant={'h3'} />
      <SectionPanel icon={<SvgETransportSend />} heading={'O transportu'}></SectionPanel>

      <COHeadline title={'Card in SectionPanel'} variant={'h3'} />
      <SectionPanel icon={<SvgETransportSend />} heading={'O transportu'}>
        <Card>
          <div className={'flex flex-col gap-4'}>
            <COHeadline title={'About'} variant={'h4'} icon={<SvgMapPin />} />
          </div>
        </Card>
      </SectionPanel>
    </div>
  );
};

export default withAuthenticationRequired(AdminAllCompanies);
