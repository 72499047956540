import { useContext, useEffect, useState } from 'react';
import { LovDTO } from '@api/logsteo-api.v2';
import { ApiContext } from '@api/api';

const useFolders = (): LovDTO[] => {
  const [folders, setFolders] = useState<LovDTO[]>([]);
  const { lovCompanyFolders } = useContext(ApiContext); // LovDTO


  useEffect(() => {
    const ac = new AbortController();
    lovCompanyFolders(null, d => {
      if (ac.signal.aborted) return;
      setFolders(d);
    });
    return () => {
      ac.abort();
    };
  }, []);

  return folders;
};

export default useFolders;
