import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ApiContext } from '@api/api';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { InputText } from 'primereact/inputtext';
import { Chip } from 'primereact/chip';
import { EntityTypeEnum, LabelPreviewWithoutTypeOutDTO } from '@api/logsteo-api.v2.tsx';
import useTranslationLgs from '../../../hooks/i18n/useTranslation';
import { Checkbox } from 'primereact/checkbox';
import { RowWithSpaceBetween } from '@components/styles';
import useBEValidation from '@hooks/useBEValidation/useBEValidation';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import EditButton from '@components/obsolete/Buttons/EditButton/EditButton.tsx';

interface ComponentProps {
  objectType: EntityTypeEnum;
  objectId: string;
  resolveLabelsForType?: EntityTypeEnum;
  disabled?: boolean;
}

interface NewLabel {
  name: string;
  color: string;
}

const colors = [
  '#60BD4E',
  '#F1D600',
  '#FF9E19',
  '#EB5A46',
  '#C277E0',
  '#0179BF',
  '#00C2DF',
  '#51E898',
  '#334563',
  '#F62DAE',
  '#000000',
  '#A63A50',
  '#470063',
  '#91C499',
  '#AFBFC0',
  '#CFD11A',
  '#E0B773',
];
export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.1rem;
`;

export const LabelDiv = styled.div<{ bgColor?: string }>`
  display: flex;
  flex-direction: row;
  padding: 0.3rem 1rem;
  color: ${props => props.color || 'white'};
  background-color: ${props => props.bgColor || 'black'};
  cursor: pointer;
  margin-top: 0.5rem;
  flex-wrap: nowrap;
  white-space: nowrap;
`;

const Labels: React.FC<ComponentProps> = ({ objectType, objectId, resolveLabelsForType = objectType, disabled = false }) => {
  const { tr } = useTranslationLgs();

  const { getAllLabelsForCompanyEntityType, createNewLabelForCompany, cuGetLabelsForEntity, cuSetLabelsForEntity, archiveLabel } = useContext(ApiContext);
  const [labels, setLabels] = useState<LabelPreviewWithoutTypeOutDTO[]>([]);
  const overlayRef = useRef(undefined);
  const [showNewLabelUI, setShowNewLabelUI] = useState(false);
  const [selectedColor, setSelectedColor] = useState<string>();
  const [objectLabels, setObjectLabels] = useState<LabelPreviewWithoutTypeOutDTO[]>([]);
  const [editMode, setEditMode] = useState(false);
  const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();

  const [newLabelData, setNewLabelData] = useState<NewLabel>({
    color: '',
    name: '',
  });

  useEffect(() => {
    loadLabelsHandler();
  }, []);

  const loadLabelsHandler = () => {
    getAllLabelsForCompanyEntityType(resolveLabelsForType, '', d => setLabels(d));
    cuGetLabelsForEntity(objectType, objectId, d => setObjectLabels(d));
  };

  const setColor = (color: string) => {
    setSelectedColor(color);
    setNewLabelData({ ...newLabelData, color });
  };

  const invalidForm = (data: NewLabel) => {
    if (data.name?.length == 0) return true;
    if (data.color?.length == 0) return true;
    return false;
  };

  const createLabelHandler = () => {
    createNewLabelForCompany(
      {
        labelValue: newLabelData.name,
        labelColor: newLabelData.color,
        entityType: resolveLabelsForType,
      },
      () => {
        loadLabelsHandler();
        setShowNewLabelUI(false);
      },
    );
  };

  useEffect(() => {
    setNewLabelData({ color: '', name: '' });
    setSelectedColor('');
  }, [showNewLabelUI]);

  const toggleLabel = (label: LabelPreviewWithoutTypeOutDTO) => {
    const existing = objectLabels.find(t => t.labelId === label.labelId);
    if (existing) {
      // action - remove
      const labels = [...objectLabels.filter(t => t.labelId !== label.labelId)].map(t => t.labelId);
      cuSetLabelsForEntity(objectType, objectId, { labelIds: labels }, () => {
        loadLabelsHandler();
      });
    } else {
      // action -add
      const labels = [...objectLabels, label].map(t => t.labelId);
      cuSetLabelsForEntity(objectType, objectId, { labelIds: labels }, () => {
        loadLabelsHandler();
      });
    }
  };

  const deleteLabel = (label: LabelPreviewWithoutTypeOutDTO) => {
    setBeValidationErrors([]);
    archiveLabel(
      label.labelId,
      () => {
        loadLabelsHandler();
      },
      null,
      { onValidationFailed: setBeValidationErrors },
    );
  };

  // @ts-ignore
  return (
    <>
      <LabelsInner>
        <LabelsWrapper>
          {objectLabels.map((label, key) => {
            return (
              <Chip
                key={key}
                label={label.labelValue}
                style={{
                  backgroundColor: label.labelColor,
                  color: 'white',
                  borderRadius: '5px',
                }}
              />
            );
          })}
        </LabelsWrapper>
        <Button
          style={{ whiteSpace: 'nowrap', backgroundColor: 'white' }}
          /*label={tr(`Labels.labels`, `Labels`)}*/
          onClick={e => {
            loadLabelsHandler();
            overlayRef.current?.toggle(e);
          }}
          icon={'pi pi-tag'}
          className={'p-button-outlined'}
          disabled={disabled}
        ></Button>
      </LabelsInner>
      {/*
        // @ts-ignore*/}
      <OverlayPanel ref={overlayRef}>
        <BackendValidationComponent />
        {showNewLabelUI ? (
          <>
            <NavigationPanel>
              <a onClick={e => setShowNewLabelUI(false)}>≤</a>
              <b>{tr(`Labels.addNewLabel`, `Add new label`)}</b>
              <a onClick={e => overlayRef.current?.hide()}>x</a>
            </NavigationPanel>
            <hr />

            <Row>
              <HorizontalFieldValue
                label={tr(`Labels.newLabel`, `New label`)}
                value={<InputText style={{ width: '100%' }} value={newLabelData.name} onChange={e => setNewLabelData({ ...newLabelData, name: e.target.value })} />}
              />
            </Row>
            <ColorRow>
              {colors.map((color, index) => {
                return (
                  <React.Fragment key={index}>
                    <ColorBox color={color} onClick={e => setColor(color)}>
                      {color === selectedColor && <>&#x2713;</>}
                    </ColorBox>
                  </React.Fragment>
                );
              })}
            </ColorRow>
            <CenteredButtonRow>
              <Button label={tr(`Labels.create`, `Create`)} disabled={invalidForm(newLabelData)} onClick={e => createLabelHandler()} />
            </CenteredButtonRow>
          </>
        ) : (
          <>
            <RowWithSpaceBetween>
              {tr(`Labels.labels`, `Labels`)}
              {!editMode && (
                <EditButton
                  label={''}
                  onClick={() => {
                    setEditMode(true);
                  }}
                />
              )}
              {editMode && <Button label={tr(`Labels.done`, `Done`)} onClick={() => setEditMode(false)}></Button>}
            </RowWithSpaceBetween>
            <LabelsRow>
              {labels.map((label, index) => {
                return (
                  <LabelRow key={index}>
                    <Label color={label.labelColor}>
                      <Checkbox checked={objectLabels.find(t => t.labelId == label.labelId) != null} onChange={e => toggleLabel(label)}></Checkbox>
                      {label.labelValue}
                    </Label>
                    {editMode && <div className={'pi pi-trash'} onClick={e => deleteLabel(label)}></div>}
                  </LabelRow>
                );
              })}
            </LabelsRow>
            <CenteredButtonRow>
              <Button label={tr(`Labels.newLabel`, `New label`)} onClick={e => setShowNewLabelUI(true)}></Button>
            </CenteredButtonRow>
          </>
        )}
      </OverlayPanel>
    </>
  );
};

const LabelsWrapper = styled.div`
  display: flex;
  gap: 0.2rem;
`;

const LabelsInner = styled.div`
  display: flex;
  height: 2rem;
  margin-right: 0.5rem;
  flex-direction: row;
  gap: 0.4rem;
`;

const CenteredButtonRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 0.8rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  row-gap: 1rem;
`;

const LabelsRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
`;

const LabelRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Label = styled.div<{ textColor?: string }>`
  display: flex;
  width: 200px;
  background-color: ${props => props.color};
  height: 2rem;
  margin: 0.2rem 0;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem;
  color: ${props => props.textColor};
  border-radius: 4px;
`;

const ColorRow = styled.div`
  display: grid;
  gap: 0.4rem;
  grid-template-columns: auto auto auto;
`;
const ColorBox = styled.a`
  display: flex;
  background-color: ${props => props.color};
  width: 60px;
  height: 2rem;
  margin: 0.2rem 0;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 4px;
`;

const NavigationPanel = styled.div`
  display: flex;
  justify-content: space-between;

  a {
    cursor: pointer;
  }

  b {
    margin: 0 1rem;
  }
`;

export default Labels;
