import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import { InputText } from 'primereact/inputtext';
import SvgShipmentNumber from '@app/pages/dap/tags/ShipmentNumber.tsx';

interface ComponentProps {
  value: string;
  onChange?: (value: string) => void;
  readonly?: boolean;
  errorMessage?: string;
}

const ShipmentNumberTag: React.FC<ComponentProps> = ({ value, onChange, readonly, errorMessage }) => {
  const [internalValue, setInternalValue] = useState(value);
  const { tr } = useTranslationLgs();
  return (
    <>
      <ButtonTag
        label={tr('ShipmentNumberTag.shipmentNumber', 'Shipment number')}
        icon={<SvgShipmentNumber />}
        required={true}
        value={value}
        onSave={onSuccess => {
          onChange(internalValue);
          onSuccess();
        }}
        errorMessage={errorMessage}
        readonly={readonly}
      >
        <InputText value={internalValue} onChange={e => setInternalValue(e.target.value)} />
      </ButtonTag>
    </>
  );
};

export default ShipmentNumberTag;
