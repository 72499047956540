import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import SvgCustomer from '@components/ringil3/icons/Customer';

interface ComponentProps {
  customerName: string;
}

const CustomerNameTag: React.FC<ComponentProps> = ({ customerName }) => {
  const { tr } = useTranslationLgs();
  const [open, setOpen] = useState(false);

  return (
    <>
      <ButtonTag label={tr(`CustomerNameTag.title`, `Customer`)} value={customerName} icon={<SvgCustomer fill={'var(--content-secondary)'} />} />
    </>
  );
};

export default CustomerNameTag;
