import { ExpeditionDuplicationGlobalTimeslotsDTO, OpeningModeEnum } from '@api/logsteo-api.v2.tsx';

export const remapOpeningHours = (openingHours: ExpeditionDuplicationGlobalTimeslotsDTO, tr: any) => {
  return openingHours?.dayWithInterval.reduce((previousValue, currentValue) => {
    const newValue = currentValue.intervals.map(i => {
      return {
        localDate: currentValue.localDate,
        hours: `${i.sinceHoursString} - ${i.tillHoursString}`,
        isCollision: currentValue.isCollision,
      };
    });

    const newValues =
      newValue.length > 0
        ? newValue
        : [
            {
              localDate: currentValue.localDate,
              hours: currentValue.openingMode === 'CLOSED' ? tr(`LocalityDetail.closed`, `Closed`) : tr(`LocalityDetail.allDay`, `non-stop`),
              isCollision: currentValue.isCollision,
            },
          ];

    return [...previousValue, ...newValues];
  }, []);
};
