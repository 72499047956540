import React, { useContext } from 'react';
import styled from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ColWithGap, Row, RowForTagsAndBadges } from '@components/styles';
import DistributionValidTillTag from '@components/framework/tags/DistributionValidTillTag.tsx';
import ExpectedPriceTag from '@components/framework/tags/ExpectedPriceTag.tsx';
import RequestedPriceTag from '@components/framework/tags/RequestedPriceTag.tsx';
import SentBadge from '@components/ringil3/Badges/SentBadge';
import SpotQuoteTable from '@components/ringil3/Features/Expedition/QuoteTable/SpotQuoteTable';
import { ExpeditionDetailV4OutDTO, ExpeditionStatusEnum } from '@api/logsteo-api.v2';
import PanelWithTextAndIcon from '@components/ringil3/Panels/PanelWithTextAndIcon';
import AddButton from '@components/ringil3/Buttons/AddButton';
import SvgLkw from '@components/ringil3/icons/heading/Lkw';
import { useCreateAddMoreCarriers } from '@components/ringil3/Features/Expedition/Sidebars/AddMoreCarriersSidebar.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import { createLocalDateTime } from '@utils/date.tsx';
import { ApiContext } from '@api/api.tsx';

interface ComponentProps {
  expedition: ExpeditionDetailV4OutDTO;
  onReload: () => void;
  heading?: string;
}

const TabExpeditionQuotes: React.FC<ComponentProps> = ({ expedition, onReload, heading }) => {
  const { tr } = useTranslationLgs();
  const { changeExpeditionDistributionDate } = useContext(ApiContext);

  const { AddMoreCarriers, setVisible: setVisibleAddMoreCarriers } = useCreateAddMoreCarriers();

  return (
    <>
      <ColWithGap>
        <COHeadline
          variant={'h2'}
          title={
            heading ? heading : tr(`ExpeditionDetail.assignedCarriers`, `{{count}} selected carriers`, { count: expedition.quotes.length }, expedition.quotes.length)
          }
          icon={<SvgLkw />}
        ></COHeadline>
        <RowForTagsAndBadges>
          <Row>
            <DistributionValidTillTag
              onChanged={v => {
                changeExpeditionDistributionDate(
                  expedition.id,
                  {
                    localDateTime: createLocalDateTime(v),
                    timeZone: v.timeZone,
                  },
                  () => onReload(),
                );
              }}
              readonly={false}
              dateTimeResponse={expedition?.distribution?.validTill}
            />
            {expedition.distribution.distributionType === 'SPOT' && (
              <ExpectedPriceTag price={expedition.distribution?.price} currency={expedition.distribution?.currency} expeditionId={expedition.id} onReload={onReload} />
            )}
            {expedition.distribution.distributionType === 'FIXED_PRICE' && (
              <RequestedPriceTag price={expedition.distribution?.price} currency={expedition.distribution?.currency} />
            )}
          </Row>
          <BadgeRow>
            <SentBadge fullName={expedition.distribution?.createdByName} sentAt={expedition.distribution?.createdAt} />
          </BadgeRow>
        </RowForTagsAndBadges>
        {expedition.quotes?.length > 0 && (
          <SpotQuoteTable quotes={expedition.quotes} onChanged={() => onReload()} expeditionId={expedition.id} companyId={expedition.customerId}></SpotQuoteTable>
        )}

        {expedition.expeditionStatus === ExpeditionStatusEnum.ASSIGNING && (
          <PanelWithTextAndIcon
            onClick={() => {
              setVisibleAddMoreCarriers(true);
            }}
            text={tr(`ExpeditionDetail.addAnotherCarrier`, `Add another carrier`)}
            iconButton={<AddButton onClick={() => setVisibleAddMoreCarriers(true)} />}
          />
        )}
      </ColWithGap>
      <AddMoreCarriers
        applicationId={expedition.applicationId}
        customerId={expedition.customerId}
        onContinue={() => {
          onReload();
        }}
      />
    </>
  );
};

const BadgeRow = styled.div`
  display: flex;
  gap: var(--ringil-cyan-gap);
`;

export default TabExpeditionQuotes;
