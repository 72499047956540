import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import ImportCompanyLocation from '@components/obsolete/admin/import/companylocations/ImportCompanyLocation';
import AdminLayout from '@app/layout/AdminLayout.tsx';

interface ComponentProps {}

const ImportCompanyWithUsersLocationPage: React.FC<ComponentProps> = () => {
  return (
    <AdminLayout title={'ImportCompanyWithUsersLocationPage'}>
      <ImportCompanyLocation />
    </AdminLayout>
  );
};

export default withAuthenticationRequired(ImportCompanyWithUsersLocationPage);
