import React, { PropsWithChildren, ReactElement } from 'react';
import styled from 'styled-components';
import { isNotBlank } from '@utils/utils.tsx';

interface ComponentProps {
  label: string;
  valueText: string;
  iconPath?: string;
  icon?: ReactElement;
  showHr?: boolean;
}

export const VerticalFieldAndValueWithIcon: React.FC<PropsWithChildren<ComponentProps>> = ({ label, valueText, iconPath, children, icon, showHr = false }) => {
  return (
    <>
      <Grid>
        {isNotBlank(iconPath) && <img src={iconPath} />}
        {icon && <>{icon}</>}
        <Content>
          <LabelContainer>
            <Label>{label}</Label>
          </LabelContainer>
          <ContentInner>{valueText}</ContentInner>
        </Content>
      </Grid>
      {showHr && <Hr />}
    </>
  );
};

const Hr = styled.hr`
  border: 1px solid var(--ringil-base-quaternarycolor);
  width: 100%;
  color: Red;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
`;

const LabelContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const ContentInner = styled.div`
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: var(--ringil3-primary-color);
`;

const Label = styled.div`
  font-size: 14px;
  line-height: 12px;
  /* Content/Secondary */
  color: var(--ringil3-gr4);
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
