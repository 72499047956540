import { InputText } from 'primereact/inputtext';
import React, { useContext, useEffect } from 'react';
import { CreateCompanyFolderRequestInDTO } from '@api/logsteo-api.v2';

import * as yup from 'yup';
import { findValidationMessage } from '@utils/validation';
import { ApiContext } from '@api/api';
import RingilSidebar from '@components/ringil3/Sidebar/RingilSidebar.tsx';
import { ColWithGap } from '@components/styles.tsx';
import { VerticalFieldAndValue } from '@components/ringil3/Containers/VerticalFieldAndValue.tsx';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import useForm from '@hooks/useForm/useForm.tsx';

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  folderId?: string;
  folderName?: string;
  companyId: string;
}

export const EditCompanyFolderSideBar: React.FC<ComponentProps> = ({ visible, onHide, folderId, folderName, companyId }) => {
  const { createCompanyFolder, updateCompanyFolder } = useContext(ApiContext);
  const { tr } = useTranslationLgs();

  const validationScheme = yup.object().shape({
    name: yup.string().defined().required(),
  });

  const form = useForm<CreateCompanyFolderRequestInDTO>(validationScheme, null, d => save(d), false, false);

  useEffect(() => {
    if (visible) {
      form.clear();
      form.setValues({
        name: folderName,
      } as CreateCompanyFolderRequestInDTO);
    }
  }, [visible]);

  const save = (data: CreateCompanyFolderRequestInDTO) => {
    if (folderId) {
      updateCompanyFolder(companyId, folderId, form.values, () => {
        onHide();
      });
    } else {
      createCompanyFolder(companyId, form.values, () => {
        onHide();
      });
    }
  };

  return (
    <>
      <RingilSidebar visible={visible} onBack={onHide} onContinue={() => form.validateAndSend()} heading={folderId ? 'Update folder' : 'Create folder'}>
        {form.values && (
          <ColWithGap>
            <VerticalFieldAndValue
              label={'Folder name'}
              labelMode={'normal'}
              required={true}
              errorMessage={findValidationMessage(form.validationErrors, form.names.name, tr)}
            >
              <InputText value={form.values.name} onChange={e => form.setFieldValue(form.names.name, e.target.value)} />
            </VerticalFieldAndValue>
          </ColWithGap>
        )}
      </RingilSidebar>
    </>
  );
};
