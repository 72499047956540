import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { TimeResponse } from '@api/logsteo-api.v2.tsx';
import { createLocalDateTime } from '@utils/date.tsx';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import SvgCargotype from '@components/ringil3/icons/Cargotype.tsx';
import SvgTagEdit from '@components/ringil3/icons/TagEdit.tsx';
import InputDateAndTime from '@components/ringil3/Input/InputDateAndTime/InputDateAndTime.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import useForm from '@hooks/useForm/useForm.tsx';
import { isNullOrUndefined } from '@utils/utils.tsx';
import * as yup from 'yup';

interface ComponentProps {
  dateTimeResponse: TimeResponse;
  readonly?: boolean;
  onChanged: (dateTimeResponse: TimeResponse) => void;
}

interface Form {
  dateTimeResponse: TimeResponse;
}

const DistributionValidTillTag: React.FC<ComponentProps> = ({ dateTimeResponse, onChanged, readonly = false }) => {
  const { tr } = useTranslationLgs();

  const form = useForm<Form>(yup.object().shape({}), { dateTimeResponse }, d => save(d), false, false);

  const save = (data: Form) => {
    onChanged(data.dateTimeResponse);
  };

  const printValue = (dateTimeResponse: TimeResponse) => {
    if (isNullOrUndefined(dateTimeResponse)) {
      return null;
    }
    return `${dateTimeResponse.localDate} ${dateTimeResponse.hourString}`;
  };

  const parse = (
    localDateTime: string,
  ): {
    hourString: string;
    localDate: string;
  } => {
    const [date, time] = localDateTime.split('T');
    return { hourString: time, localDate: date };
  };

  return (
    <>
      <ButtonTag
        label={tr(`DistributionValidTillTag.title`, `Valid till`)}
        value={printValue(dateTimeResponse)}
        icon={<SvgCargotype />}
        actionIcon={<SvgTagEdit />}
        onSave={onSuccess => {
          form.validateAndSend(onSuccess);
        }}
        readonly={readonly}
        popupWidth={'500px'}
        autoClose
      >
        <HorizontalFieldValue
          label={tr(`DistributionValidTillTag.validTill`, `Valid till`)}
          value={
            <InputDateAndTime
              timeZone={form.values.dateTimeResponse?.timeZone}
              value={createLocalDateTime(form.values.dateTimeResponse)}
              onChange={v => {
                form.useProduce(draft => {
                  const { localDate, hourString } = parse(v);
                  draft.dateTimeResponse.localDate = localDate;
                  draft.dateTimeResponse.hourString = hourString;
                });
              }}
              onChangeTimeZone={v => {
                form.useProduce(draft => {
                  draft.dateTimeResponse.timeZone = v;
                });
              }}
            />
          }
        />
      </ButtonTag>
    </>
  );
};

export default DistributionValidTillTag;
