import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { LovWithoutIdDTO } from '@api/logsteo-api.v2';
import { Field } from '@components/framework/formfield/Field.tsx';
import { CargoTypeCodeEnum } from '@utils/constants.ts';

interface ComponentProps {
  value: CargoType;
  onChange: (cargoType: CargoType) => void;
  disabled?: boolean;
}

export interface CargoType {
  cargoTypeCode: string;
  adrWeight?: number;
}

export const InputCargoType: React.FC<ComponentProps> = ({ value, onChange, disabled = false }) => {
  const { tr } = useTranslationLgs();
  const { lovSHSNCargoTypes } = useContext(ApiContext);
  const [cargoTypes, setCargoTypes] = useState<LovWithoutIdDTO[]>([]);

  useEffect(() => {
    lovSHSNCargoTypes(d => {
      setCargoTypes(d);
      if (value?.cargoTypeCode !== CargoTypeCodeEnum.ADR_LQ) {
        onChange({ ...value, adrWeight: null });
      }
    });
  }, []);

  return (
    <div>
      <div>
        <Field label={tr(`CargoType.cargoType`, `Cargo type`)}>
          <Dropdown
            disabled={disabled}
            options={cargoTypes}
            optionValue={'code'}
            optionLabel={'name'}
            value={value?.cargoTypeCode}
            style={{ width: '100%' }}
            onChange={e => onChange({ ...value, cargoTypeCode: e.value })}
          />
        </Field>
      </div>
      {value?.cargoTypeCode === CargoTypeCodeEnum.ADR_LQ && (
        <div>
          <Field required={true} label={tr('CargoType.adrWeightKg', 'ADR weight [kg]')}>
            <InputNumber
              value={value?.adrWeight}
              onChange={e => onChange({ ...value, adrWeight: e.value })}
              minFractionDigits={0}
              maxFractionDigits={2}
              locale={'cs'}
              style={{ width: '100%' }}
            />
          </Field>
        </div>
      )}
    </div>
  );
};
