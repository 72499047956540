import React, { useContext, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { isNullOrUndefined } from '@utils/utils';
import { Button } from 'primereact/button';
import { ApiContext } from '@api/api';
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';
import styled from 'styled-components';
import { Row } from '@components/styles.tsx';
import InputCountryPlain from '@components/obsolete/Form/InputCountry/InputCountryPlain.tsx';
import Label from '@components/obsolete/Form/Label/Label.tsx';

interface ComponentProps {}

interface FormData {
  companyName?: string;
  crn?: string;
  city?: string;
  zipCode?: string;
  streetNr?: string;
  vat?: string;
  country?: string;
  adminFirstName?: string;
  adminLastName?: string;
  adminEmail?: string;
  phone?: string;
  onboardingPopulate: boolean;
  timeZone: string;
}

const CreateCompany: React.FC<ComponentProps> = () => {
  const [formData, setFormData] = useState<FormData>({
    onboardingPopulate: false,
    timeZone: 'Europe/Prague',
  });
  const { createFirstCompanyRegistration, getCompanyFromAresByICO } = useContext(ApiContext);

  const onChange = (property: any, value: any) => {
    setFormData(prevState => {
      return { ...prevState, [property]: value };
    });
  };

  const onLoadFromAres = () => {
    getCompanyFromAresByICO(formData.crn, d => {
      onChange('zipCode', d.zipCode);
      onChange('companyName', d.name);
      onChange('city', d.city);
      onChange('streetNr', d.street);
      onChange('crn', d.ico);
      onChange('vat', d.dic);
      onChange('country', 'CZE');
    });
  };

  const createCustomer = () => {
    // @ts-ignore
    createFirstCompanyRegistration(formData, () => {
      // @ts-ignore
      toast.current?.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Customer invited...',
      });
    });
  };

  const toast = useRef();

  return (
    <>
      <div className="p-fluid p-formgrid p-grid">
        <div className="p-col-12 p-md-6 p-lg-6">
          <Toast ref={toast} />
          <h1>Create company</h1>
          <div className={'p-fluid'}>
            <div className="p-field  ">
              <div className="p-field  ">
                <Label title={'CRN'}></Label>
                <Row>
                  <InputText value={formData.crn} onChange={e => onChange('crn', (e.target as HTMLInputElement).value)} />
                  <Button label={'Load from ARES'} onClick={e => onLoadFromAres()} disabled={isNullOrUndefined(formData.crn)} />
                </Row>
                <small>CRN/ICO/TIN</small>
              </div>
              <Label title={'Company name'}></Label>
              <InputText value={formData.companyName} onChange={e => onChange('companyName', (e.target as HTMLInputElement).value)} />
              <small>Enter company name</small>
            </div>

            <div className="p-field  ">
              <Label title={'City'}></Label>
              <InputText value={formData.city} onChange={e => onChange('city', (e.target as HTMLInputElement).value)} />
              <small>Company registration city</small>
            </div>
            <div className="p-field ">
              <Label title={'Zip'}></Label>
              <InputText value={formData.zipCode} onChange={e => onChange('zipCode', (e.target as HTMLInputElement).value)} />
              <small>Zip/Postal code</small>
            </div>
            <div className="p-field ">
              <Label title={'Street with number'}></Label>
              <InputText value={formData.streetNr} onChange={e => onChange('streetNr', (e.target as HTMLInputElement).value)} />
              <small>Street with number</small>
            </div>
            <div className="p-field ">
              <Label title="VAT number"></Label>
              <InputText value={formData.vat} onChange={e => onChange('vat', (e.target as HTMLInputElement).value)} />
              <small>VAT number</small>
            </div>
            <div className="p-field ">
              <Label title="Country"></Label>
              {/*              <Dropdown
                value={formData.country}
                options={[
                  { code: 'CZE', name: 'Cesko' },
                  { code: 'SVK', name: 'Slovensko' },
                ]}
                optionLabel={'name'}
                optionValue={'code'}
                dataKey={'code'}
                onChange={(e) => onChange('country', e.value)}
              />*/}
              <InputCountryPlain codeValue={formData.country} onChange={v => onChange('country', v)} />
              <small>Country</small>
            </div>
            <div className="p-field ">
              <Label title="Admmin first name"></Label>
              <InputText value={formData.adminFirstName} onChange={e => onChange('adminFirstName', (e.target as HTMLInputElement).value)} />
              <small>Administrator first name</small>
            </div>
            <div className="p-field ">
              <Label title="Admin last name"></Label>
              <InputText value={formData.adminLastName} onChange={e => onChange('adminLastName', (e.target as HTMLInputElement).value)} />
              <small>Administrator last name</small>
            </div>
            <div className="p-field ">
              <Label title="Admin email contact"></Label>
              <InputText value={formData.adminEmail} onChange={e => onChange('adminEmail', (e.target as HTMLInputElement).value)} />
              <small>Admin email - used to send email with password</small>
            </div>
            <div className="p-field ">
              <Label title="Admin phone contact"></Label>
              <InputText value={formData.phone} onChange={e => onChange('phone', (e.target as HTMLInputElement).value)} />
              <small>Admin phone</small>
            </div>
            <div className="p-field ">
              <Label title="Timezone - nemenit"></Label>
              <InputText value={formData.timeZone} onChange={e => onChange('timeZone', (e.target as HTMLInputElement).value)} />
            </div>
            <div className="p-field ">
              <CheckboxRow>
                <Checkbox checked={formData.onboardingPopulate} onChange={e => onChange('onboardingPopulate', e.checked)} />
                <CheckboxLabel>Populate sample data</CheckboxLabel>
              </CheckboxRow>
              <small>Populate sample data for onboarding</small>
            </div>
            <div className="p-grid p-justify-end">
              <div className={'p-col p-lg-4 p-md-6 p-sm-12'}>
                <Button label="Save" onClick={() => createCustomer()} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const CheckboxRow = styled.div`
  display: flex;
  gap: 0.2rem;
`;

const CheckboxLabel = styled.div`
  display: flex;
  font-weight: bold;
`;

export default CreateCompany;
