import React, { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { ApiContext } from '@api/api';
import { CompanyPreviewOutDTO } from '@api/logsteo-api.v2';

interface ComponentProps {
  value: CompanyPreviewOutDTO;
  onChange: (value: CompanyPreviewOutDTO) => void;
  style?: any;
  readMode?: boolean;
  required?: boolean;
}

const InputCompanyPartnerPreview: React.FC<ComponentProps> = ({ value, onChange, style, readMode = false, required = true }) => {
  const { cuLovPartners } = useContext(ApiContext);
  const [partners, setPartners] = useState<CompanyPreviewOutDTO[]>([]);

  useEffect(() => {
    cuLovPartners(null, d => {
      setPartners(
        d.map(t => {
          return {
            companyId: t.id,
            companyName: t.name,
          };
        }),
      );
      if (d?.length > 0 && !readMode && required && d.length > 0) onChange({ companyId: d[0].id, companyName: d[0].name });
    });
  }, []);

  return (
    <>
      {readMode ? (
        <div></div>
      ) : (
        <Dropdown
          style={style}
          value={value}
          options={partners}
          optionLabel={'companyName'}
          dataKey={'companyId'}
          onChange={e => {
            onChange(e.value);
          }}
        />
      )}
    </>
  );
};

export default InputCompanyPartnerPreview;
