import { OrdersConfig } from '@components/framework/orders/validation.ts';

export const dapOrderConfig: OrdersConfig = {
  orderName: { visibility: 'always', required: true },
  cargo: { visibility: 'always', required: true },
  cargoDimensions: {
    visibility: 'always',
    required: true,
    items: {
      width: { required: false },
      height: { required: false },
      length: { required: false },
      weight: { required: true },
    },
  },
  cargoDetails: {
    visibility: 'always',
    required: true,
    items: { orderAmount: { required: false }, orderDescription: { required: false }, orderValue: { required: true } },
  },
  totalWeight: {
    render: true,
  },
  unloadingLocation: {
    render: false,
  },
};
