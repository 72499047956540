import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import { EntityTypeEnum, State, WorkflowPossibleTransitionOutDTO } from '@api/logsteo-api.v2';
import { Lkw } from '@components/ringil3/icons/heading';
import SvgActive from '@components/ringil3/icons/workflow/Active';
import { dumpVars, isNullOrUndefined, visualiseWorkflowStateState } from '@utils/utils';
import SvgTagEdit from '@components/ringil3/icons/TagEdit';
import useForm from '@hooks/useForm/useForm';
import { Dropdown } from 'primereact/dropdown';

interface ComponentProps {
  state: State;
  reservationId: string;
  onReload: () => void;
}

interface Form {
  state: string;
}

const ReservationWorkflowStateTag: React.FC<ComponentProps> = ({ state, reservationId, onReload }) => {
  const { tr } = useTranslationLgs();
  const [open, setOpen] = useState(false);
  const { getWorkflowPossibleTransition, performTransitionOnEntity } = useContext(ApiContext);
  const [workflowInfo, setWorkflowInfo] = useState<WorkflowPossibleTransitionOutDTO>();

  const form = useForm<Form>(yup.object().shape({}), { state: state?.name }, v => save(v.state), true, false);

  useEffect(() => {
    const c = new AbortController();
    getWorkflowPossibleTransition(reservationId, 'TIMESLOT', d => {
      if (c.signal.aborted) return;
      setWorkflowInfo(d);
    });
    return () => {
      c.abort();
    };
  }, [state]);

  if (isNullOrUndefined(workflowInfo)) return <></>;

  const renderIcon = () => {
    if (state.icon === null) return <></>;
    switch (state.icon.name) {
      default:
        return <SvgActive fill={state.icon.color} />;
    }
  };

  const save = (state: string) => {
    performTransitionOnEntity(reservationId, EntityTypeEnum.TIMESLOT, { transitionToName: state }, () => {
      onReload();
      setOpen(false);
    });
  };

  const pane = (
    <Dropdown
      options={workflowInfo.possibleTransitions.map(t => t.to)}
      optionLabel={'name'}
      optionValue={'name'}
      itemTemplate={d => visualiseWorkflowStateState(d, tr)}
      valueTemplate={d => visualiseWorkflowStateState(d, tr)}
      value={form.values.state}
      onChange={v => {
        form.setFieldValue('state', v.value);
      }}
      placeholder={workflowInfo.currentState}
    />
  );

  return (
    <>
      <ButtonTag
        label={tr(`ReservationWorkflowStateTag.state`, `State`)}
        value={tr(`CreateEditWorkflowStateComponent.stateName${state.name}`, `${state.name}`)}
        icon={renderIcon()}
        actionIcon={<SvgTagEdit />}
        actionPane={pane}
        onSave={() => form.validateAndSend()}
        hideButtons={false}
        autoClose={false}
      />
    </>
  );
};

interface Props {
  reservationId: string;
  form: any;
}

export default ReservationWorkflowStateTag;
