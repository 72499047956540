import React from 'react';
import { InputSwitch } from 'primereact/inputswitch';
import clsx from 'clsx';

interface ComponentProps {
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
}

const RingilInputSwitch: React.FC<ComponentProps> = ({ value, label, onChange, disabled = false }) => {
  return (
    <div className={'flex flex-row gap-2 items-center'}>
      <InputSwitch checked={value} onChange={e => onChange(e.target.value)} disabled={disabled} />
      <label className={clsx({ 'text-content-tertiary': disabled })}>{label}</label>
    </div>
  );
};

export default RingilInputSwitch;
