import * as React from 'react';
import type { SVGProps } from 'react';
const SvgForExpedition = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} fill="none" {...props}>
    <path
      fill="#B2B2B2"
      d="M4 2a2 2 0 1 1-4 0 2 2 0 0 1 4 0M14 12a2 2 0 1 1-4 0 2 2 0 0 1 4 0M8 3.5a1.5 1.5 0 1 1 3 0V8h2V3.5a3.5 3.5 0 1 0-7 0v7a1.5 1.5 0 0 1-3 0V6H1v4.5a3.5 3.5 0 1 0 7 0z"
    />
  </svg>
);
export default SvgForExpedition;
