import React, { useState } from 'react';
import styled from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Clickable } from '@components/ringil3/styles';
import { VerticalFieldAndValue } from '@components/ringil3/Containers/VerticalFieldAndValue';
import { InputTextarea } from 'primereact/inputtextarea';
import { isBlank, isNullOrUndefined } from '@utils/utils';
import LinkWithIcon from '@components/ringil3/Links/LinkWithIcon.tsx';
import SvgIconPlus from '@components/framework/orders/IconPlus.tsx';
import { Field } from '@components/framework/formfield/Field.tsx';

interface ComponentProps {
  note: string;
  onChanges: (value: string) => void;
  noteLabel?: string;
}

const InputNoteWithLink: React.FC<ComponentProps> = ({ note, noteLabel, onChanges }) => {
  const { tr } = useTranslationLgs();
  const [open, setOpen] = useState(false);

  return (
    <div>
      {!open && isBlank(note) && <LinkWithIcon label={noteLabel} icon={<SvgIconPlus />} onClick={() => setOpen(true)}></LinkWithIcon>}
      {(note || open) && (
        <Field label={noteLabel}>
          <InputTextarea value={isNullOrUndefined(note) ? '' : note} onChange={e => onChanges(e.target.value)} rows={4} />
        </Field>
      )}
    </div>
  );
};

export default InputNoteWithLink;
