import { useEffect, useState } from 'react';

export const useDebounceWithEvents = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [isDebouncing, setIsDebouncing] = useState(false);

  useEffect(() => {
    setIsDebouncing(true); // Začátek debouncingu
    const handler = setTimeout(() => {
      setDebouncedValue(value);
      setIsDebouncing(false); // Konec debouncingu
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return [debouncedValue, isDebouncing];
};
