import React, { useContext, useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ApiContext } from '@api/api';
import styled from 'styled-components';
import { AdminModuleAllNotInheritedPreviewOutDTO, CompanyModuleEnum } from '@api/logsteo-api.v2';
import { InputSwitch } from 'primereact/inputswitch';
import { Button } from 'primereact/button';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';

interface ComponentProps {
  customerId: string;
}

const AdminModulesList: React.FC<ComponentProps> = ({ customerId }) => {
  const [data, setData] = useState<AdminModuleAllNotInheritedPreviewOutDTO[]>();
  const { adminAllNotInheritedCompanyModules, adminToggleModuleForCompany } = useContext(ApiContext);
  const { tr } = useTranslationLgs();
  useEffect(() => {
    load();
  }, []);

  const load = () => {
    adminAllNotInheritedCompanyModules(customerId, data => {
      setData(data);
    });
  };

  const toggleModule = (companyModule: CompanyModuleEnum, checked: boolean) => {
    adminToggleModuleForCompany(customerId, { companyModule: companyModule, isActive: checked }, () => {
      load();
    });
  };

  const populateData = () => {
    /*adminPopulateCompanyWithDefaultData(matches[2], () => {
      load();
    });*/
    alert('Removed: mysql migration');
  };

  return (
    <>
      <h1>Owned modules</h1>
      <div>
        <Button label={`Populate with default data`} onClick={populateData} />
      </div>
      <DataTableWrapper>
        {/*// @ts-ignore*/}
        <DataTable value={data} emptyMessage={tr(`global.noRecordFound`, `Nebyly nalezeny žádné záznamy`)}>
          <Column header={'Module type'} body={(row: AdminModuleAllNotInheritedPreviewOutDTO) => <div>{row.companyModule}</div>} />
          <Column
            style={{ width: '5rem' }}
            header={'Active'}
            body={(row: AdminModuleAllNotInheritedPreviewOutDTO) => <InputSwitch checked={row.isActive} onChange={e => toggleModule(row.companyModule, e.value)} />}
          ></Column>
        </DataTable>
      </DataTableWrapper>
    </>
  );
};

const DataTableWrapper = styled.div`
  .p-datatable .p-datatable-tbody > tr > td {
    padding: 1rem 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default AdminModulesList;
