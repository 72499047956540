import React, { useContext, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import { SplitButton } from 'primereact/splitbutton';
import ConfirmDialog from '@components/obsolete/ConfirmDialog/ConfirmDialog.tsx';
import useNavigation from '@hooks/useNavigation.tsx';

interface ComponentProps {
  archived: boolean;
  supplierNotificationId: string;
  isEntityOwner: boolean;
  onChange: () => void;
  isDAP: boolean;
}

export const MoreButtonsSupplierNotification: React.FC<ComponentProps> = ({ archived, supplierNotificationId, isEntityOwner, onChange, isDAP }) => {
  const { tr } = useTranslationLgs();
  const { changeArchiveStateSupplierNotification } = useContext(ApiContext);
  const [visibleConfirmDialog, setVisibleConfirmDialog] = useState(false);
  const nav = useNavigation();

  const model = [
    {
      label: tr('MoreButtonsSupplierNotification.archive', 'Archive'),
      icon: 'pi pi-times',
      command: (e: any) => {
        setVisibleConfirmDialog(true);
      },
      visible: !archived && isEntityOwner,
    },
    {
      label: tr('MoreButtonsSupplierNotification.duplicate', 'Duplicate'),
      icon: 'pi pi-refresh',
      command: (e: any) => {
        nav.navigate(nav.urlFunctions.createDuplicateSupplierNotification(supplierNotificationId));
        //window.location.href = `/supplier-notifications/duplicate-supplier-notification?fromSupplierNotificationId=${supplierNotificationId}`;
      },
      visible: !isDAP,
    },
  ];

  const archive = () => {
    changeArchiveStateSupplierNotification(supplierNotificationId, { archive: true }, () => {
      setVisibleConfirmDialog(false);
      onChange();
    });
  };

  return (
    <>
      {model.filter(t => t.visible).length > 0 && <SplitButton model={model.filter(t => t.visible)} className="btn-more-info p-button-rounded p-button-outlined" />}

      <ConfirmDialog
        visible={visibleConfirmDialog}
        dialogTitle={tr(`MoreButtonsSupplierNotification.archiveEntity`, `Do you want to archive this record?`)}
        dialogMessage={tr(`MoreButtonsSupplierNotification.switchToManualModeDialogBody`, `Do you want to archive this record?`)}
        onCancel={() => setVisibleConfirmDialog(false)}
        onConfirm={() => archive()}
        onAcceptButtonText={tr(`MoreButtonsSupplierNotification.yesArchive`, `Archive`)}
        onCancelButtonText={tr(`MoreButtonsSupplierNotification.close`, `Close`)}
        loading={true}
      />
    </>
  );
};
