import React, { useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import RingilSidebar from '@components/ringil3/Sidebar/RingilSidebar';
import { VerticalFieldAndValueWithIcon } from '@components/ringil3/Containers/VerticalFieldAndValueWithIcon';
import { AddMoreCarrierInDTO, CGV2LocationOutDTO, ContactPerson, LocationPreviewV4OutDTO } from '@api/logsteo-api.v2';
import { ApiContext } from '@api/api';
import useForm from '@hooks/useForm/useForm';
import * as yup from 'yup';
import useBEValidation from '@hooks/useBEValidation/useBEValidation';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { Field } from '@components/framework/formfield/Field.tsx';
import ReadonlyInput from '@components/framework/input/ReadonlyInput/ReadonlyInput.tsx';
import COInlineMessage from '@components/ringil3/COInlineMessage/COInlineMessage.tsx';
import SvgWarningExclamation from '@components/ringil3/icons/WarningExclamation.tsx';
import { formatAddress } from '@utils/formators.tsx';
import InputContact from '@components/framework/InputContact/InputContact.tsx';
import { MultiSelect } from 'primereact/multiselect';
import { isNotNullOrUndefined } from '@utils/utils.tsx';
import RingilInputText from '@components/framework/input/RingilInput/RingilInputText.tsx';
import InputCountry from '@components/framework/input/InputCountry/InputCountry.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import { InputCheckbox } from '@components/ringil3/Input/InputCheckbox/InputCheckbox.tsx';
import RingilButton from '@components/framework/buttons/RingilButton.tsx';
import SvgCoButtonDelete from '@icons/CoButtonDelete.tsx';
import LinkWithIcon from '@components/ringil3/Links/LinkWithIcon.tsx';
import SvgIconPlus from '@components/framework/orders/IconPlus.tsx';
import { ManualAddress } from '@app/pages/expedition/create/types.ts';

interface ComponentProps {
  applicationId: string;
  expeditionId: string;
  locationId: string;
  onContinue: () => void;
  location: LocationPreviewV4OutDTO;
}

interface Form extends ManualAddress {
  loadingReference?: string;
}

export const useCreateEditManualExpeditionLocation = () => {
  const [visible, setVisible] = useState(false);

  const EditExpeditionManualLocationSidebar: React.FC<ComponentProps> = ({ onContinue, applicationId, locationId, expeditionId, location }) => {
    const { tr } = useTranslationLgs();
    const { loggedUser } = useContext(AuthenticatedUserContext);
    const { editManualLocationExpedition } = useContext(ApiContext);
    const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();

    const mapPerson = (p: ContactPerson) => {
      return { contactEmail: p.contactEmail, contactName: p.contactName, contactPhone: p.contactPhone };
    };

    const save = (data: Form) => {
      editManualLocationExpedition(
        expeditionId,
        locationId,
        {
          selectedPerson: { contactEmail: data.mainContactEmail, contactName: data.mainContactPerson, contactPhone: data.mainContactPhone },
          address: { name: data.name, city: data.city, country: data.countryCode3, postalCode: data.postalCode, streetNr: data.streetNr },
          loadingReference: data.loadingReference,
          notifySelectedPerson: data.notifyContactPerson,
          additionalContacts: data.additionalPersons?.map(t => ({
            contactEmail: t.email,
            contactName: t.name,
          })),
        },
        () => {
          setVisible(false);
          onContinue();
        },
      );
    };

    const schema = yup.object().shape({});
    const form = useForm<Form>(schema, {}, d => save(d), false, false);

    useEffect(() => {
      if (isNotNullOrUndefined(location) && visible === true) {
        form.overwriteValues({
          mainContactEmail: location.selectedPerson?.contactEmail,
          mainContactPerson: location.selectedPerson?.contactName,
          mainContactPhone: location.selectedPerson?.contactPhone,
          name: location.name,
          postalCode: location.postalCode,
          countryCode3: location.country,
          city: location.city,
          streetNr: location.streetNr,
          additionalPersons: location.additionalContacts.map(t => ({ name: t.contactName, email: t.contactEmail })),
        });
      }
    }, [location]);

    if (!visible) return null;

    return (
      <>
        <RingilSidebar visible={visible} onBack={() => setVisible(false)} onContinue={() => form.validateAndSend()} heading={'Změna na lokalitě'} ready={false}>
          <div className={'flex flex-col gap-4'}>
            <BackendValidationComponent />
            <VerticalFieldAndValueWithIcon
              label={tr(`AddMoreCarriers.forExpedition`, `For expedition`)}
              valueText={applicationId}
              iconPath={'/images/icons/ringil3/forExpedition.svg'}
            />
            <>
              <Field required label={'Název lokace'}>
                <RingilInputText
                  value={form.values.name}
                  onChange={v =>
                    form.useProduce(d => {
                      d.name = v;
                    })
                  }
                />
              </Field>
              <div className={'grid grid-cols-2 gap-4'}>
                <Field label={'PSČ'}>
                  <RingilInputText
                    value={form.values.postalCode}
                    onChange={v =>
                      form.useProduce(draft => {
                        draft.postalCode = v;
                      })
                    }
                  />
                </Field>
                <Field label={'Země'}>
                  <InputCountry
                    value={form.values.countryCode3}
                    onChange={v => {
                      form.useProduce(d => {
                        d.countryCode3 = v;
                      });
                    }}
                  />
                </Field>
              </div>
              <Field required label={'Město'}>
                <RingilInputText
                  value={form.values.city}
                  onChange={v =>
                    form.useProduce(d => {
                      d.city = v;
                    })
                  }
                />
              </Field>
              <Field required label={'Ulice, č.p'}>
                <RingilInputText
                  value={form.values.streetNr}
                  onChange={v =>
                    form.useProduce(d => {
                      d.streetNr = v;
                    })
                  }
                />
              </Field>
              <COHeadline variant={'h4'} title={'Kontakty'} />
              <Field label={'Hlavní kontakt jméno'}>
                <RingilInputText
                  value={form.values.mainContactPerson}
                  onChange={v =>
                    form.useProduce(d => {
                      d.mainContactPerson = v;
                    })
                  }
                />
              </Field>
              <Field label={'Hlavní kontaktní telefon'}>
                <RingilInputText
                  value={form.values.mainContactPhone}
                  onChange={v =>
                    form.useProduce(d => {
                      d.mainContactPhone = v;
                    })
                  }
                />
              </Field>
              <Field label={'Hlavní kontaktní e-mail'}>
                <RingilInputText
                  value={form.values.mainContactEmail}
                  onChange={v =>
                    form.useProduce(d => {
                      d.mainContactEmail = v;
                    })
                  }
                />
              </Field>
              {form.values.additionalPersons?.length > 0 && <COHeadline variant={'h4'} title={'Notifikovat e-mailem o přepravě'} />}

              {form.values.mainContactEmail && form.values.mainContactPerson && (
                <InputCheckbox
                  value={form.values.notifyContactPerson}
                  label={'Hlavní kontakt'}
                  note={form.values.mainContactEmail}
                  onChange={(_, v) =>
                    form.useProduce(d => {
                      d.notifyContactPerson = v;
                    })
                  }
                />
              )}
              {form.values.additionalPersons?.map((person, personIndex) => {
                return (
                  <React.Fragment key={personIndex}>
                    <div className={'grid grid-cols-2 gap-4'}>
                      <Field label={'Jméno osoby'} required>
                        <RingilInputText
                          value={person.name}
                          onChange={v =>
                            form.useProduce(draft => {
                              draft.additionalPersons[personIndex].name = v;
                            })
                          }
                        />
                      </Field>
                      <Field
                        label={'Notifikovat e-mailem o přepravě'}
                        required
                        actionButtons={
                          <RingilButton
                            mode={'rect-small'}
                            leftIcon={<SvgCoButtonDelete />}
                            onClick={() => {
                              form.useProduce(draft => {
                                draft.additionalPersons = draft.additionalPersons.filter((_, i) => i !== personIndex);
                              });
                            }}
                          />
                        }
                      >
                        <RingilInputText
                          value={person.email}
                          onChange={v =>
                            form.useProduce(d => {
                              d.additionalPersons[personIndex].email = v;
                            })
                          }
                        />
                      </Field>
                    </div>
                  </React.Fragment>
                );
              })}
              <LinkWithIcon
                label={'Notifikovat další e-mail'}
                icon={<SvgIconPlus />}
                onClick={() => {
                  form.useProduce(draft => {
                    if (draft.additionalPersons == null) {
                      draft.additionalPersons = [];
                    }
                    draft.additionalPersons.push({
                      name: '',
                      email: '',
                    });
                  });
                }}
              ></LinkWithIcon>
              <Field label={'Reference pro nakládku'}>
                <RingilInputText value={form.values.loadingReference} onChange={v => form.setFieldValue('loadingReference', v)} />
              </Field>
            </>
          </div>
        </RingilSidebar>
      </>
    );
  };

  return { EditExpeditionManualLocationSidebar, setVisible, visible };
};
