import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import ImportCompanyLocation from '@components/obsolete/admin/import/companylocations/ImportCompanyLocation';
import AdminLayout from '@app/layout/AdminLayout.tsx';
import ImportCompanyWithUsers from '@components/obsolete/admin/import/companyWithUsers/ImportCompanyWithUsers.tsx';

interface ComponentProps {}

const ImportCompanyLocationPage: React.FC<ComponentProps> = () => {
  return (
    <AdminLayout title={'Requests'}>
      <ImportCompanyWithUsers />
    </AdminLayout>
  );
};

export default withAuthenticationRequired(ImportCompanyLocationPage);
