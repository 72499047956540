import React, { PropsWithChildren } from 'react';
import SvgBreadcrumbDivider from '@icons/BreadcrumbDivider.tsx';
import COText from '@app/pages/COText/COText.tsx';
import clsx from 'clsx';

interface ComponentProps {
  currentPosition: number;
  locationCount: number;
}

interface Props {
  position: number;
  currentPosition: number;
}

const Anchor: React.FC<PropsWithChildren<Props>> = ({ position, currentPosition, children }) => {
  return (
    <>
      <React.Fragment key={position}>
        <div className={clsx({ 'text-content-brand font-medium cursor-pointer': position !== currentPosition + 1 })}>{children}</div>
      </React.Fragment>
    </>
  );
};

const NavigationBreadCrumb: React.FC<ComponentProps> = ({ currentPosition, locationCount }) => {
  const resolveText = (i: number, currentPosition: number) => {
    if (i === 1) {
      return (
        <Anchor position={i} currentPosition={currentPosition}>
          <COText label={'Začátek trasy'} />
        </Anchor>
      );
    }
    if (i === locationCount) {
      return (
        <Anchor position={i} currentPosition={currentPosition}>
          <COText label={'Konec trasy'} />
        </Anchor>
      );
    } else {
      return (
        <Anchor position={i} currentPosition={currentPosition}>
          <COText label={'Mezizastávka'} />
        </Anchor>
      );
    }
  };

  return (
    <>
      <div id={`pos_${currentPosition}`} className={'flex flex-row items-center gap-4 flex-wrap'}>
        {Array.from({ length: locationCount }, (_, i) => i + 1).map(item => {
          return (
            <React.Fragment key={item}>
              {resolveText(item, currentPosition)}
              {item < locationCount && <SvgBreadcrumbDivider />}
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};

export default NavigationBreadCrumb;
