import React from 'react';
import styled from 'styled-components';
import { SelectableButton } from '@components/ringil3/Buttons/SelectableButton';

interface ComponentProps {
  model: ClickableItem[];
  selectedItemCode: string;
  onSelect: (selectedItemCode: string) => void;
  disabled?: boolean;
}

interface ClickableItem {
  code: string;
  label: string;
}

const InputSelect: React.FC<ComponentProps> = ({ model, selectedItemCode, onSelect, disabled }) => {
  return (
    <>
      <InputSelectInner>
        {model.map((item, itemPos) => {
          return <SelectableButton key={itemPos} label={item.label} onClick={() => (!disabled ? onSelect(item.code) : {})} checked={item.code === selectedItemCode} />;
        })}
      </InputSelectInner>
    </>
  );
};

const InputSelectInner = styled.div`
  display: flex;
  gap: 0.5rem;
  height: 40px;
`;

export default InputSelect;
