import React, { useEffect, useState } from 'react';
import useTranslationLgs from '../i18n/useTranslation';
import AlertInfo from '@components/obsolete/Alert/AlertInfo.tsx';
import { MessageResponseOutDTO } from '@api/logsteo-api.v2.tsx';

interface ValidationMessage extends MessageResponseOutDTO {}

const useBEValidation = (onValidationError: () => void = () => {}) => {
  const { tr } = useTranslationLgs();
  const [beValidationErrors, setBeValidationErrors] = useState<ValidationMessage[]>([]);

  const clear = () => {
    setBeValidationErrors([]);
  };

  useEffect(() => {
    if (beValidationErrors?.length > 0) {
      onValidationError();
    }
  }, [beValidationErrors]);

  const BackendValidationComponent = () => {
    return (
      <>
        {beValidationErrors && beValidationErrors.length > 0 && (
          <AlertInfo backgroundWrapper={'red'}>
            <ul>
              {beValidationErrors.map((error, index) => {
                return (
                  <li key={index} className={'beValidationItem'}>
                    {error.locized ? tr(error.message, 'Translate in locize') : error.message}
                  </li>
                );
              })}
            </ul>
          </AlertInfo>
        )}
      </>
    );
  };

  return {
    setBeValidationErrors,
    BackendValidationComponent,
    clear,
  };
};

export default useBEValidation;
