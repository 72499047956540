import React, { useContext, useState } from 'react';
import useForm from '@hooks/useForm/useForm';
import { ApiContext } from '@api/api';
import { ShipmentPartiesData, ShipmentPartiesForm } from '../forms/ShipmentPartiesForm';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { RingilSidebar } from '@components/obsolete/RingilSidebar/RingilSidebar.tsx';
import * as yup from 'yup';

type FormData = Pick<ShipmentPartiesData, 'departureAt' | 'arrivalAt'>;

interface ComponentProps {
  initialData?: FormData;
  shipmentId: string;
  companyId: string;
}

export const useCreateEditShipmentLoadingUnloadingSidebar = (onComplete: () => void) => {
  const [visible, setVisible] = useState(false);

  const EditShipmentLoadingUnloadingSidebar: React.FC<ComponentProps> = ({ initialData, shipmentId, companyId }) => {
    const { updateShipmentUpdateDataFoTimeslot } = useContext(ApiContext);
    const { tr } = useTranslationLgs();

    const form = useForm<FormData>(
      yup.object().shape({}),
      initialData,
      v => {
        updateShipmentUpdateDataFoTimeslot(
          shipmentId,
          {
            arrivalAt: form.values.arrivalAt ? form.values.arrivalAt.toISOString() : null,
            departureAt: form.values.arrivalAt ? form.values.departureAt.toISOString() : null,
          },
          () => {
            setVisible(false);
            onComplete();
          },
        );
      },
      true,
      true,
    );

    return (
      <RingilSidebar
        visible={visible}
        headerText={tr(`EditShipmentLoadingUnloadingSidebar.updateShipment`, `Update shipment`)}
        onHide={() => setVisible(false)}
        onSave={() => form.validateAndSend()}
      >
        {form.values && (
          <ShipmentPartiesForm
            // @ts-ignore
            form={form}
            inSidebar={true}
            showLoadingUnloading={false}
            showDates={true}
            showLocations={false}
          />
        )}
      </RingilSidebar>
    );
  };

  return { EditShipmentLoadingUnloadingSidebar, setVisible };
};
