import React from 'react';
import styled, { css } from 'styled-components';
import { GrayText } from '@components/ringil3/styles';
import { isNotNullOrUndefined } from '@utils/utils.tsx';

interface ComponentProps {
  filled?: boolean;
  label?: string;
  value?: string | number;
  backgroundColor?: string;
  borderColor?: string;
  highlightedValue?: boolean;
}

const Badge: React.FC<ComponentProps> = ({
  filled = false,
  label,
  value,
  highlightedValue,
  backgroundColor = 'var(--ringil3-brand-light-color)',
  borderColor = 'var(--ringil3-brand-light-color)',
}) => {
  return (
    <BadgeInner filled={filled} backgroundColor={backgroundColor} borderColor={borderColor}>
      {label && <GrayText>{label}</GrayText>}
      {isNotNullOrUndefined(value) && <Value highlightedValue={highlightedValue}>{value}</Value>}
    </BadgeInner>
  );
};

const Value = styled.div<Pick<ComponentProps, 'highlightedValue'>>`
  ${props => props.highlightedValue && `color: var(--ringil3-brand-color);`}
  font-weight: 700;
`;

const BadgeInner = styled.div<{ filled: boolean; backgroundColor: string; borderColor: string }>`
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 4px 8px;
  border-radius: 4px;
  gap: 0.25rem;
  font-size: 12px;
  ${props =>
    props.borderColor &&
    css`
      border: 1px solid ${props.borderColor};
    `};

  align-self: stretch; /* Tento potomek bude na celou výšku */

  ${props =>
    props.filled
      ? css`
          background-color: ${props.backgroundColor};
        `
      : css`
          background-color: var(--ringil3-base-secondary-color);
        `}
`;
export default Badge;
