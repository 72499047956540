import React, { useContext, useState } from 'react';
import { ApiContext } from '@api/api';
import { Column } from 'primereact/column';
import { AdminImportCompanyLocationRequest, AdminImportCompanyLocationResponse, AdminImportCompanyRequest } from '@api/logsteo-api.v2';
import { useCreateInputPasteTabular } from '@components/framework/input/InputPasteTabular/InputPasteTabular.tsx';
import { useCreateTable } from '@components/ringil3/RingilDataTable/DataTable.tsx';
import RingilButton from '@components/framework/buttons/RingilButton.tsx';

interface ComponentProps {}

interface FormValues extends AdminImportCompanyRequest {
  status: AdminImportCompanyLocationResponse;
}

const ImportCompanyWithUsers: React.FC<ComponentProps> = () => {
  const { adminImportCompany } = useContext(ApiContext);
  const [importData, setImportData] = useState([]);

  const { InputPasteTabular, PasteTabularButton } = useCreateInputPasteTabular<AdminImportCompanyRequest>([
    'crn',
    'companyName',
    'city',
    'zipCode',
    'street',
    'vat',
    'country',
    'adminFirstName',
    'adminLastName',
    'adminEmail',
    'adminPhone',
    'timeZone',
    'populateSampleData',
    'userOneFirstName',
    'userOneLastName',
    'userOneLastEmail',
    'userOnePhone',
    'userTwoFirstName',
    'userTwoLastName',
    'userTwoLastEmail',
    'userTwoPhone',
    'userThreeFirstName',
    'userThreeLastName',
    'userThreeLastEmail',
    'userThreePhone',
    'userFourFirstName',
    'userFourLastName',
    'userFourLastEmail',
    'userFourPhone',
    'userFiveFirstName',
    'userFiveLastName',
    'userFiveLastEmail',
    'userFivePhone',
  ]);

  const { Table, Column } = useCreateTable<FormValues>();

  const importAction = () => {
    importData.forEach((t, tIndex) =>
      adminImportCompany(t, res => {
        setImportData(prev =>
          prev.map((r, rIndex) => {
            if (tIndex === rIndex) {
              return { ...r, status: res };
            }
            return r;
          }),
        );
      }),
    );
  };

  return (
    <div className={'flex flex-col gap-4'}>
      <h1>Import company with users</h1>
      <div className={'flex flex-row gap-4 items-center'}>
        <PasteTabularButton />
        <a href={'/imports/template-create-company.xlsx'}>Download template</a>
      </div>
      <h2>Companies to import</h2>

      <InputPasteTabular
        onSave={data => {
          setImportData(data);
        }}
      />

      <div className={'max-w-full'}>
        <Table value={importData}>
          <Column
            fixed
            width={160}
            body={r => <div className={r.status?.error ? 'text-complementary-error' : 'text-complementary-success'}>{r.status?.message}</div>}
            header="Status"
          />
          <Column fixed width={200} body={r => <div>{r.crn}</div>} header="CRN" />
          <Column fixed width={200} body={r => <div>{r.companyName}</div>} header="Company Name" />
          <Column width={150} body={r => <div>{r.city}</div>} header="City" />
          <Column width={150} body={r => <div>{r.zipCode}</div>} header="Zip Code" />
          <Column width={150} body={r => <div>{r.street}</div>} header="Street" />
          <Column width={150} body={r => <div>{r.vat}</div>} header="VAT" />
          <Column width={150} body={r => <div>{r.country}</div>} header="Country" />
          <Column width={150} body={r => <div>{r.adminFirstName}</div>} header="Admin First Name" />
          <Column width={150} body={r => <div>{r.adminLastName}</div>} header="Admin Last Name" />
          <Column width={250} body={r => <div>{r.adminEmail}</div>} header="Admin Email" />
          <Column width={150} body={r => <div>{r.adminPhone}</div>} header="Admin Phone" />
          <Column width={150} body={r => <div>{r.timeZone}</div>} header="Time Zone" />
          <Column width={150} body={r => <div>{r.populateSampleData}</div>} header="Populate sample data" />
          <Column width={150} body={r => <div>{r.userOneFirstName}</div>} header="User One First Name" />
          <Column width={150} body={r => <div>{r.userOneLastName}</div>} header="User One Last Name" />
          <Column width={250} body={r => <div>{r.userOneLastEmail}</div>} header="User One Email" />
          <Column width={150} body={r => <div>{r.userOnePhone}</div>} header="User One Phone" />
          <Column width={150} body={r => <div>{r.userTwoFirstName}</div>} header="User Two First Name" />
          <Column width={150} body={r => <div>{r.userTwoLastName}</div>} header="User Two Last Name" />
          <Column width={250} body={r => <div>{r.userTwoLastEmail}</div>} header="User Two Email" />
          <Column width={150} body={r => <div>{r.userTwoPhone}</div>} header="User Two Phone" />
          <Column width={150} body={r => <div>{r.userThreeFirstName}</div>} header="User Three First Name" />
          <Column width={150} body={r => <div>{r.userThreeLastName}</div>} header="User Three Last Name" />
          <Column width={250} body={r => <div>{r.userThreeLastEmail}</div>} header="User Three Email" />
          <Column width={150} body={r => <div>{r.userThreePhone}</div>} header="User Three Phone" />
          <Column width={150} body={r => <div>{r.userFourFirstName}</div>} header="User Four First Name" />
          <Column width={150} body={r => <div>{r.userFourLastName}</div>} header="User Four Last Name" />
          <Column width={250} body={r => <div>{r.userFourLastEmail}</div>} header="User Four Email" />
          <Column width={150} body={r => <div>{r.userFourPhone}</div>} header="User Four Phone" />
          <Column width={150} body={r => <div>{r.userFiveFirstName}</div>} header="User Five First Name" />
          <Column width={150} body={r => <div>{r.userFiveLastName}</div>} header="User Five Last Name" />
          <Column width={250} body={r => <div>{r.userFiveLastEmail}</div>} header="User Five Email" />
          <Column width={150} body={r => <div>{r.userFivePhone}</div>} header="User Five Phone" />
        </Table>
      </div>
      <RingilButton label={'Import'} onClick={() => importAction()} mode={'filled-big'} />
    </div>
  );
};

export default ImportCompanyWithUsers;
