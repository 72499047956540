import React, { useContext, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import RingilSidebar from '@components/ringil3/Sidebar/RingilSidebar';
import { VerticalFieldAndValueWithIcon } from '@components/ringil3/Containers/VerticalFieldAndValueWithIcon';
import { GlobalTimeslotsDTO, OpeningEntityTypeEnum } from '@api/logsteo-api.v2';
import { ApiContext } from '@api/api';
import useForm from '@hooks/useForm/useForm';
import * as yup from 'yup';
import useBEValidation from '@hooks/useBEValidation/useBEValidation';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import ManageTimeslot from '@components/obsolete/TimeslotWithDefaults/ManageTimeslot.tsx';

interface ComponentProps {
  applicationId: string;
  expeditionId: string;
  timeslots: GlobalTimeslotsDTO;
  localityName: string;
  companyLocationId: string;
  locationId: string;
  onContinue: () => void;
}

interface EditExpeditionLocationForm {
  timeslots: GlobalTimeslotsDTO;
}

export const useCreateEditExpeditionTimeSlotsSidebar = () => {
  const [visible, setVisible] = useState(false);

  const EditExpeditionTimeSlotsSidebar: React.FC<ComponentProps> = ({ onContinue, applicationId, locationId, expeditionId, timeslots, companyLocationId }) => {
    const { tr } = useTranslationLgs();
    const { loggedUser } = useContext(AuthenticatedUserContext);
    const { editCompanyLocationTimeslotExpedition } = useContext(ApiContext);
    const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();

    const save = (data: EditExpeditionLocationForm) => {
      editCompanyLocationTimeslotExpedition(expeditionId, locationId, { timeslots: data.timeslots }, () => {
        onContinue();
        setVisible(false);
      });
    };

    const schema = yup.object().shape({});
    const form = useForm<EditExpeditionLocationForm>(schema, { timeslots }, d => save(d), false, false);

    if (!visible) return null;

    return (
      <>
        <RingilSidebar visible={visible} onBack={() => setVisible(false)} onContinue={() => form.validateAndSend()} heading={'Očekávaná manipulace'} ready={false}>
          <div className={'flex flex-col gap-4'}>
            <BackendValidationComponent />
            <VerticalFieldAndValueWithIcon label={'For expedition'} valueText={applicationId} iconPath={'/images/icons/ringil3/forExpedition.svg'} />
          </div>
          <>
            <ManageTimeslot
              entityId={companyLocationId}
              entityType={OpeningEntityTypeEnum.LOCATION}
              timeslot={form.values.timeslots}
              onChange={data => {
                form.useProduce(draft => {
                  draft.timeslots = data;
                });
              }}
              noOpeningHours={false}
            />
          </>
        </RingilSidebar>
      </>
    );
  };

  return { EditExpeditionTimeSlotsSidebar, setVisible, visible };
};
