import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import { CompanyPreviewOutDTO, InvoiceRebillingTypeEnum } from '@api/logsteo-api.v2.tsx';
import { Dropdown } from 'primereact/dropdown';
import { Field } from '@components/framework/formfield/Field.tsx';
import { isNullOrUndefined } from '@utils/utils.tsx';
import { useImmer } from 'use-immer';
import MultipleValues from '@components/ringil3/HelperComponents/MultipleValues.tsx';
import InputCompanyPartnerPreview from '@components/obsolete/Form/InputPartner/InputCompanyPartnerPreview.tsx';
import InputDepartmentMultiSelect from '@components/framework/input/InputDepartmentMultiSelect/InputDepartmentMultiSelect.tsx';
import InputDepartmentPlain from '@components/obsolete/Form/InputDepartmentPlain.tsx';

export interface RebillingType {
  invoiceRebillingType: InvoiceRebillingTypeEnum;
  invoicePartner?: CompanyPreviewOutDTO;
  invoiceDepartment?: string;
}

interface ComponentProps {
  value: RebillingType;
  required?: boolean;
  readonly?: boolean;
  onChange: (value: RebillingType) => void;
}

const InternalInfoRebillingTag: React.FC<ComponentProps> = ({ readonly, required, value, onChange }) => {
  const [internalValue, setInternalValue] = useImmer(value);
  const { tr } = useTranslationLgs();

  const renderText = (value: RebillingType) => {
    if (isNullOrUndefined(value)) return null;

    if (value.invoiceRebillingType === InvoiceRebillingTypeEnum.INTERNAL_REBILLING) {
      return <MultipleValues values={['Interní přeúčtování', value.invoiceDepartment]} />;
    }

    if (value.invoiceRebillingType === InvoiceRebillingTypeEnum.EXTERNAL_REBILLING) {
      return <MultipleValues values={['Externí přeúčtování', value.invoicePartner?.companyName]} />;
    }

    return 'Nepřeúčtovávat';
  };

  return (
    <>
      <ButtonTag
        label={'Přeúčtování'}
        required={required}
        readonly={readonly}
        value={renderText(value)}
        onSave={onSuccess => {
          onChange(internalValue);
          onSuccess();
        }}
        popupWidth={'400px'}
        autoClose
      >
        <>
          <Field label={'Přeúčtovat'}>
            <Dropdown
              value={internalValue?.invoiceRebillingType}
              style={{ width: '100%' }}
              options={[
                InvoiceRebillingTypeEnum.NON_REBILLING,
                InvoiceRebillingTypeEnum.INTERNAL_REBILLING,
                InvoiceRebillingTypeEnum.EXTERNAL_REBILLING,
                InvoiceRebillingTypeEnum.OTHER,
              ]}
              showClear={true}
              valueTemplate={e => <div>{tr(`UpdateExpeditionInternalInformationSidebar.InvoiceRebillingTypeEnum.${e}`, e)}</div>}
              itemTemplate={e => <div>{tr(`UpdateExpeditionInternalInformationSidebar.InvoiceRebillingTypeEnum.${e}`, e)}</div>}
              onChange={e =>
                setInternalValue(draft => {
                  if (draft == null) return { invoiceRebillingType: e.target.value };
                  draft.invoiceRebillingType = e.target.value;
                })
              }
            />
          </Field>

          {internalValue?.invoiceRebillingType === InvoiceRebillingTypeEnum.INTERNAL_REBILLING && (
            <Field label={'Interní oddělení'}>
              <InputDepartmentPlain
                department={internalValue.invoiceDepartment}
                style={{ width: '100%', padding: '0', margin: '0px', backgroundColor: 'red' }}
                onChange={v =>
                  setInternalValue(draft => {
                    if (draft == null) return { invoiceDepartment: v };
                    draft.invoiceDepartment = v;
                  })
                }
              />
            </Field>
          )}
          {internalValue?.invoiceRebillingType === InvoiceRebillingTypeEnum.EXTERNAL_REBILLING && (
            <Field label={'Partner'}>
              <InputCompanyPartnerPreview
                style={{ width: '100%' }}
                value={internalValue.invoicePartner}
                onChange={v =>
                  setInternalValue(draft => {
                    if (draft == null) return { invoicePartnerId: v };
                    draft.invoicePartner = v;
                  })
                }
              />
            </Field>
          )}
        </>
      </ButtonTag>
    </>
  );
};

export default InternalInfoRebillingTag;
