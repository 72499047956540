import React, { useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import SvgSearch from '@icons/Search.tsx';
import SvgChevronUp from '@icons/ChevronUp.tsx';
import COBadge from '@components/ringil3/COBadge/COBadge.tsx';
import SvgChevron from '@icons/Chevron.tsx';
import COText from '@app/pages/COText/COText.tsx';
import SvgMapPin from '@icons/MapPin.tsx';
import { useDebounce } from 'use-debounce';
import { CGV2SmallCompanyOutDTO, LocationContactTypeEnum } from '@api/logsteo-api.v2.tsx';

interface ComponentProps {
  companyLocations?: CGV2SmallCompanyOutDTO[];
  isLoading?: boolean;
  onSelect: (companyLocationId: string) => void;
  onFilter: (filter: string) => void;
}

const LocationSelectDropdown: React.FC<ComponentProps> = ({ companyLocations, isLoading, onSelect, onFilter }) => {
  const { tr } = useTranslationLgs();
  const [openCompanies, setOpenCompanies] = useState<Set<string>>(new Set());
  const [filterValue, setFilterValue] = useState('');
  const [debouncedFilterValue, control] = useDebounce<string>(filterValue, 700);

  const isOpen = (companyId: string) => {
    return openCompanies.has(companyId);
  };

  const openCompany = (companyId: string) => {
    setOpenCompanies(new Set(openCompanies.add(companyId)));
  };

  const close = (companyId: string) => {
    setOpenCompanies(new Set([...openCompanies].filter(x => x !== companyId)));
  };

  const toggle = (companyId: string) => {
    if (isOpen(companyId)) {
      close(companyId);
    } else {
      openCompany(companyId);
    }
  };

  useEffect(() => {
    onFilter(debouncedFilterValue);
  }, [debouncedFilterValue]);

  return (
    <>
      <div className={'flex flex-row justify-between items-center px-3 py-2 gap-3 text-content-secondary'}>
        <div className={'flex flex-row justify-between items-center gap-3 border border-solid w-full'}>
          <input
            type={'text'}
            className={'border-none h-[34px] w-full p-2 outline-none focus:ring-0 text-base text-content-secondary'}
            value={filterValue}
            onChange={e => setFilterValue(e.target.value)}
          />
          <div className={'mx-2'}>
            <SvgSearch className={isLoading ? 'animate-spin' : ''} />
          </div>
        </div>
      </div>
      <div className={'flex flex-col text-content-secondary h-[300px] overflow-auto'}>
        {companyLocations?.map((cl, clIndex) => {
          return (
            <div className={'flex flex-col border-b border-b-red-600'} style={{ borderBottom: '1px solid var(--content-quaternary)' }}>
              <div key={clIndex} className={'flex justify-between hover:bg-content-quaternary items-center px-3 py-3 cursor-pointer'} onClick={e => toggle(cl.companyId)}>
                <div>{cl.companyName}</div>
                <div className={'flex flex-row gap-2 items-center'}>
                  {cl.locationContactType == LocationContactTypeEnum.OUR_LOCATION && (
                    <COBadge type={'processing'} variant={'outlined'}>
                      <COText label={'Naše lokace'} />
                    </COBadge>
                  )}
                  {cl.locationContactType == LocationContactTypeEnum.PARTNER_LOCATION && (
                    <COBadge type={'draft'} variant={'outlined'}>
                      <COText label={'Partner'} />
                    </COBadge>
                  )}
                  {cl.locationContactType == LocationContactTypeEnum.OUR_EXTERNAL_LOCATION && (
                    <COBadge type={'draft'} variant={'outlined'}>
                      <COText label={'Externi'} />
                    </COBadge>
                  )}

                  <div>{isOpen(cl.companyId) ? <SvgChevronUp /> : <SvgChevron />}</div>
                </div>
              </div>
              {isOpen(cl.companyId) && (
                <>
                  {cl.locations.map((loc, locIndex) => {
                    return (
                      <div
                        key={locIndex}
                        className={'flex justify-between hover:bg-content-quaternary items-center px-3 py-3 cursor-pointer'}
                        onClick={e => {
                          onSelect(loc.locationId);
                        }}
                      >
                        <div className={'flex flex-row gap-4 items-center'}>
                          <SvgMapPin />
                          {loc.locationName}
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default LocationSelectDropdown;
