import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import SvgYellowExclamation from '@components/ringil3/icons/YellowExclamation.tsx';
import RingilTextArea from '@components/framework/input/RingilTextArea.tsx';

interface ComponentProps {
  value?: string;
  onChange: (carrierNote: string) => void;
}

const CarrierNoteTag: React.FC<ComponentProps> = ({ value, onChange }) => {
  const { tr } = useTranslationLgs();
  const [internalValue, setInternalValue] = useState(value);

  return (
    <>
      <ButtonTag
        label={tr(`CarrierNoteTag.title`, `Note for carrier`)}
        value={value}
        icon={<SvgYellowExclamation />}
        onSave={success => {
          onChange(internalValue);
          success();
        }}
        autoClose
      >
        <RingilTextArea value={internalValue} onChange={setInternalValue} maxLength={255} />
      </ButtonTag>
    </>
  );
};

export default CarrierNoteTag;
