import React, { useContext, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { ApiContext } from '@api/api.tsx';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import InputResponsiblePerson from '@components/framework/input/InputResponsiblePerson/InputResponsiblePerson.tsx';
import { LovDTO } from '@api/logsteo-api.v2.tsx';

interface ComponentProps {
  required?: boolean;
  readonly?: boolean;
  person: LovDTO;
  onChange: (responsiblePerson: LovDTO) => void;
}

const ResponsiblePersonTag: React.FC<ComponentProps> = ({ readonly, required, person, onChange }) => {
  const [internalValue, setInternalValue] = useState(person);
  const { tr } = useTranslationLgs();

  const renderText = (responsiblePersonId: LovDTO) => {
    return responsiblePersonId ? responsiblePersonId.name : null;
  };

  return (
    <>
      <ButtonTag
        label={tr('ResponsiblePersonTag.zodpovednaOsoba', 'Zodpovědná osoba')}
        required={required}
        readonly={readonly}
        value={renderText(person)}
        onSave={onSuccess => {
          onChange(internalValue);
          onSuccess();
        }}
        autoClose
      >
        <InputResponsiblePerson responsiblePerson={internalValue} onChange={setInternalValue} />
      </ButtonTag>
    </>
  );
};

export default ResponsiblePersonTag;
