import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import SvgDriver from '@components/ringil3/icons/Driver.tsx';

interface ComponentProps {
  assignedDriverName: string;
}

const AssignedDriverTag: React.FC<ComponentProps> = ({ assignedDriverName }) => {
  const { tr } = useTranslationLgs();
  const [open, setOpen] = useState(false);

  return (
    <>
      <ButtonTag label={tr(`AssignedDriverTag.title`, `Driver`)} value={assignedDriverName} icon={<SvgDriver />} autoClose />
    </>
  );
};

export default AssignedDriverTag;
