import React, { Suspense, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import { ApiContext } from '@api/api';
import { CompanyLocationRampWithOpeningHoursOutDTO, ReservationsDataOutDTO } from '@api/logsteo-api.v2';
import styled from 'styled-components';
import { dumpVars, isNotNullOrUndefined, isNullOrUndefined, useLocalStorage } from '@utils/utils';
import dayjs from 'dayjs';
import { DatesSetArg, EventContentArg } from '@fullcalendar/core';
import { mapToAPIDateTime, shortDateFormat } from '@utils/date';
import useTranslationLgs from '../../../hooks/i18n/useTranslation';
import csLocale from '@fullcalendar/core/locales/cs';
import enLocale from '@fullcalendar/core/locales/en-gb';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { RingilH3 } from '@components/styles';
import useNavigation from '@hooks/useNavigation';
import { useTranslation } from 'react-i18next';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { useYardTable } from '@components/obsolete/YardTable/YardTable.tsx';
import { useCreateTimeslotSidebar } from '@components/ringil3/Features/Reservation/Sidedbars/CreateTimeslotSidebar.tsx';
import { Link } from 'react-router-dom';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import InputCompanyLocation from '@components/obsolete/Form/InputCompanyLocation/InputCompanyLocation.tsx';
import InputYesNoPlain from '@components/obsolete/Form/InputYesNoPlain/InputYesNoPlain.tsx';
import InputCompanyRamps from '@components/obsolete/Form/InputCompanyRamps/InputCompanyRamps.tsx';
import RenderFragment from '@app/pages/timeslots/fragments/RenderFragment.tsx';

interface ComponentProps {}

const defaultCardRendersData = ['CFRWorkflowIcon', 'CFRName', 'CFRCarrierInfo', 'CFRSlotDuration', 'CFRCargoItem', 'CFRLabels', 'CFRCreated', 'CFRUpdated'];

export const TimeslotCalendar: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();
  const { i18n } = useTranslation();
  const { loggedUser } = useContext(AuthenticatedUserContext);

  const navigation = useNavigation();

  const { getRampsForCompanyLocation, getReservationsForDayAndLocation, listCustomDataByType } = useContext(ApiContext);

  const [selectedLocationId, setSelectedLocationId] = useLocalStorage('TIMESLOT_SELECTED_LOCATIONID', undefined);
  const [ramps, setRamps] = useLocalStorage<CompanyLocationRampWithOpeningHoursOutDTO[]>('TIMESLOT_RAMPS', []);
  const [events, setEvents] = useState([]);
  const [currentDate, setCurrentDate] = useLocalStorage<string>('TIMESLOT_SELECTED_DATE', mapToAPIDateTime(dayjs()));
  const [selectedRamps, setSelectedRamps] = useLocalStorage<string[]>('TIMESLOT_SELECTED_RAMPS', []);
  const [showLargeView, setShowLargeView] = useLocalStorage('TIMESLOT_CALENDAR_SHOW_LARGE_VIEW', false);

  const { refresh, hasTableData, YardTable } = useYardTable(selectedLocationId);
  const [defaultCardFragments, setDefaultCardFragments] = useState(defaultCardRendersData);

  const { CreateTimeslotSidebar, createTimeslotRequest, setCreateTimeslotRequest } = useCreateTimeslotSidebar();

  useLayoutEffect(() => {
    listCustomDataByType('CALENDAR_CARD_FRAGMENTS', d => {
      if (d.length > 0) {
        const data = d[0].payload;
        setDefaultCardFragments(data.split(',').map((t: string) => t.trim()));
      }
    });
  }, []);

  useEffect(() => {
    load();
  }, [selectedLocationId, selectedRamps]);

  function renderEventContent(eventInfo: EventContentArg) {
    const reservation = eventInfo.event.extendedProps as ReservationsDataOutDTO;
    console.log('renderEventContent', reservation);

    return (
      /*<Link href={`/timeslots/${reservation.reservationId}`}>*/
      <Link to={navigation.createNavigationLink(navigation.urlFunctions.createReservationDetail(reservation.reservationId))} className={'text-white'}>
        <EntryInner>
          {defaultCardFragments.map(t => {
            return <RenderFragment key={t} fragment={t} reservation={reservation} />;
          })}
          {/* <CFRCarrierInfo reservation={reservation} />
          <CFRSlotDuration reservation={reservation} />
          <CFRCargoItem reservation={reservation} />
          <CFRLabels reservation={reservation} />
          <CFRCreated reservation={reservation} />
          <CFRUpdated reservation={reservation} />*/}
        </EntryInner>
      </Link>
    );
  }

  const load = () => {
    if (!isNullOrUndefined(selectedLocationId)) {
      getRampsForCompanyLocation(selectedLocationId, true, d => {
        setRamps(d);
        getReservationsForDayAndLocation(selectedLocationId, currentDate, selectedRamps, res => {
          const merged = res
            .map(t => [
              t.reservations.map(t2 => ({
                ...t2,
                resourceId: t.rampId,
                start: t2.timeslot.since,
                end: t2.timeslot.till,
                id: t2.reservationId,
                title: `${t2.reservationId}`,
                color: t2.labels?.length === 1 ? t2.labels[0].labelColor : '',
                labels: t2.labels,
                rampId: t.rampId,
              })),
            ])
            .flat(1)
            .flat(1);
          setEvents(merged);
        });
      });
    }
  };

  useEffect(() => {
    getReservationsForDayAndLocation(selectedLocationId, currentDate, [], res => {
      const merged = res
        .map(t => [
          t.reservations.map(t2 => ({
            ...t2,
            resourceId: t.rampId,
            start: t2.timeslot.since,
            end: t2.timeslot.till,
            id: t2.reservationId,
            title: `${t2.reservationId}`,
            color: t2.labels?.length === 1 ? t2.labels[0].labelColor : '',
            rampId: t.rampId,
          })),
        ])
        .flat(1)
        .flat(1);

      setEvents(merged);
    });
  }, [currentDate]);

  const changeDate = (date: DatesSetArg) => {
    setCurrentDate(date.startStr);
  };
  const changeDateCal = (date: string) => {
    // @ts-ignore
    calendarRef.current.calendar.gotoDate(date);
  };

  const calendarRef = useRef();

  const reducedCounts = events
    .map((t: ReservationsDataOutDTO) => t.orders)
    .flat(1)
    .map(t => t.items)
    .flat(1)
    .reduce((acc, t) => {
      if (isNullOrUndefined(acc[t.cargoTemplate.name])) {
        acc[t.cargoTemplate.name] = t.amount;
      } else {
        acc[t.cargoTemplate.name] += t.amount;
      }
      return acc;
    }, {} as { [key: string]: number });

  return (
    <>
      <RowSpaceBetween>
        <NormalH1>{tr(`calendar.calendar`, `Calendar`)}</NormalH1>
        <div>
          <Button
            label={tr(`calendar.reservation`, `Reservation`)}
            icon={'pi pi-plus'}
            onClick={e => {
              /*router.push('/timeslots/create-reservation');*/
              /*navigation.navigate(navigation.urlFunctions.createReservation());*/
              setCreateTimeslotRequest({
                applicationId: null,
                companyLocationId: selectedLocationId,
                preferredDate: currentDate,
                companyLocationCustomerId: loggedUser.companyId,
                expeditionLocationId: null,
                expeditionId: null,
              });
            }}
          />
        </div>
      </RowSpaceBetween>
      <YardTable></YardTable>
      <Row>
        <div>
          <HorizontalFieldValue
            label={tr(`calendar.location`, `Location`)}
            value={
              <>
                <InputCompanyLocation
                  value={selectedLocationId}
                  onChange={v => {
                    setSelectedLocationId(v.id);
                    setSelectedRamps([]);
                  }}
                  required={false}
                  showOnlyWithRamp={true}
                />
              </>
            }
          />
        </div>
        <div>
          <HorizontalFieldValue
            label={tr(`calendar.ramps`, `Ramps`)}
            value={
              <>
                <InputCompanyRamps value={selectedRamps} showTemporaryClosed={true} onChange={v => setSelectedRamps(v)} locationId={selectedLocationId} />
              </>
            }
          />
        </div>
        <div>
          <HorizontalFieldValue
            label={tr(`calendar.selectedDate`, `Selected date`)}
            value={
              <>
                <Calendar
                  locale={'cs'}
                  className="p-mb-1"
                  value={dayjs(currentDate).toDate()}
                  showIcon
                  dateFormat={shortDateFormat}
                  onChange={e => changeDateCal(dayjs(e.value as Date).format())}
                />
              </>
            }
          />
        </div>
        <div>
          <HorizontalFieldValue
            label={tr(`calendar.largeView`, `Large view`)}
            value={
              <>
                <InputYesNoPlain value={showLargeView} onChange={v => setShowLargeView(v)} />
              </>
            }
          />
        </div>
      </Row>
      <RingilH3>
        {tr(`Calendar.celkemNaplanovano`, `Celkem naplanovano:`)}{' '}
        {Object.keys(reducedCounts)
          .map(t => `${reducedCounts[t]}x ${t}`)
          .join(', ')}
        {Object.keys(reducedCounts).length == 0 && <>{tr(`Calendar.zatimNic`, `Zatim nic`)}</>}
      </RingilH3>
      {selectedLocationId && (
        <>
          <CalendarInner id="calendar" hourCellHeight={showLargeView ? '150px' : '40px'}>
            <FullCalendar
              ref={calendarRef}
              plugins={[resourceTimeGridPlugin, interactionPlugin]}
              schedulerLicenseKey="0801397464-fcs-1643209021"
              eventContent={renderEventContent}
              initialView={'resourceTimeGrid'}
              locale={i18n.language === 'cs' ? csLocale : enLocale}
              contentHeight={800}
              nowIndicator={true}
              initialDate={currentDate}
              datesSet={changeDate}
              resourceOrder={'title'}
              allDaySlot={false}
              resources={ramps.filter(t => selectedRamps.length == 0 || selectedRamps.indexOf(t.rampId) >= 0).map(t => ({ id: t.rampId, title: t.name }))}
              events={events}
            />
          </CalendarInner>
          {createTimeslotRequest && (
            <CreateTimeslotSidebar
              onContinue={reservation => {
                load();
              }}
              headerDisabled={false}
            />
          )}
        </>
      )}
    </>
  );
};
const RowSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

const EntryInner = styled.div`
  display: flex;
  margin: 0.2rem 0.2rem;
  flex-direction: column;
`;

const NormalH1 = styled.h1`
  padding: 0;
  margin: auto 25px auto 0;
`;

const CalendarInner = styled.div<{ hourCellHeight: string }>`
    height: 100%;
    width: 100%;

    .fc-timegrid-slot {
        height: ${props => props.hourCellHeight} !important;
    }

    .fc-timegrid-event {
        overflow: hidden;
    }

    .fc-timegrid-event-harness-inset:hover {
        z-index: 100 !important;
    }

    .fc-timegrid-event:hover {
        overflow: visible;
        left: auto;
        right: auto;
        top: auto;
        bottom: auto;
        z-index: 100;

        * {
            overflow: visible;
            z-index: 100;
        }
`;

const Parties = styled.div`
  display: flex;
  font-size: medium;
  font-weight: bold;
`;

const Row = styled.div<{ justifyContent?: string }>`
  display: flex;
  margin-top: 15px;
  justify-content: ${props => props.justifyContent || 'flex-start'};
`;
