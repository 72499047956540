// Generouted, changes to this file will be overriden
/* eslint-disable */

import { components, hooks, utils } from '@generouted/react-router/client';

export type Path =
  | `/:lang?`
  | `/:lang?/accounting`
  | `/:lang?/additional-terms`
  | `/:lang?/admin`
  | `/:lang?/admin/:adminCompanyId`
  | `/:lang?/admin/:adminCompanyId/inheriters`
  | `/:lang?/admin/:adminCompanyId/modules`
  | `/:lang?/admin/:adminCompanyId/settings`
  | `/:lang?/admin/:adminCompanyId/templating`
  | `/:lang?/admin/:adminCompanyId/templating/:templateId`
  | `/:lang?/admin/carriers`
  | `/:lang?/admin/companies`
  | `/:lang?/admin/company`
  | `/:lang?/admin/company/:companyId`
  | `/:lang?/admin/components`
  | `/:lang?/admin/components2`
  | `/:lang?/admin/email-monitoring`
  | `/:lang?/admin/email-monitoring2`
  | `/:lang?/admin/find-email-request`
  | `/:lang?/admin/find-resolved-emails`
  | `/:lang?/admin/i18n`
  | `/:lang?/admin/import-companies`
  | `/:lang?/admin/import/company-locations`
  | `/:lang?/admin/import/company-with-users`
  | `/:lang?/admin/import/users`
  | `/:lang?/admin/module-settings/timeslot/:companyId`
  | `/:lang?/admin/requests`
  | `/:lang?/admin/requests/:requestId`
  | `/:lang?/admin/security/roles`
  | `/:lang?/admin/templating`
  | `/:lang?/admin/templating/:templateId`
  | `/:lang?/admin/users`
  | `/:lang?/auth`
  | `/:lang?/carrier`
  | `/:lang?/carrier/demand/:id`
  | `/:lang?/carrier/demands`
  | `/:lang?/carrier/multi-demands`
  | `/:lang?/carrier/multi-demands/:id`
  | `/:lang?/carrier/quick-actions/demand/:id`
  | `/:lang?/carrier/shipment/:id`
  | `/:lang?/carrier/shipments`
  | `/:lang?/cmr/:expeditionId`
  | `/:lang?/company-notifications`
  | `/:lang?/company-profile`
  | `/:lang?/customer/admin/reports`
  | `/:lang?/customer/carrier-catalog`
  | `/:lang?/customer/carrier-group/:id`
  | `/:lang?/customer/carrier-groups`
  | `/:lang?/customer/carrier/:id`
  | `/:lang?/customer/carriers`
  | `/:lang?/customer/exceptions`
  | `/:lang?/customer/expedition`
  | `/:lang?/customer/expedition/:id`
  | `/:lang?/customer/expedition/duplicate/:id`
  | `/:lang?/customer/expeditions`
  | `/:lang?/customer/locations`
  | `/:lang?/customer/locations/:id`
  | `/:lang?/customer/partner/:id`
  | `/:lang?/customer/partners`
  | `/:lang?/customer/process-supplier-notification`
  | `/:lang?/customer/route`
  | `/:lang?/customer/route/:id`
  | `/:lang?/customer/routes`
  | `/:lang?/expedition/:expeditionId`
  | `/:lang?/expedition/create`
  | `/:lang?/inherited-companies`
  | `/:lang?/location/location-panel/:locationId`
  | `/:lang?/multi-demands`
  | `/:lang?/multi-demands/:multiDemandGroupId`
  | `/:lang?/my-profile`
  | `/:lang?/notifications`
  | `/:lang?/reports/:reportId`
  | `/:lang?/settings`
  | `/:lang?/shipments`
  | `/:lang?/shipments/:shipmentId`
  | `/:lang?/shipments/create-shipment`
  | `/:lang?/supplier-notifications`
  | `/:lang?/supplier-notifications/:supplierNotificationId`
  | `/:lang?/supplier-notifications/create-supplier-notification`
  | `/:lang?/supplier-notifications/create-supplier-notification-dap`
  | `/:lang?/supplier-notifications/duplicate-supplier-notification`
  | `/:lang?/supplier-notifications/process-shipment`
  | `/:lang?/test/calendar`
  | `/:lang?/test/console`
  | `/:lang?/test/dialog`
  | `/:lang?/test/error`
  | `/:lang?/test/gatepoc`
  | `/:lang?/test/map`
  | `/:lang?/test/menu`
  | `/:lang?/test/newCalendar`
  | `/:lang?/test/orders`
  | `/:lang?/test/rampcalendar`
  | `/:lang?/test/table`
  | `/:lang?/test/table2`
  | `/:lang?/test/upload`
  | `/:lang?/timeslots/:reservationId`
  | `/:lang?/timeslots/calendar`
  | `/:lang?/timeslots/cams`
  | `/:lang?/timeslots/cams-settings`
  | `/:lang?/timeslots/reservations`
  | `/:lang?/timeslots/visitors`;

export type Params = {
  '/:lang?': { lang?: string };
  '/:lang?/accounting': { lang?: string };
  '/:lang?/additional-terms': { lang?: string };
  '/:lang?/admin': { lang?: string };
  '/:lang?/admin/:adminCompanyId': { lang?: string; adminCompanyId: string };
  '/:lang?/admin/:adminCompanyId/inheriters': { lang?: string; adminCompanyId: string };
  '/:lang?/admin/:adminCompanyId/modules': { lang?: string; adminCompanyId: string };
  '/:lang?/admin/:adminCompanyId/settings': { lang?: string; adminCompanyId: string };
  '/:lang?/admin/:adminCompanyId/templating': { lang?: string; adminCompanyId: string };
  '/:lang?/admin/:adminCompanyId/templating/:templateId': { lang?: string; adminCompanyId: string; templateId: string };
  '/:lang?/admin/carriers': { lang?: string };
  '/:lang?/admin/companies': { lang?: string };
  '/:lang?/admin/company': { lang?: string };
  '/:lang?/admin/company/:companyId': { lang?: string; companyId: string };
  '/:lang?/admin/components': { lang?: string };
  '/:lang?/admin/components2': { lang?: string };
  '/:lang?/admin/email-monitoring': { lang?: string };
  '/:lang?/admin/email-monitoring2': { lang?: string };
  '/:lang?/admin/find-email-request': { lang?: string };
  '/:lang?/admin/find-resolved-emails': { lang?: string };
  '/:lang?/admin/i18n': { lang?: string };
  '/:lang?/admin/import-companies': { lang?: string };
  '/:lang?/admin/import/company-locations': { lang?: string };
  '/:lang?/admin/import/company-with-users': { lang?: string };
  '/:lang?/admin/import/users': { lang?: string };
  '/:lang?/admin/module-settings/timeslot/:companyId': { lang?: string; companyId: string };
  '/:lang?/admin/requests': { lang?: string };
  '/:lang?/admin/requests/:requestId': { lang?: string; requestId: string };
  '/:lang?/admin/security/roles': { lang?: string };
  '/:lang?/admin/templating': { lang?: string };
  '/:lang?/admin/templating/:templateId': { lang?: string; templateId: string };
  '/:lang?/admin/users': { lang?: string };
  '/:lang?/auth': { lang?: string };
  '/:lang?/carrier': { lang?: string };
  '/:lang?/carrier/demand/:id': { lang?: string; id: string };
  '/:lang?/carrier/demands': { lang?: string };
  '/:lang?/carrier/multi-demands': { lang?: string };
  '/:lang?/carrier/multi-demands/:id': { lang?: string; id: string };
  '/:lang?/carrier/quick-actions/demand/:id': { lang?: string; id: string };
  '/:lang?/carrier/shipment/:id': { lang?: string; id: string };
  '/:lang?/carrier/shipments': { lang?: string };
  '/:lang?/cmr/:expeditionId': { lang?: string; expeditionId: string };
  '/:lang?/company-notifications': { lang?: string };
  '/:lang?/company-profile': { lang?: string };
  '/:lang?/customer/admin/reports': { lang?: string };
  '/:lang?/customer/carrier-catalog': { lang?: string };
  '/:lang?/customer/carrier-group/:id': { lang?: string; id: string };
  '/:lang?/customer/carrier-groups': { lang?: string };
  '/:lang?/customer/carrier/:id': { lang?: string; id: string };
  '/:lang?/customer/carriers': { lang?: string };
  '/:lang?/customer/exceptions': { lang?: string };
  '/:lang?/customer/expedition': { lang?: string };
  '/:lang?/customer/expedition/:id': { lang?: string; id: string };
  '/:lang?/customer/expedition/duplicate/:id': { lang?: string; id: string };
  '/:lang?/customer/expeditions': { lang?: string };
  '/:lang?/customer/locations': { lang?: string };
  '/:lang?/customer/locations/:id': { lang?: string; id: string };
  '/:lang?/customer/partner/:id': { lang?: string; id: string };
  '/:lang?/customer/partners': { lang?: string };
  '/:lang?/customer/process-supplier-notification': { lang?: string };
  '/:lang?/customer/route': { lang?: string };
  '/:lang?/customer/route/:id': { lang?: string; id: string };
  '/:lang?/customer/routes': { lang?: string };
  '/:lang?/expedition/:expeditionId': { lang?: string; expeditionId: string };
  '/:lang?/expedition/create': { lang?: string };
  '/:lang?/inherited-companies': { lang?: string };
  '/:lang?/location/location-panel/:locationId': { lang?: string; locationId: string };
  '/:lang?/multi-demands': { lang?: string };
  '/:lang?/multi-demands/:multiDemandGroupId': { lang?: string; multiDemandGroupId: string };
  '/:lang?/my-profile': { lang?: string };
  '/:lang?/notifications': { lang?: string };
  '/:lang?/reports/:reportId': { lang?: string; reportId: string };
  '/:lang?/settings': { lang?: string };
  '/:lang?/shipments': { lang?: string };
  '/:lang?/shipments/:shipmentId': { lang?: string; shipmentId: string };
  '/:lang?/shipments/create-shipment': { lang?: string };
  '/:lang?/supplier-notifications': { lang?: string };
  '/:lang?/supplier-notifications/:supplierNotificationId': { lang?: string; supplierNotificationId: string };
  '/:lang?/supplier-notifications/create-supplier-notification': { lang?: string };
  '/:lang?/supplier-notifications/create-supplier-notification-dap': { lang?: string };
  '/:lang?/supplier-notifications/duplicate-supplier-notification': { lang?: string };
  '/:lang?/supplier-notifications/process-shipment': { lang?: string };
  '/:lang?/test/calendar': { lang?: string };
  '/:lang?/test/console': { lang?: string };
  '/:lang?/test/dialog': { lang?: string };
  '/:lang?/test/error': { lang?: string };
  '/:lang?/test/gatepoc': { lang?: string };
  '/:lang?/test/map': { lang?: string };
  '/:lang?/test/menu': { lang?: string };
  '/:lang?/test/newCalendar': { lang?: string };
  '/:lang?/test/orders': { lang?: string };
  '/:lang?/test/rampcalendar': { lang?: string };
  '/:lang?/test/table': { lang?: string };
  '/:lang?/test/table2': { lang?: string };
  '/:lang?/test/upload': { lang?: string };
  '/:lang?/timeslots/:reservationId': { lang?: string; reservationId: string };
  '/:lang?/timeslots/calendar': { lang?: string };
  '/:lang?/timeslots/cams': { lang?: string };
  '/:lang?/timeslots/cams-settings': { lang?: string };
  '/:lang?/timeslots/reservations': { lang?: string };
  '/:lang?/timeslots/visitors': { lang?: string };
};

export type ModalPath = never;

export const { Link, Navigate } = components<Path, Params>();
export const { useModals, useNavigate, useParams } = hooks<Path, Params, ModalPath>();
export const { redirect } = utils<Path, Params>();
