import React, { useContext, useState } from 'react';
import SupplierNotificationOrdersForm, { mapOders, SupplierNotificationOrdersData } from '../forms/SupplierNotificationOrdersForm';
import useForm from '@hooks/useForm/useForm';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import useBEValidation from '@hooks/useBEValidation/useBEValidation';
import { SNConfiguration } from '@api/logsteo-api.v2';
import { RingilSidebar } from '@components/obsolete/RingilSidebar/RingilSidebar.tsx';
import * as yup from 'yup';

interface ComponentProps {
  initialData?: SupplierNotificationOrdersData;
  supplierNotificationId: string;
  companyId: string;
  supplierId: string;
  readonly configuration: SNConfiguration;
}

export const useCreateEditSupplierNotificationOrdersSidebar = (onComplete: () => void) => {
  const [visible, setVisible] = useState(false);
  const { BackendValidationComponent, setBeValidationErrors, clear } = useBEValidation();

  const EditSupplierNotificationOrdersSidebar: React.FC<ComponentProps> = ({ initialData, supplierNotificationId, companyId, supplierId, configuration }) => {
    const { updateNotificationSupplierUpdateDataForCargo } = useContext(ApiContext);

    const form = useForm<SupplierNotificationOrdersData>(
      yup.object().shape({}),
      initialData,
      v => {
        updateNotificationSupplierUpdateDataForCargo(
          supplierNotificationId,
          { orders: form.values.orders.map(mapOders) },
          () => {
            setVisible(false);
            onComplete();
          },
          null,
          { onValidationFailed: setBeValidationErrors },
        );
      },
      true,
      true,
    );

    const { tr } = useTranslationLgs();

    return (
      <RingilSidebar
        visible={visible}
        headerText={tr(`EditSupplierNotificationOrdersSidebar.updateSupplierNotification`, `Update supplier notification`)}
        onHide={() => {
          setVisible(false);
          clear();
        }}
        onSave={() => {
          form.validateAndSend();
          clear();
        }}
      >
        <BackendValidationComponent />
        <SupplierNotificationOrdersForm form={form} companyId={companyId} inSidebar={true} supplierId={supplierId} configuration={configuration} />
      </RingilSidebar>
    );
  };

  return { EditSupplierNotificationOrdersSidebar, setVisible };
};
