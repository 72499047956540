import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ContactPerson } from '@api/logsteo-api.v2.tsx';
import { Dropdown } from 'primereact/dropdown';

interface ComponentProps {
  contacts: ContactPerson[];
  value: ContactPerson;
  onChange: (value: ContactPerson) => void;
  fullWidth?: boolean;
}

const InputContact: React.FC<ComponentProps> = ({ value, contacts, onChange, fullWidth = false }) => {
  const { tr } = useTranslationLgs();
  return (
    <div className={`flex flex-col gap-1 ${fullWidth ? 'grow' : ''} `}>
      <Dropdown options={contacts} value={value} optionLabel="contactName" onChange={e => onChange(e.value)} />
      <div className={'text-content-secondary text-[10px]'}>{[value?.contactEmail, value?.contactPhone].filter(t => t != null).join(', ')}</div>
    </div>
  );
};

export default InputContact;
