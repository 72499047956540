import React, { useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import { CameraTypeEnum, LoVCompanyCamerasResponseOutDTO } from '@api/logsteo-api.v2.tsx';
import { Dropdown } from 'primereact/dropdown';

interface ComponentProps {
  companyLocationId: string;
  cameraId: string;
  onChange: (cameraId: string) => void;
}

const InputCamera: React.FC<ComponentProps> = ({ companyLocationId, cameraId, onChange }) => {
  const { loVCompanyCamerasByLocationId } = useContext(ApiContext);
  const [data, setData] = useState<LoVCompanyCamerasResponseOutDTO[]>();
  const { tr } = useTranslationLgs();

  useEffect(() => {
    const ac = new AbortController();

    loVCompanyCamerasByLocationId(companyLocationId, d => {
      setData(d);
    });

    return () => {
      ac.abort();
    };
  }, [companyLocationId]);
  return (
    <>
      <Dropdown
        value={cameraId}
        onChange={e => onChange(e.value)}
        options={data?.filter(t => t.cameraType === CameraTypeEnum.ENTRY)}
        optionLabel="cameraName"
        optionValue="cameraId"
        placeholder={tr('InputCamera.selectCamera', 'Vyberte kameru')}
      />
    </>
  );
};

export default InputCamera;
