import * as yup from 'yup';

export const createOrdersComponentValidationSchema = (config: OrdersConfig, tr: any) => {
  const visibilitySchema = (visibility: 'always' | 'optional' | 'never', required: boolean, schema: any) => {
    if (visibility === 'never') {
      return yup.mixed().nullable(true); // Pole nebude validováno, protože není zobrazeno
    } else if (visibility === 'optional') {
      return required === true ? schema.required('This field is required') : schema.nullable(true).notRequired(); // Explicitní kontrola na true
    }
    return required === true ? schema.required('This field is required') : schema.nullable(true).notRequired(); // Explicitní kontrola na true pro always
  };

  return yup.object().shape({
    orders: yup.array().of(
      yup.object().shape({
        orderName: visibilitySchema(config.orderName.visibility, config.orderName.required, yup.string()),
        items: yup
          .array()
          .min(config.cargo.visibility === 'always' ? 1 : 0, tr('CreateOrdersComponentValidationSchema.oneItemMinimum', 'Zadejte minimálně jednu položku'))
          .of(
            yup.object().shape({
              amount: visibilitySchema(config.cargo.visibility, config.cargo.required, yup.string()),
              cargoTemplateCode: visibilitySchema(config.cargo.visibility, config.cargo.required, yup.string()),
              width: visibilitySchema(
                config.cargoDimensions.visibility,
                config.cargo.visibility !== 'never' && config.cargoDimensions.items.width.required,
                yup.number(),
              ),
              height: visibilitySchema(
                config.cargoDimensions.visibility,
                config.cargo.visibility !== 'never' && config.cargoDimensions.items.height.required,
                yup.number(),
              ),
              length: visibilitySchema(
                config.cargoDimensions.visibility,
                config.cargo.visibility !== 'never' && config.cargoDimensions.items.length.required,
                yup.number(),
              ),
              weight: visibilitySchema(
                config.cargoDimensions.visibility,
                config.cargo.visibility !== 'never' && config.cargoDimensions.items.weight.required,
                yup.number(),
              ),
            }),
          ),
        orderAmount: visibilitySchema(config.cargoDetails.visibility, config.cargoDetails.items.orderAmount.required, yup.number().nullable()),
        orderDescription: visibilitySchema(config.cargoDetails.visibility, config.cargoDetails.items.orderDescription.required, yup.string()),
        //orderValue: visibilitySchema(config.cargoDetails.visibility, config.cargoDetails.items.orderValue.required, yup.number()),
        // RD-446
        /*orderValue: visibilitySchema(
          config.cargoDetails.visibility,
          config.cargoDetails.items.orderValue.required,
          yup.object().shape({
            currency: yup.string().required(),
            price: yup.string().required(),
          }),
        ),*/
      }),
    ),
  });
};

export const expeditionConfig: OrdersConfig = {
  orderName: { visibility: 'always', required: true },
  cargo: { visibility: 'always', required: true },
  cargoDimensions: {
    visibility: 'always',
    required: true,
    items: {
      width: { required: true },
      height: { required: true },
      length: { required: true },
      weight: { required: true },
    },
  },
  cargoDetails: {
    visibility: 'always',
    required: true,
    items: { orderAmount: { required: true }, orderDescription: { required: true }, orderValue: { required: true } },
  },
  totalWeight: {
    render: true,
  },
  unloadingLocation: {
    render: false,
  },
};

export const fullRequiredConfig: OrdersConfig = {
  orderName: { visibility: 'always', required: true },
  cargo: { visibility: 'always', required: true },
  cargoDimensions: {
    visibility: 'always',
    required: true,
    items: {
      width: { required: true },
      height: { required: true },
      length: { required: true },
      weight: { required: true },
    },
  },
  cargoDetails: {
    visibility: 'always',
    required: true,
    items: { orderAmount: { required: true }, orderDescription: { required: true }, orderValue: { required: true } },
  },
  totalWeight: {
    render: true,
  },
  unloadingLocation: {
    render: true,
  },
};

export interface OrdersConfig {
  orderName: { visibility: Visibility; required: boolean };
  // jedna se o manipulacni jednotku a pocet
  cargo: { visibility: Visibility; required: boolean };
  // jedna se o sirku, vysku, delku a vahu
  cargoDimensions: {
    visibility: Visibility;
    required: boolean;
    items: {
      width: { required: boolean };
      height: { required: boolean };
      length: { required: boolean };
      weight: { required: boolean };
    };
  };
  cargoDetails: {
    visibility: Visibility;
    required: boolean;
    items: {
      orderAmount: { required: boolean };
      orderDescription: { required: boolean };
      orderValue: { required: boolean };
    };
  };
  totalWeight: {
    render: boolean;
  };
  unloadingLocation: {
    render: boolean;
  };
}

export type Visibility = 'always' | 'never' | 'optional';
