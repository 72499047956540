import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Dropdown } from 'primereact/dropdown';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { isNotNullOrUndefined } from '@utils/utils.tsx';

interface ComponentProps {
  value: string;
  onChange: (localDate: string) => void;
  timeZone?: string;
  onChangeTimeZone?: (timeZone: string) => void;
  fullWidth?: boolean;
}

const InputDateAndTime: React.FC<ComponentProps> = ({ value, onChange, timeZone, onChangeTimeZone, fullWidth = false }) => {
  const { getAvailableTimezones } = useContext(ApiContext);
  const [timezones, setTimezones] = useState<string[]>([]);
  const { tr } = useTranslationLgs();

  useEffect(() => {
    getAvailableTimezones(d => {
      setTimezones(d);
    });
  }, []);

  return (
    <div className={fullWidth ? 'flex-grow' : ''}>
      <div className={'grid grid-cols-2 min-h-[40px]'}>
        <input
          style={{ width: '100%', padding: '0.5rem', color: 'var(--ringil3-gr4)' }}
          type="datetime-local"
          onChange={e => {
            onChange(e.target.value);
          }}
          value={isNotNullOrUndefined(value) ? value : ''}
        />
        <Dropdown
          value={timeZone}
          options={timezones}
          filter={true}
          onChange={e => onChangeTimeZone(e.value)}
          placeholder={tr(`InputDateAndTime.selectATimezone`, `Select a timezone`)}
        />
      </div>
    </div>
  );
};

export default InputDateAndTime;
