import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { Calendar } from 'primereact/calendar';
import { findNextDay, shortDateFormat } from '@utils/date.tsx';
import { Button } from 'primereact/button';
import dayjs from 'dayjs';
import { BlueIcon, Clickable, RowWithSpaceBetween } from '@components/styles.tsx';
import { DayEnum } from '@api/logsteo-api.v2.tsx';
import InputNoteWithLink from '@components/ringil3/Input/InputNoteWithLink/InputNoteWithLink.tsx';
import LinkWithIcon from '@components/ringil3/Links/LinkWithIcon.tsx';
import SvgIconPlus from '@components/framework/orders/IconPlus.tsx';

interface ComponentProps {
  startLocalDate: string;
  computedLocalDates: string[];
  onChanged: (computedDates: string[]) => void;
}

const DuplicateDatesButtons: React.FC<ComponentProps> = ({ startLocalDate, onChanged, computedLocalDates }) => {
  const [customDate, setCustomDate] = useState(false);
  const { tr } = useTranslationLgs();
  const [selectedDate, setSelectedDate] = useState<Date>();

  const addNewPlusDay = (days: number) => {
    const last = computedLocalDates[computedLocalDates.length - 1] || startLocalDate;
    const newLocalDate = dayjs(last).add(days, 'day').format('YYYY-MM-DD');
    onChanged([...computedLocalDates, newLocalDate]);
  };

  const addNewNewDayInWeek = (dayInWeek: DayEnum) => {
    const lastLocalDate = computedLocalDates[computedLocalDates.length - 1];
    const l = dayjs(lastLocalDate);

    const next = findNextDay(l, dayInWeek);
    onChanged([...computedLocalDates, next.format('YYYY-MM-DD')]);
  };

  return (
    <>
      {customDate && (
        <div className={'flex flex-nowrap whitespace-nowrap gap-2'}>
          <Calendar
            dateFormat={shortDateFormat}
            style={{ width: '140px' }}
            locale={'cs'}
            showButtonBar={true}
            showIcon
            value={selectedDate}
            onChange={e => setSelectedDate(e.value as Date)}
          />
          <Button
            label={tr(`duplicate.save`, `Save`)}
            onClick={() => {
              onChanged([...computedLocalDates, dayjs(selectedDate).format('YYYY-MM-DD')]);
              setCustomDate(false);
              setSelectedDate(null);
            }}
          />
          <BlueIcon
            className={'pi pi-trash'}
            onClick={() => {
              setCustomDate(false);
            }}
          />
        </div>
      )}
      <>
        {!customDate && (
          <div>
            <RowWithSpaceBetween>
              <div className={'flex gap-2'}>
                <Clickable onClick={() => addNewPlusDay(1)}>{tr(`duplicate.1Den`, `1 den`)}</Clickable>
                <span>|</span>
                <Clickable onClick={() => addNewPlusDay(7)}>{tr(`duplicate.7Dnu`, `7 dnu`)}</Clickable>
                <span>|</span>
                <Clickable onClick={() => addNewPlusDay(14)}>{tr(`duplicate.14Dni`, `14 dni`)}</Clickable>
                <span>|</span>
                <Clickable onClick={() => addNewPlusDay(21)}>{tr(`duplicate.21Dnu`, `21 dnu`)}</Clickable>
                <span>|</span>
                <Clickable onClick={() => addNewPlusDay(30)}>{tr(`duplicate.30Dni`, `30 dni`)}</Clickable>
                <span>|</span>
                <LinkWithIcon label={tr(`duplicate.vlastni`, `Vlastni`)} onClick={() => setCustomDate(true)} icon={<SvgIconPlus />} />
              </div>
            </RowWithSpaceBetween>
            <RowWithSpaceBetween>
              <Clickable onClick={() => addNewNewDayInWeek(DayEnum.MONDAY)}>
                <div className={'whitespace-nowrap'}>{tr(`duplicate.nextMonday`, `Pristi pondeli`)}</div>
              </Clickable>
              <span>|</span>
              <Clickable onClick={() => addNewNewDayInWeek(DayEnum.TUESDAY)}>{tr(`duplicate.tuesday`, `Utery`)}</Clickable>
              <span>|</span>
              <Clickable onClick={() => addNewNewDayInWeek(DayEnum.WEDNESDAY)}>{tr(`duplicate.wednesday`, `Streda`)}</Clickable>
              <span>|</span>
              <Clickable onClick={() => addNewNewDayInWeek(DayEnum.THURSDAY)}>{tr(`duplicate.thursday`, `Ctvrtek`)}</Clickable>
              <span>|</span>
              <Clickable onClick={() => addNewNewDayInWeek(DayEnum.FRIDAY)}>{tr(`duplicate.friday`, `Patek`)}</Clickable>
              <span>|</span>
              <Clickable onClick={() => addNewNewDayInWeek(DayEnum.SATURDAY)}>{tr(`duplicate.saturday`, `Sobota`)}</Clickable>
              <span>|</span>
              <Clickable onClick={() => addNewNewDayInWeek(DayEnum.SUNDAY)}>{tr(`duplicate.sunday`, `Nedele`)}</Clickable>
            </RowWithSpaceBetween>
          </div>
        )}
      </>
    </>
  );
};

export default DuplicateDatesButtons;
