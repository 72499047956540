import React, { PropsWithChildren, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { isBlank } from '@utils/utils.tsx';
import LinkWithIcon from '@components/ringil3/Links/LinkWithIcon.tsx';
import SvgIconPlus from '@components/framework/orders/IconPlus.tsx';

interface ComponentProps {
  title: string;
}

const InputPlus: React.FC<PropsWithChildren<ComponentProps>> = ({ title, children }) => {
  const { tr } = useTranslationLgs();
  const [open, setOpen] = useState(false);

  return (
    <div>
      {!open && <LinkWithIcon label={title} icon={<SvgIconPlus />} onClick={() => setOpen(true)}></LinkWithIcon>}
      {open && <>{children}</>}
    </div>
  );
};

export default InputPlus;
