import React, { useContext, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import RingilSidebar from '@components/ringil3/Sidebar/RingilSidebar';
import { VerticalFieldAndValueWithIcon } from '@components/ringil3/Containers/VerticalFieldAndValueWithIcon';
import { VerticalFieldAndValue } from '@components/ringil3/Containers/VerticalFieldAndValue';
import InputSpotDirect from '@components/ringil3/Input/InputSpotDirect/InputSpotDirect';
import InputPriceWithCurrency from '@components/ringil3/Input/InputPriceWithCurrency/InputPriceWithCurrency';
import { CarrierGroupInDTO, DistributionInDTO, DistributionTypeEnum, LovDTO } from '@api/logsteo-api.v2';
import { InputCarriers } from '@components/ringil3/Input/InputCarriers/InputCarriers';
import InputDateAndTime from '@components/ringil3/Input/InputDateAndTime/InputDateAndTime';
import InputDelayedSend from '@components/ringil3/Input/InputDelayedSend/InputDelayedSend';
import { ApiContext } from '@api/api';
import useForm from '@hooks/useForm/useForm';
import * as yup from 'yup';
import { dumpVars, isNullOrUndefined } from '@utils/utils';
import { localDateTimeToApi } from '@utils/date';
import { findValidationMessage } from '@utils/validation';
import dayjs from 'dayjs';
import useBEValidation from '@hooks/useBEValidation/useBEValidation';
import { SelectCarriers } from '@components/ringil3/Input/InputCarriers/SelectCarriers';
import { ColWithGap } from '@components/styles';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';

interface ComponentProps {
  onContinue: () => void;
  customerId: string;
  applicationId: string;
}

export const useCreateRequestCarrier = () => {
  const [visible, setVisible] = useState(false);

  const RequestCarrier: React.FC<ComponentProps> = ({ onContinue, customerId, applicationId }) => {
    const { tr } = useTranslationLgs();
    const { loggedUser } = useContext(AuthenticatedUserContext);
    const { cuCreateDistributionForExpedition } = useContext(ApiContext);
    const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();

    if (!visible) return null;

    const schema = yup.object().shape({
      distributionType: yup.string().required(),
      selectedItems: yup.array().required().min(1),
      price: yup.mixed().test('required', tr('validation.required', 'Required'), (value, context) => {
        const formData = context.parent as Distribution;

        if (formData.distributionType === DistributionTypeEnum.FIXED_PRICE) {
          if (isNullOrUndefined(formData.price) || formData.price <= 0) {
            return false;
          }

          if (isNullOrUndefined(formData.currency) || formData.currency.length === 0) {
            return false;
          }
          return true;
        }
        return true;
      }),
      responseDeadline: yup.string().test('required', tr('validation.required', 'Required'), (value, context) => {
        const formData = context.parent as Distribution;
        const isValid = dayjs(value, "YYYY-MM-DD'T'HH:mm").isValid();
        return isValid;
      }),
      isPublished: yup.boolean().required(),
    });

    const form = useForm<Distribution>(
      schema,
      {
        price: 0,
        distributionType: DistributionTypeEnum.SPOT,
        isPublished: true,
        responseDeadline: dayjs().add(8, 'hours').format('YYYY-MM-DDTHH:mm'),
        responseDeadlineTimezone: 'Europe/Prague',
        selectedItems: [],
        currency: 'CZK',
      },
      d => onContinueHandler(d),
      false,
      false,
    );

    // @ts-ignore
    /*    const { isFieldRequired } = useIsFieldRequired<Distribution>(schema);*/

    const onContinueHandler = (distribution: Distribution) => {
      cuCreateDistributionForExpedition(
        customerId,
        applicationId,
        mapToDistributionInDTO(distribution),
        () => {
          setVisible(false);
          onContinue();
        },
        null,
        { onValidationFailed: setBeValidationErrors },
      );
    };

    return (
      <>
        <RingilSidebar
          visible={visible}
          onBack={() => setVisible(false)}
          onContinue={() => form.validateAndSend()}
          heading={tr(`RequestCarrier.requestCarrier`, `Poptat dopravce`)}
        >
          <BackendValidationComponent />
          <ColWithGap>
            <VerticalFieldAndValueWithIcon
              label={tr(`RequestCarrier.forExpedition`, `For expedition`)}
              valueText={applicationId}
              iconPath={'/images/icons/ringil3/forExpedition.svg'}
            />

            <VerticalFieldAndValue
              label={tr('RequestCarrier.distributionType', 'Distribution type')}
              labelMode={'normal'}
              required={true}
              helpText={tr('RequestCarrier.distributionTypeHelp', 'Distribution type help')}
            >
              <InputSpotDirect
                selectedItemCode={form.values.distributionType}
                onSelect={v => {
                  form.setFieldValue(form.names.distributionType, v);
                }}
              />
            </VerticalFieldAndValue>
            <VerticalFieldAndValue
              label={tr(`RequestCarrier.price`, `Price`)}
              labelMode={'normal'}
              required={form.values.distributionType === DistributionTypeEnum.FIXED_PRICE}
              errorMessage={findValidationMessage(form.validationErrors, form.names.price, tr)}
            >
              <InputPriceWithCurrency
                currency={form.values.currency}
                price={form.values.price}
                changeCurrency={v => {
                  form.setFieldValue(form.names.currency, v);
                }}
                changePrice={v => {
                  form.setFieldValue(form.names.price, v);
                }}
              />
            </VerticalFieldAndValue>
            <VerticalFieldAndValue
              label={tr(`RequestCarrier.whoDoYouWantToAddress`, `Who do you want to address?`)}
              labelMode={'normal'}
              required={true}
              errorMessage={findValidationMessage(form.validationErrors, form.names.selectedItems, tr)}
            >
              {/*<InputCarriers
              value={form.values.selectedItems?.map((t) => ({ id: t.id, name: '', active: true } as LovDTO))}
              isDisabled={false}
              placeholder={tr(`RequestCarrier.selectACarrierOrGroup`, `Select a carrier or group`)}
              onSelected={(v) => {
                form.setFieldValue(form.names.selectedItems, v);
              }}
            />*/}
              <SelectCarriers
                value={form.values.selectedItems}
                onlyCarriers={false}
                onSelected={value => {
                  form.setFieldValue(form.names.selectedItems, value);
                }}
                isDisabled={false}
                placeholder={tr(`RequestCarrier.selectACarrierOrGroup`, `Select a carrier or group`)}
              />
            </VerticalFieldAndValue>

            <VerticalFieldAndValue
              label={tr(`RequestCarrier.byWhenDoYouNeedAQuotation`, `By when do you need a quotation`)}
              labelMode={'normal'}
              required={true}
              errorMessage={findValidationMessage(form.validationErrors, 'responseDeadline', tr)}
            >
              <InputDateAndTime
                value={form.values.responseDeadline}
                onChange={v => {
                  form.setFieldValue(form.names.responseDeadline, v);
                }}
                timeZone={loggedUser.companyTimeZone}
                onChangeTimeZone={v => {}}
              />
            </VerticalFieldAndValue>
            {/*<VerticalFieldAndValue label={tr(`RequestCarrier.sendToCarriers`, `Send to carriers`)} labelMode={'normal'} required={true}>
              <InputDelayedSend
                isDelayedSend={form.values.isPublished}
                onChange={v => {
                  form.setFieldValue(form.names.isPublished, v);
                }}
              />
            </VerticalFieldAndValue>*/}
          </ColWithGap>
        </RingilSidebar>
      </>
    );
  };

  return { RequestCarrier, setVisible };
};

interface Distribution {
  distributionType: DistributionTypeEnum;
  price?: number;
  selectedItems: CarrierGroupInDTO[];
  responseDeadline: string;
  responseDeadlineTimezone: string;
  currency: string;
  isPublished: boolean;
}

const mapToDistributionInDTO = (distribution: Distribution): DistributionInDTO => {
  return {
    price: distribution.price,
    validTill: localDateTimeToApi(distribution.responseDeadline, distribution.responseDeadlineTimezone),
    selectAll: false,
    distributionType: distribution.distributionType,
    currency: distribution.currency,
    selectedItems: distribution.selectedItems,
    isPublish: distribution.isPublished,
  };
};
