import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import Badge from '@components/ringil3/Badges/Badge';
import { isNotNullOrUndefined } from '@utils/utils';

interface ComponentProps {
  loadingReference: string;
}

const LoadingReferenceBadge: React.FC<ComponentProps> = ({ loadingReference }) => {
  const { tr } = useTranslationLgs();
  return (
    <>
      {isNotNullOrUndefined(loadingReference) && (
        <div>
          <Badge
            filled={true}
            value={loadingReference}
            label={tr(`LoadingReferenceBadge.referenceProNakladku`, `Reference pro nakladku`)}
            highlightedValue={false}
            backgroundColor={'var(--ringil3-complementary-warning-medium)'}
            borderColor={'var(--ringil3-complementary-warning)'}
          ></Badge>
        </div>
      )}
    </>
  );
};

export default LoadingReferenceBadge;
