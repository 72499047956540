import React, { useContext, useState } from 'react';
import { ApiContext } from '@api/api';
import SupplierNotificationCargoInfoForm, { SupplierNotificationCargoInfoData } from '../forms/SupplierNotificationCargoInfoForm';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { SNConfiguration, TransportationTypeEnum } from '@api/logsteo-api.v2';
import useBEValidation from '@hooks/useBEValidation/useBEValidation';
import useForm from '@hooks/useForm/useForm.tsx';
import { RingilSidebar } from '@components/obsolete/RingilSidebar/RingilSidebar.tsx';
import * as yup from 'yup';

interface ComponentProps {
  initialData?: SupplierNotificationCargoInfoData;
  supplierNotificationId: string;
  companyId: string;
  transportationTypeCode: TransportationTypeEnum;
  readonly configuration: SNConfiguration;
}

export const useCreateEditSupplierNotificationCargoInfoSidebar = (onComplete: () => void) => {
  const [visible, setVisible] = useState(false);

  const EditSupplierNotificationCargoInfoSidebar: React.FC<ComponentProps> = ({
    initialData,
    supplierNotificationId,
    companyId,
    transportationTypeCode,
    configuration,
  }) => {
    const { updateNotificationSupplierUpdateDataForInfoCargo } = useContext(ApiContext);
    const { BackendValidationComponent, setBeValidationErrors, clear } = useBEValidation();

    const form = useForm<SupplierNotificationCargoInfoData>(
      yup.object().shape({}),
      initialData,
      v => {
        updateNotificationSupplierUpdateDataForInfoCargo(
          supplierNotificationId,
          {
            cargoType: form.values.cargoType,
            specialRequirementIds: form.values.specialRequirementIds,
            containerInfo: form.values.containerInfo,
            customsTypeCode: form.values.customsTypeCode,
            referralCode: form.values.referralCode,
            shipmentId: form.values.shipmentId,
            note: form.values.note,
          },
          () => {
            setVisible(false);
            onComplete();
          },
          null,
          { onValidationFailed: setBeValidationErrors },
        );
      },
      true,
      true,
    );

    const { tr } = useTranslationLgs();

    return (
      <RingilSidebar
        visible={visible}
        headerText={tr(`EditSupplierNotificationCargoInfoSidebar.updateSupplierNotification`, `Update supplier notification`)}
        onHide={() => setVisible(false)}
        onSave={() => form.validateAndSend()}
      >
        {form.values && (
          <>
            <BackendValidationComponent />
            <SupplierNotificationCargoInfoForm
              form={form}
              companyId={companyId}
              inSidebar={true}
              transportationTypeCode={transportationTypeCode}
              configuration={configuration}
            />
          </>
        )}
      </RingilSidebar>
    );
  };

  return { EditSupplierNotificationCargoInfoSidebar, setVisible };
};
