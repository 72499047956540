import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { formatPrice, isNotNullOrUndefined } from '@utils/utils.tsx';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import SvgMoney from '@components/ringil3/icons/Money.tsx';

interface ComponentProps {
  price?: number;
  currency?: string;
}

const RequestedPriceTag: React.FC<ComponentProps> = ({ price, currency }) => {
  const { tr } = useTranslationLgs();
  const [open, setOpen] = useState(false);

  return <>{isNotNullOrUndefined(price) && <ButtonTag label={tr(`RequestedPriceTag.title`, `Requested`)} value={formatPrice(price, currency)} icon={<SvgMoney />} />}</>;
};

export default RequestedPriceTag;
