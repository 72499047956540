import React, { useState } from 'react';
import RingilButton from '@components/framework/buttons/RingilButton.tsx';
import SvgTabularDeleteIcon from '@components/framework/icons/TabularDeleteIcon.tsx';
import RingilDialog from '@components/framework/dialog/RingilDialog.tsx';
import LinkWithIcon from '@components/ringil3/Links/LinkWithIcon.tsx';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';

interface ComponentProps {
  onClick: () => void;
  showConfirm?: boolean;
  disabled?: boolean;
}

const TabularDeleteButton: React.FC<ComponentProps> = ({ onClick, showConfirm, disabled }) => {
  const [visible, setVisible] = useState(false);
  const { tr } = useTranslationLgs();

  return (
    <>
      <RingilButton onClick={() => setVisible(true)} mode={'rect-small'} leftIcon={<SvgTabularDeleteIcon />} disabled={disabled}></RingilButton>
      <RingilDialog
        visible={visible}
        onHide={() => setVisible(false)}
        heading={'Opravdu chcete smazat tento řádek?'}
        buttons={[
          <RingilButton key={1} label={'Ano, smazat'} mode={'filled-big'} onClick={onClick} />,
          <LinkWithIcon
            key={2}
            label={tr('TabularDeleteButton.zrusit', 'Zrušit')}
            onClick={() => {
              setVisible(false);
            }}
          />,
        ]}
      ></RingilDialog>
    </>
  );
};

export default TabularDeleteButton;
