import React, { useContext, useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ApiContext } from '@api/api';
import { InheritedModuleOutDTO } from '@api/logsteo-api.v2';
import { RowWithSpaceBetween } from '@components/styles.tsx';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';

interface ComponentProps {
  customerId: string;
}

const AdminInheritedModules: React.FC<ComponentProps> = ({ customerId }) => {
  const [data, setData] = useState<InheritedModuleOutDTO[]>();
  const { getModulesInheritedForCompany } = useContext(ApiContext);
  const { tr } = useTranslationLgs();
  useEffect(() => {
    load();
  }, []);

  const load = () => {
    getModulesInheritedForCompany(customerId, data => {
      setData(data);
    });
  };

  return (
    <>
      <RowWithSpaceBetween>
        <h1>Inherited modules modules</h1>
      </RowWithSpaceBetween>
      {/*// @ts-ignore*/}
      <DataTable value={data} emptyMessage={tr(`global.noRecordFound`, `Nebyly nalezeny žádné záznamy`)}>
        <Column header={'Module type'} body={(row: InheritedModuleOutDTO) => <div>{row.moduleName}</div>} />
        <Column header={'Inherited from company'} body={(row: InheritedModuleOutDTO) => <div>{row.inheritedFromCompanyName}</div>} />
      </DataTable>
    </>
  );
};

export default AdminInheritedModules;
