import React, { useContext, useEffect, useRef, useState } from 'react';
import { ApiContext } from '@api/api';
import { CompanySecurityRolePreviewOutDTO } from '@api/logsteo-api.v2';
import { isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils';
import { DataTable } from 'primereact/datatable';

import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { HeaderWithButtonText, RowWithSpaceBetween } from '@components/styles.tsx';
import { Clickable } from '@components/ringil3/styles.tsx';
import { RingilDialog } from '@components/obsolete/RingilDialog/RingilDialog.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import InputPickPermissions from '@components/obsolete/Form/InputPickPermissions/InputPickPermissions.tsx';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';

interface ComponentProps {}

const SecurityRoles: React.FC<ComponentProps> = () => {
  const { adminGetSecurityRoles, adminCreateSecurityRole, adminUpdateSecurityRole } = useContext(ApiContext);
  const [data, setData] = useState<CompanySecurityRolePreviewOutDTO[]>();
  const [editable, setEditable] = useState<CompanySecurityRolePreviewOutDTO>();
  const abortControllerRef = useRef<AbortController | null>(null);
  const { tr } = useTranslationLgs();

  useEffect(() => {
    const ac = new AbortController();
    abortControllerRef.current = ac;

    load(abortControllerRef.current);

    return () => {
      abortControllerRef.current.abort();
    };
  }, []);

  const load = (ac: AbortController) => {
    adminGetSecurityRoles(d => {
      if (ac.signal.aborted) {
        return;
      }
      setData(d);
    });
  };

  const save = () => {
    if (isNotNullOrUndefined(editable)) {
      if (isNullOrUndefined(editable.id)) {
        adminCreateSecurityRole({ name: editable.name, permissionTypes: editable.permissionTypes }, () => {
          setEditable(null);
          load(abortControllerRef.current);
        });
      } else {
        adminUpdateSecurityRole(editable.id, { name: editable.name, permissionTypes: editable.permissionTypes }, () => {
          setEditable(null);
          load(abortControllerRef.current);
        });
      }
    }
  };

  if (isNullOrUndefined(data)) return <>Loading...</>;

  return (
    <>
      <RowWithSpaceBetween>
        <HeaderWithButtonText>Security roles</HeaderWithButtonText>
        <Button
          label="Add security role"
          iconPos="left"
          icon="icon pi pi-pencil"
          className="p-button-outlined"
          onClick={() =>
            setEditable({
              name: '',
              isGlobal: true,
              id: null,
              permissionTypes: [],
            })
          }
        ></Button>
      </RowWithSpaceBetween>

      {/*// @ts-ignore*/}
      <DataTable value={data} emptyMessage={tr(`global.noRecordFound`, `Nebyly nalezeny žádné záznamy`)}>
        <Column header="Name" field={'name'}></Column>
        <Column header="IsGlobal" body={(row: CompanySecurityRolePreviewOutDTO) => <>{row.isGlobal ? 'G' : 'C'}</>}></Column>
        <Column
          header="Permissions"
          body={(row: CompanySecurityRolePreviewOutDTO) => <Clickable onClick={() => setEditable(row)}>{row.permissionTypes.length}</Clickable>}
        ></Column>
      </DataTable>
      <RingilDialog visible={isNotNullOrUndefined(editable)} onHide={() => setEditable(null)} onSave={() => save()} headerText={'Create/Edit security role'} wide={true}>
        {isNotNullOrUndefined(editable) && (
          <>
            <HorizontalFieldValue
              label={'SecurityGroup name'}
              value={<InputText value={editable.name} onChange={e => setEditable({ ...editable, name: e.target.value })} />}
            />
            <HorizontalFieldValue
              label="Permissions"
              value={<InputPickPermissions value={editable.permissionTypes} onChange={v => setEditable({ ...editable, permissionTypes: v })} />}
            />
          </>
        )}
      </RingilDialog>
    </>
  );
};

export default SecurityRoles;
