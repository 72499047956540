import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Card } from 'primereact/card';
import { ButtonRow, Clickable, Col, RingilHeading } from '@components/styles';
import { AdminCompanyDetailOutDTO, ExternalIdsOutDTO, ModuleSettingsOutDTO, CompanyModuleEnum, UpdateCompanyDetailInDTO } from '@api/logsteo-api.v2';
import { isNotNullOrUndefined } from '@utils/utils';
import useForm, { proxiedPropertiesOf } from '@hooks/useForm/useForm';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { SelectButton } from 'primereact/selectbutton';
import styled from 'styled-components';
import produce from 'immer';
import { MultiSelect } from 'primereact/multiselect';
import AdminModulesList from '../modules/AdminModulesList';
import AdminIhneritedModules from '@components/pages/admin/modules/AdminIhneritedModules';
import AdminCompanyUsers from '@components/pages/admin/users/AdminCompanyUsers';
import AdminCompanyInheriters from '@components/pages/admin/AdminCompanyInheriters/AdminCompanyInheriters';
import ListInfo from '@components/obsolete/ListInfo/ListInfo.tsx';
import ListInfoItem from '@components/obsolete/ListInfo/ListInfoItem.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import AdminCompanyFolders from '@components/pages/admin/folder/AdminCompanyFolders.tsx';

interface ComponentProps {
  companyId: string;
}

interface Form {
  readonly name: string;
  features: string[];
  contentPrefix?: string;
  code?: string;
  externalIds?: ExternalIdsOutDTO[];
  modules: ModuleSettingsOutDTO[];
  dachserLastExportId?: string;
  enabledExpRequirementCodes?: string[];
  customerReportingId: string;
}

const AdminCompanyDetailPage: React.FC<ComponentProps> = ({ companyId }) => {
  const { adminGetCompanyDetail, adminUpdateCompanyDetail, adminLovAllFeatures, adminLovAllSpecialReqs } = useContext(ApiContext);
  const [resetData, setResetData] = useState<Form>();
  const [allReqs, setAllReqs] = useState<string[]>();
  const [data, setData] = useState<AdminCompanyDetailOutDTO>();

  const form = useForm<Form>(yup.object().shape({}), null, d => save(d), false, false);

  const names = proxiedPropertiesOf<Form>();

  const reset = () => {
    form.setValues(resetData);
  };

  useEffect(() => {
    if (isNotNullOrUndefined(companyId)) {
      load(companyId);
    }
    adminLovAllSpecialReqs(d => {
      setAllReqs(d);
    });
  }, [companyId]);

  const load = (companyId: string) => {
    adminGetCompanyDetail(companyId, d => {
      setData(d);
      const rstData = {
        contentPrefix: d.contentPrefix,
        code: d.code,
        features: d.features,
        externalIds: d.externalIds,
        name: d.name,
        modules: d.modules,
        dachserLastExportId: d.dachserLastExportId,
        enabledExpRequirementCodes: d.enabledExpRequirementCodes,
        customerReportingId: d.customerReportingId,
      };
      form.setValues(rstData);
      setResetData(rstData);
    });
  };

  const save = (d: Form) => {
    adminUpdateCompanyDetail(
      companyId,
      {
        features: d.features,
        companyCode: d.code,
        contentPrefix: d.contentPrefix,
        externalIds: d.externalIds,
        dachserLastExportId: d.dachserLastExportId,
        enabledExpRequirementCodes: d.enabledExpRequirementCodes,
        customerReportingId: d.customerReportingId,
      } as UpdateCompanyDetailInDTO,
      () => {
        load(companyId);
      },
    );
  };

  return (
    <>
      {form.values && (
        <>
          {/*// @ts-ignore*/}
          <Card>
            <RingilHeading>{form.values.name}</RingilHeading>
            <ListInfo>
              <ListInfoItem title="ID">
                <p className="p-d-flex p-ai-center">
                  <small>{data.companyId}</small>
                </p>
              </ListInfoItem>
              <ListInfoItem title="CRN">
                <p className="p-d-flex p-ai-center">
                  <small>{data.crn}</small>
                </p>
              </ListInfoItem>
              <ListInfoItem title="TIN">
                <p className="p-d-flex p-ai-center">
                  <small>{data.tin}</small>
                </p>
              </ListInfoItem>
              <ListInfoItem title="Country">
                <p className="p-d-flex p-ai-center">
                  <small>{data.country}</small>
                </p>
              </ListInfoItem>
              <ListInfoItem title={'ZipCode'}>
                <p className="p-d-flex p-ai-center">
                  <small>{data.zipCode}</small>
                </p>
              </ListInfoItem>
              <ListInfoItem title={'City'}>
                <p className="p-d-flex p-ai-center">
                  <small>{data.city}</small>
                </p>
              </ListInfoItem>
              <ListInfoItem title={'StreetNr'}>
                <p className="p-d-flex p-ai-center">
                  <small>{data.streetAndNr}</small>
                </p>
              </ListInfoItem>
            </ListInfo>
            <HorizontalFieldValue
              label={'Company Code'}
              value={
                <NullableInput value={form.values.code} defaultValue={''} onChange={v => form.setFieldValue(names.code, v)}>
                  <InputText value={form.values.code} onChange={e => form.setFieldValue(names.code, e.target.value)} />
                </NullableInput>
              }
            />
            <HorizontalFieldValue label={'Features'} value={<EnableFeature value={form.values.features} onChange={v => form.setFieldValue(names.features, v)} />} />
            <HorizontalFieldValue
              label={'Context prefix'}
              value={
                <NullableInput value={form.values.contentPrefix} onChange={v => form.setFieldValue(names.contentPrefix, v)} defaultValue={''}>
                  <InputText value={form.values.contentPrefix} onChange={e => form.setFieldValue(names.contentPrefix, e.target.value)} />
                </NullableInput>
              }
            />
            <HorizontalFieldValue
              label={'ExternalIds'}
              value={
                <NullableInput value={form.values.externalIds} onChange={v => form.setFieldValue(names.externalIds, v)} defaultValue={[]}>
                  <SetExternalIds value={form.values.externalIds} onChange={v => form.setFieldValue(names.externalIds, v)} modules={form.values.modules} />
                </NullableInput>
              }
            />
            <HorizontalFieldValue
              label={'dachserLastExportId'}
              value={
                <NullableInput value={form.values.dachserLastExportId} onChange={v => form.setFieldValue(names.dachserLastExportId, v)} defaultValue={'00000'}>
                  <InputText value={form.values.dachserLastExportId} onChange={v => form.setFieldValue(names.dachserLastExportId, v.target.value)} />
                </NullableInput>
              }
            />
            <HorizontalFieldValue
              label={'enabledExpRequirementCodes'}
              value={
                <>
                  <NullableInput value={form.values.enabledExpRequirementCodes} onChange={v => form.setFieldValue(names.enabledExpRequirementCodes, v)} defaultValue={[]}>
                    <MultiSelect
                      options={allReqs}
                      value={form.values.enabledExpRequirementCodes}
                      onChange={v => form.setFieldValue(names.enabledExpRequirementCodes, v.value)}
                    />
                  </NullableInput>
                </>
              }
            />
            <HorizontalFieldValue
              label={'customerReportingId'}
              value={
                <>
                  <NullableInput value={form.values.customerReportingId} onChange={v => form.setFieldValue(names.customerReportingId, v)} defaultValue={[]}>
                    <InputText value={form.values.customerReportingId} onChange={v => form.setFieldValue(names.customerReportingId, v.target.value)} />
                  </NullableInput>
                </>
              }
            />
            <ButtonRow>
              <Button label={'Save'} onClick={() => form.validateAndSend()} />
              <Button label={'RST'} onClick={() => reset()} />
            </ButtonRow>
          </Card>
        </>
      )}
      <AdminModulesList customerId={companyId} />
      <AdminCompanyInheriters companyId={companyId} />
      <AdminIhneritedModules customerId={companyId} />
      <AdminCompanyUsers companyId={companyId} />
      <AdminCompanyFolders companyId={companyId} />
    </>
  );
};

interface ExternalIdsProps {
  value: ExternalIdsOutDTO[];
  onChange: (v: ExternalIdsOutDTO[]) => void;
  modules: ModuleSettingsOutDTO[];
}

const SetExternalIds: React.FC<ExternalIdsProps> = ({ value, onChange, modules }) => {
  const { tr } = useTranslationLgs();
  const { loggedUser } = useContext(AuthenticatedUserContext);

  if (loggedUser === undefined) {
    return <div></div>;
  }

  const applyValue = (companyId: string, v: string) => {
    const v2 = produce(value, draft => {
      const found = draft.find(t => t.companyId === companyId);
      if (found !== undefined) {
        found.externalIds = v;
      } else {
        draft.push({ companyId: companyId, externalIds: v });
      }
    });
    onChange(v2);
  };
  return (
    <>
      {modules
        .filter(t => t.inheritingModule === true)
        .filter(t => t.owner !== null)
        .filter(t => t.companyModule === CompanyModuleEnum.SUPPLIER_NOTIFICATION)
        .map((m, mIndex) => {
          return (
            <Grid key={mIndex}>
              <div>{m.owner.companyName}</div>
              <InputText value={value.find(t => t.companyId == m.owner.companyId)?.externalIds || ''} onChange={e => applyValue(m.owner.companyId, e.target.value)} />
            </Grid>
          );
        })}
    </>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr 1fr;
`;

interface Props {
  value: string[];
  onChange: (v: string[]) => void;
}

const EnableFeature: React.FC<Props> = ({ value, onChange }) => {
  const { adminLovAllFeatures } = useContext(ApiContext);
  const [options, setOptions] = useState<string[]>();

  useEffect(() => {
    adminLovAllFeatures(d => {
      setOptions(d);
    });
  }, []);

  return (
    <>
      <SelectButton multiple={true} value={value} options={options} onChange={e => onChange(e.value)} />
    </>
  );
};

interface NullableInputProps {
  value: any;
  onChange: (v: any) => void;
  defaultValue: any;
}

const NullableInput: React.FC<PropsWithChildren<NullableInputProps>> = ({ children, value, onChange, defaultValue }) => {
  const { tr } = useTranslationLgs();
  return (
    <>
      {value !== null ? (
        <Col>
          {children}
          <div>
            <Clickable onClick={() => onChange(null)}>Set null</Clickable>
          </div>
        </Col>
      ) : (
        <Col>
          {tr(`HorizontalFieldValue.undefined`, `Value not set`)}
          <div>
            <Clickable onClick={() => onChange(defaultValue)}>Set value</Clickable>
          </div>
        </Col>
      )}
    </>
  );
};

export default AdminCompanyDetailPage;
